export const GET_COURSE_START = 'studentsroom/GET_COURSE_START';
export const GET_COURSE_COMPLETED = 'studentsroom/GET_COURSE_COMPLETED';
export const GET_COURSE_FAILED = 'studentsroom/GET_COURSE_FAILED';

export const GET_LESSON_START = 'studentsroom/GET_LESSON_START';
export const GET_LESSON_COMPLETED = 'studentsroom/GET_LESSON_COMPLETED';
export const GET_LESSON_FAILED = 'studentsroom/GET_LESSON_FAILED';

export const SET_LESSON_COMPLATE = 'studentsroom/SET_LESSON_COMPLATE';
export const SET_IS_COMPLATE_COMPLATE = 'studentsroom/SET_IS_COMPLATE_COMPLATE';

export const COURSE_FINISHED_COMPLATED = 'studentsroom/COURSE_FINISHED_COMPLATED';
export const SET_ZOOM_SETTINGS_COMPLETE = 'studentsroom/SET_ZOOM_SETTINGS_COMPLETE';


export const MEETING__BOOK__START = 'studentsroom/MEETING__BOOK__START';
export const MEETING__BOOK__END = 'studentsroom/MEETING__BOOK__END';
export const MEETING__BOOK__COMPLETED = 'studentsroom/MEETING__BOOK__COMPLETED';

export const GET_VIDEOCOURSE_COMPLETED = 'studentsroom/GET_VIDEOCOURSE_COMPLETED';
export const GET_VIDEO_COMPLETED = 'studentsroom/GET_VIDEO_COMPLETED';
