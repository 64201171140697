const initialState = {
   getFrontBlogPostInProgress: false,
   getFrontBlogInProgress: false,
   getFrontBlogSettingsInProgress: false,
   postFront: {},
   blogFront: [],
   blogSettingsFront: {},
   categoriesFront: [],
   getFrontBlogCountInProgress: false,
};

export default initialState;
