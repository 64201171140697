export const SET_INPUT = 'members/SET_INPUT';
export const GET_MEMBERS_START = 'members/GET_MEMBERS_START';
export const GET_MEMBERS_COMPLETED = 'members/GET_MEMBERS_COMPLETED';
export const GET_MEMBERS_FAILED = 'members/GET_MEMBERS_FAILED';

export const GET_CURRENT_MEMBER_START = 'members/GET_CURRENT_MEMBER_START';
export const GET_CURRENT_MEMBER_COMPLETED = 'members/GET_CURRENT_MEMBER_COMPLETED';
export const GET_CURRENT_MEMBER_FAILED = 'members/GET_CURRENT_MEMBER_FAILED';

export const GET_MEMBER_TRANSACTION_START = 'members/GET_MEMBER_TRANSACTION_START';
export const GET_MEMBER_TRANSACTION_COMPLETED = 'members/GET_MEMBER_TRANSACTION_COMPLETED';
export const GET_MEMBER_TRANSACTION_FAILED = 'members/GET_MEMBER_TRANSACTION_FAILED';

export const GET_MEMBER_NOTES_START = 'members/GET_MEMBER_NOTES_START';
export const GET_MEMBER_NOTES_COMPLETED = 'members/GET_MEMBER_NOTES_COMPLETED';
export const GET_MEMBER_NOTES_FAILED = 'members/GET_MEMBER_NOTES_FAILED';

export const PUT_CURRENT_MEMBER_START = 'members/PUT_CURRENT_MEMBER_START';
export const PUT_CURRENT_MEMBER_COMPLETED = 'members/PUT_CURRENT_MEMBER_COMPLETED';
export const PUT_CURRENT_MEMBER_FAILED = 'members/PUT_CURRENT_MEMBER_FAILED';

export const SEND_PASSWORD_START = 'members/SEND_PASSWORD_START';
export const SEND_PASSWORD_COMPLETED = 'members/SEND_PASSWORD_COMPLETED';
export const SEND_PASSWORD_FAILED = 'members/SEND_PASSWORD_FAILED';

export const CREATE_NOTE_START = 'members/CREATE_NOTE_START';
export const CREATE_NOTE_COMPLETED = 'members/CREATE_NOTE_COMPLETED';
export const CREATE_NOTE_FAILED = 'members/CREATE_NOTE_FAILED';

export const DELETE_NOTE_START = 'members/DELETE_NOTE_START';
export const DELETE_NOTE_COMPLETED = 'members/DELETE_NOTE_COMPLETED';
export const DELETE_NOTE_FAILED = 'members/DELETE_NOTE_FAILED';

export const GET_TAGS_START = 'members/GET_TAGS_START';
export const GET_TAGS_COMPLETED = 'members/GET_TAGS_COMPLETED';
export const GET_TAGS_FAILED = 'members/GET_TAGS_FAILED';

export const ADD_TAG_START = 'members/ADD_TAG_START';
export const ADD_TAG_COMPLETED = 'members/ADD_TAG_COMPLETED';
export const ADD_TAG_FAILED = 'members/ADD_TAG_FAILED';

export const DETACH_TAG_COMPLETED = 'members/DETACH_TAG_COMPLETED';
export const ATACH_TAG_COMPLETED = 'members/ATACH_TAG_COMPLETED';

export const SEARCH_MEMBER_START = 'members/SEARCH_MEMBER_START';
export const SEARCH_MEMBER_COMPLETED = 'members/SEARCH_MEMBER_COMPLETED';
export const SEARCH_MEMBER_FAILED = 'members/SEARCH_MEMBER_FAILED';

export const ADD_MEMBER_START = 'members/ADD_MEMBER_START';
export const ADD_MEMBER_COMPLETED = 'members/ADD_MEMBER_COMPLETED';
export const ADD_MEMBER_FAILED = 'members/ADD_MEMBER_FAILED';

export const PAUSE_COURSE_START = 'members/PAUSE_COURSE_START';
export const PAUSE_COURSE_COMPLETED = 'members/PAUSE_COURSE_COMPLETED';
export const PAUSE_COURSE_FAILED = 'members/PAUSE_COURSE_FAILED';

export const DELETE_COURSE_START = 'members/DELETE_COURSE_START';
export const DELETE_COURSE_COMPLETED = 'members/DELETE_COURSE_COMPLETED';
export const DELETE_COURSE_FAILED = 'members/DELETE_COURSE_FAILED';

export const DELETE_MEMBER_START = 'members/DELETE_MEMBER_START';
export const DELETE_MEMBER_COMPLETED = 'members/DELETE_MEMBER_COMPLETED';
export const DELETE_MEMBER_FAILED = 'members/DELETE_MEMBER_FAILED';

export const CHOOSE_COURSES_OPTIONS = 'members/CHOOSE_COURSES_OPTIONS';
export const ADD_FILTER_OPTION = 'members/ADD_FILTER_OPTION';
export const REMOVE_FILTER_OPTION = 'members/REMOVE_FILTER_OPTION';

export const UNSUBSCRIBE_EMAIL_START = 'members/UNSUBSCRIBE_EMAIL_START';
export const UNSUBSCRIBE_EMAIL_COMPLETED = 'members/UNSUBSCRIBE_EMAIL_COMPLETED';
export const UNSUBSCRIBE_EMAIL_FAILED = 'members/UNSUBSCRIBE_EMAIL_FAILED';

export const CHANGE_PAGE_START = 'members/CHANGE_PAGE_START';
export const CHANGE_PAGE_COMPLETED = 'members/CHANGE_PAGE_COMPLETED';
export const CHANGE_PAGE_FAILED = 'members/CHANGE_PAGE_FAILED';

export const CHOOSE_NOTE = 'members/CHOOSE_NOTE';
export const SET_NOTE_INPUT = 'members/SET_NOTE_INPUT';

export const UPDATE_NOTE_START = 'members/UPDATE_NOTE_START';
export const UPDATE_NOTE_COMPLETED = 'members/UPDATE_NOTE_COMPLETED';
export const UPDATE_NOTE_FAILED = 'members/UPDATE_NOTE_FAILED';

export const EMPTY_COURSE_FILTER = 'members/EMPTY_COURSE_FILTER';

export const UPDATE_CURRENT_MEMBERS_TAGS = 'members/UPDATE_CURRENT_MEMBERS_TAGS';

export const FILTER_MEMBER_START = 'member/FILTER_MEMBER_START';
export const FILTER_MEMBER_COMPLETED = 'member/FILTER_MEMBER_COMPLETED';
export const FILTER_MEMBER_FAILED = 'member/FILTER_MEMBER_FAILED';


export const CLEAR_CURRENT_MEMBER = 'member/CLEAR_CURRENT_MEMBER';

export const ADD_CURREMT_MEMBER_COURSE_START = 'member/ADD_CURREMT_MEMBER_COURSE_START';
export const ADD_CURREMT_MEMBER_COURSE_FAIL = 'member/ADD_CURREMT_MEMBER_COURSE_FAIL';
export const ADD_CURREMT_MEMBER_COURSE_COMPLETED = 'member/ADD_CURREMT_MEMBER_COURSE_COMPLETED';

export const MEMBER_POPUP_CHANGE_START = 'member/MEMBER_POPUP_CHANGE_START';
export const MEMBER_POPUP_CHANGE_FAIL = 'member/MEMBER_POPUP_CHANGE_FAIL';
export const MEMBER_POPUP_CHANGE_COMPLETED = 'member/MEMBER_POPUP_CHANGE_COMPLETED';

export const MEMBER_TRANSACTIONS_FILTER_COMPLETED = 'member/MEMBER_TRANSACTIONS_FILTER_COMPLETED';

export const GET_MEMBER_COMMUNITY_MORE_INFO_START = 'member/GET_MEMBER_COMMUNITY_MORE_INFO_START';
export const GET_MEMBER_COMMUNITY_MORE_INFO_FAILED = 'member/GET_MEMBER_COMMUNITY_MORE_INFO_FAILED';
export const GET_MEMBER_COMMUNITY_MORE_INFO_COMPLETED = 'member/GET_MEMBER_COMMUNITY_MORE_INFO_COMPLETED';
export const RESET_COMMUNITY = 'community/RESET_COMMUNITY';

export const BULK_DELETE_MEMBER_COMPLETED = 'member/BULK_DELETE_MEMBER_COMPLETED';
