export const GET_USER_START = 'account/GET_USER_START';
export const GET_USER_COMPLETED = 'account/GET_USER_COMPLETED';
export const GET_USER_FAILED = 'account/GET_USER_FAILED';

export const SET_USER_START = 'account/SET_USER_START';
export const SET_USER_COMPLETED = 'account/SET_USER_COMPLETED';

export const GET_PLAN_START = 'account/GET_PLAN_START';
export const GET_PLAN_COMPLETED = 'account/GET_PLAN_COMPLETED';
export const GET_PLAN_FAILED = 'account/GET_PLAN_FAILED';

export const CANCEL_PLAN_START = 'account/CANCEL_PLAN_START';
export const CANCEL_PLAN_END = 'account/CANCEL_PLAN_END';


export const REACTIVATE_PLAN_START = 'account/REACTIVATE_PLAN_START';
export const REACTIVATE_PLAN_END = 'account/REACTIVATE_PLAN_END';

export const UPDATE_CARD_START = 'account/UPDATE_CARD_START';
export const UPDATE_CARD_END = 'account/UPDATE_CARD_END';

export const CHANGE_PLAN_START = 'account/CHANGE_PLAN_START';
export const CHANGE_PLAN_END = 'account/CHANGE_PLAN_END';

export const SAVE_ADDRESS_START = 'account/SAVE_ADDRESS_START';
export const SAVE_ADDRESS_FINISH = 'account/SAVE_ADDRESS_FINISH';
export const SAVE_ADDRESS_FAILED = 'account/SAVE_ADDRESS_FAILED';

export const CONNECT_PLAN_START = 'plans/CONNECT_PLAN_START';
export const CONNECT_PLAN_FAILED = 'plans/CONNECT_PLAN_FAILED';
export const CONNECT_PLAN = 'plans/CONNECT_PLAN';
