const initialState = {
   dataIsFetching: true,
   putSettingsInProgress: true,
   getSettingsInProgress: false,
   createLinkInProgress: true,
   errors: {},
   emails: {},
   initialNotifications: [],
   notifications: [],
   isNotificationsFetching: false,
   mainhub: {},
   courses: {},
   account: {
      password: '',
   },
   connect: {},
   integrations: {},
   videoanalytics: {},
   getVideoInProgress: true,
   video: {},
   getVideosInProgress: true,
   tabName: '',
   userChangedData: {
      name: '',
      picture_src: '',
   },
   createLinkModalOpen: false,
   isCustomLinksButtonDisabled: false,
   fileSizeInfo: {},
   getApiKeyInProgress: false,
   apiData: {},
   allCourses: [],
   fromEmailLoading: false,
   fromEmailData: {},
   getScriptsInProgress: false,
   scripts: [],
   currentScript: {},
   newScript: '',
   disableDisconnectIntegration: false,
   codes: {},
};

export default initialState;
