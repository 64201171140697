export const GET_FRONTBLOGPOST_START = 'blogFront/GET_FRONTBLOGPOST_START';
export const GET_FRONTBLOGPOST_COMPLETED = 'blogFront/GET_FRONTBLOGPOST_COMPLETED';
export const GET_FRONTBLOGPOST_FAILED = 'blogFront/GET_FRONTBLOGPOST_FAILED';

export const GET_FRONTBLOG_START = 'blogFront/GET_FRONTBLOG_START';
export const GET_FRONTBLOG_COMPLETED = 'blogFront/GET_FRONTBLOG_COMPLETED';
export const GET_FRONTBLOG_FAILED = 'blogFront/GET_FRONTBLOG_FAILED';

export const GET_FRONTBLOGSETTINGS_START = 'blogFront/GET_FRONTBLOGSETTINGS_START';
export const GET_FRONTBLOGSETTINGS_COMPLETED = 'blogFront/GET_FRONTBLOGSETTINGS_COMPLETED';
export const GET_FRONTBLOGSETTINGS_FAILED = 'blogFront/GET_FRONTBLOGSETTINGS_FAILED';

export const GET_FRONTBLOGCOUNT_START = 'blogFront/GET_FRONTBLOGCOUNT_START';
export const GET_FRONTBLOGCOUNT_COMPLETED = 'blogFront/GET_FRONTBLOGCOUNT_COMPLETED';
