export const GET_WEBHOOKS_START = 'webhooks/GET_WEBHOOKS_START';
export const GET_WEBHOOKS_COMPLETED = 'webhooks/GET_WEBHOOKS_COMPLETED';
export const GET_WEBHOOKS_FAILED = 'webhooks/GET_WEBHOOKS_FAILED';

export const GET_WEBHOOKBYID_START = 'webhooks/GET_WEBHOOKBYID_START';
export const GET_WEBHOOKBYID_COMPLETED = 'webhooks/GET_WEBHOOKBYID_COMPLETED';
export const GET_WEBHOOKBYID_FAILED = 'webhooks/GET_WEBHOOKBYID_FAILED';

export const GET_WEBHOOKLOGS_START = 'webhooks/GET_WEBHOOKLOGS_START';
export const GET_WEBHOOKLOGS_COMPLETED = 'webhooks/GET_WEBHOOKLOGS_COMPLETED';
export const GET_WEBHOOKLOGS_FAILED = 'webhooks/GET_WEBHOOKLOGS_FAILED';

export const CREATE_WEBHOOKS_START = 'webhooks/CREATE_WEBHOOKS_START';
export const CREATE_WEBHOOKS_COMPLETED = 'webhooks/CREATE_WEBHOOKS_COMPLETED';
export const CREATE_WEBHOOKS_FAILED = 'webhooks/CREATE_WEBHOOKS_FAILED';

export const DELETE_WEBHOOK_START = 'webhooks/DELETE_WEBHOOK_START';
export const DELETE_WEBHOOK_COMPLETED = 'webhooks/DELETE_WEBHOOK_COMPLETED';
export const DELETE_WEBHOOK_FAILED = 'webhooks/DELETE_WEBHOOK_FAILED';

export const UPDATE_WEBHOOK_START = 'webhooks/UPDATE_WEBHOOK_START';
export const UPDATE_WEBHOOK_COMPLETED = 'webhooks/UPDATE_WEBHOOK_COMPLETED';
export const UPDATE_WEBHOOK_FAILED = 'webhooks/UPDATE_WEBHOOK_FAILED';

export const SET_INPUT = 'webhooks/SET_INPUT';
export const RESET_WEBHOOK = 'webhooks/RESET_WEBHOOK';
