import React from 'react';

export const UnpublishedPlanSIcon = () => {
   return (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M1.64649 1.64645C1.84175 1.45118 2.15833 1.45118 2.35359 1.64645L4.23744 3.5303L6.93935 6.2322L9.76778 9.06063L12.4697 11.7626L14.3536 13.6464C14.5489 13.8417 14.5489 14.1583 14.3536 14.3536C14.1583 14.5488 13.8417 14.5488 13.6465 14.3536L12.0492 12.7562C10.9855 13.4041 9.64076 13.8333 8.00004 13.8333C5.52301 13.8333 3.72857 12.8556 2.55655 11.6385C1.4044 10.442 0.833374 8.98997 0.833374 8C0.833374 6.87316 1.57617 5.11964 3.12578 3.83285L1.64649 2.35355C1.45123 2.15829 1.45123 1.84171 1.64649 1.64645ZM3.83636 4.54342C2.43428 5.67028 1.83337 7.19593 1.83337 8C1.83337 8.6767 2.26234 9.8913 3.27687 10.9448C4.27151 11.9777 5.8104 12.8333 8.00004 12.8333C9.3458 12.8333 10.4428 12.5103 11.3179 12.0249L9.37866 10.0857C8.98352 10.3473 8.50932 10.5 7.99998 10.5C6.61927 10.5 5.49998 9.38071 5.49998 8C5.49998 7.49066 5.65266 7.01646 5.91426 6.62132L3.83636 4.54342ZM6.64609 7.35316C6.55234 7.54898 6.49998 7.76822 6.49998 8C6.49998 8.82843 7.17155 9.5 7.99998 9.5C8.23176 9.5 8.451 9.44764 8.64682 9.35389L6.64609 7.35316ZM8.00004 3.16667C7.31013 3.16667 6.68704 3.25156 6.12695 3.39861C6.02161 3.42627 5.91845 3.45613 5.81743 3.48807C5.55414 3.57132 5.27321 3.42535 5.18997 3.16206C5.10672 2.89876 5.25268 2.61783 5.51598 2.53459C5.63273 2.49768 5.75173 2.46323 5.87301 2.43139C6.51873 2.26186 7.2273 2.16667 8.00004 2.16667C10.4771 2.16667 12.2715 3.14441 13.4435 4.36151C14.5957 5.55797 15.1667 7.01003 15.1667 8C15.1667 8.72662 14.8597 9.68806 14.2644 10.6055C14.1864 10.7258 14.1031 10.8459 14.0144 10.9651C13.8496 11.1867 13.5363 11.2327 13.3148 11.0678C13.0932 10.903 13.0472 10.5898 13.212 10.3682C13.2879 10.2663 13.359 10.1637 13.4256 10.0612C13.9471 9.25736 14.1667 8.4828 14.1667 8C14.1667 7.3233 13.7377 6.1087 12.7232 5.05515C11.7286 4.02226 10.1897 3.16667 8.00004 3.16667Z' fill='#131F1E' fillOpacity='0.8' />
      </svg>
   );
};

export const PublishedPlanSIcon = () => {
   return (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M1.83337 8.00033C1.83337 7.32363 2.26234 6.10902 3.27687 5.05548C4.27151 4.02258 5.8104 3.16699 8.00004 3.16699C10.1897 3.16699 11.7286 4.02258 12.7232 5.05548C13.7377 6.10902 14.1667 7.32363 14.1667 8.00033C14.1667 8.67702 13.7377 9.89163 12.7232 10.9452C11.7286 11.9781 10.1897 12.8337 8.00004 12.8337C5.8104 12.8337 4.27151 11.9781 3.27687 10.9452C2.26234 9.89163 1.83337 8.67702 1.83337 8.00033ZM8.00004 2.16699C5.52301 2.16699 3.72857 3.14474 2.55655 4.36184C1.4044 5.55829 0.833374 7.01035 0.833374 8.00033C0.833374 8.9903 1.4044 10.4424 2.55655 11.6388C3.72857 12.8559 5.52301 13.8337 8.00004 13.8337C10.4771 13.8337 12.2715 12.8559 13.4435 11.6388C14.5957 10.4424 15.1667 8.9903 15.1667 8.00033C15.1667 7.01035 14.5957 5.55829 13.4435 4.36184C12.2715 3.14474 10.4771 2.16699 8.00004 2.16699ZM6.49998 8.00033C6.49998 7.1719 7.17155 6.50033 7.99998 6.50033C8.82841 6.50033 9.49998 7.1719 9.49998 8.00033C9.49998 8.82875 8.82841 9.50033 7.99998 9.50033C7.17155 9.50033 6.49998 8.82875 6.49998 8.00033ZM7.99998 5.50033C6.61927 5.50033 5.49998 6.61961 5.49998 8.00033C5.49998 9.38104 6.61927 10.5003 7.99998 10.5003C9.38069 10.5003 10.5 9.38104 10.5 8.00033C10.5 6.61961 9.38069 5.50033 7.99998 5.50033Z' fill='#24554E' />
      </svg>
   );
};

export const TestModePlanSIcon = () => {
   return (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M1.62842 7.99952C1.62842 4.48165 4.46081 1.62793 8.00001 1.62793C10.104 1.62793 11.6807 2.49792 12.7219 3.35693C12.9674 3.55946 13.1839 3.76197 13.3716 3.95333V3.30225C13.3716 3.02611 13.5955 2.80225 13.8716 2.80225C14.1477 2.80225 14.3716 3.02611 14.3716 3.30225V5.3573C14.3716 5.63345 14.1477 5.8573 13.8716 5.8573H11.8165C11.5404 5.8573 11.3165 5.63345 11.3165 5.3573C11.3165 5.08116 11.5404 4.8573 11.8165 4.8573H12.8501C12.6465 4.63332 12.3915 4.38078 12.0855 4.1283C11.1695 3.37263 9.81039 2.62793 8.00001 2.62793C5.01522 2.62793 2.62842 5.03181 2.62842 7.99952C2.62842 8.27566 2.40456 8.49952 2.12842 8.49952C1.85228 8.49952 1.62842 8.27566 1.62842 7.99952ZM13.8716 7.49952C14.1477 7.49952 14.3716 7.72338 14.3716 7.99952C14.3716 11.5185 11.5189 14.3711 8.00001 14.3711C6.20811 14.3711 4.77342 13.4799 3.81109 12.6315C3.58956 12.4362 3.39066 12.2411 3.21558 12.0563V12.6968C3.21558 12.9729 2.99172 13.1968 2.71558 13.1968C2.43943 13.1968 2.21558 12.9729 2.21558 12.6968V10.6417C2.21558 10.3656 2.43943 10.1417 2.71558 10.1417H4.77063C5.04678 10.1417 5.27063 10.3656 5.27063 10.6417C5.27063 10.9179 5.04678 11.1417 4.77063 11.1417H3.73424C3.93471 11.3692 4.18217 11.6255 4.4724 11.8814C5.34159 12.6477 6.54911 13.3711 8.00001 13.3711C10.9667 13.3711 13.3716 10.9662 13.3716 7.99952C13.3716 7.72338 13.5955 7.49952 13.8716 7.49952Z' fill='#58239B' />
      </svg>
   );
};

export const EditPlanSIcon = () => {
   return (
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M14.1427 3.23956C14.675 2.84201 15.4055 2.84202 15.9378 3.23958C16.0172 3.29886 16.107 3.38682 16.3601 3.63996C16.6132 3.89309 16.7012 3.98289 16.7604 4.06226C17.158 4.59459 17.158 5.32501 16.7604 5.85733C16.7012 5.9367 16.6132 6.0265 16.3601 6.27963L15.6767 6.96302C14.264 6.86486 13.1351 5.73601 13.037 4.3233L13.7204 3.63993C13.9735 3.3868 14.0633 3.29883 14.1427 3.23956ZM12.1778 5.1825L5.88667 11.4736C5.3652 11.995 5.14816 12.2146 4.97693 12.4601C4.81977 12.6854 4.6897 12.9285 4.58938 13.1842C4.48008 13.4629 4.41778 13.7653 4.27315 14.4884L3.96356 16.0364L5.51155 15.7268C6.23469 15.5822 6.53706 15.5199 6.81572 15.4106C7.07147 15.3103 7.31452 15.1802 7.53985 15.023C7.78537 14.8518 8.00492 14.6348 8.52639 14.1133L14.8175 7.82222C13.5447 7.45836 12.5416 6.45532 12.1778 5.1825ZM16.5362 2.43837C15.6489 1.77576 14.4316 1.77575 13.5443 2.43833C13.3994 2.54658 13.2551 2.69091 13.0433 2.90279L13.0433 2.90281L13.0133 2.93282L5.17957 10.7664L5.14417 10.8018C4.66846 11.2775 4.38457 11.5613 4.15671 11.8881C3.95465 12.1778 3.78741 12.4903 3.65843 12.8191C3.51298 13.1899 3.43428 13.5836 3.30238 14.2432L3.29257 14.2923L2.83589 16.5757C2.8031 16.7396 2.85441 16.9091 2.97263 17.0273C3.09084 17.1455 3.26031 17.1968 3.42424 17.1641L5.70766 16.7074L5.75673 16.6976L5.75674 16.6976C6.4164 16.5657 6.81006 16.487 7.18087 16.3415C7.50969 16.2125 7.82218 16.0453 8.11189 15.8433C8.43861 15.6154 8.72246 15.3315 9.19811 14.8558L9.2335 14.8204L17.0672 6.98673L17.0972 6.95674L17.0972 6.95673C17.3091 6.74492 17.4534 6.60064 17.5616 6.4557C18.2242 5.56849 18.2242 4.35113 17.5617 3.46391C17.4534 3.31896 17.3091 3.17468 17.0972 2.96286L17.0672 2.93286L17.0372 2.90287L17.0372 2.90286C16.8254 2.69096 16.6811 2.54662 16.5362 2.43837Z' fill='#131F1E' />
      </svg>
   );
};
