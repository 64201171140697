
export const COMMENTS_GET_START = 'comments/COMMENS_GET_START';
export const COMMENTS_GET_COMPLETED = 'comments/COMMENTS_GET_COMPLETED';
export const COMMENTS_GET_FAILED = 'comments/COMMENTS_GET_FAILED';

export const COMMENTS_SEARCH_START = 'comments/COMMENTS_SEARCH_START';
export const COMMENTS_SEARCH_FAILED = 'comments/COMMENTS_SEARCH_FAILED';
export const COMMENTS_SEARCH_COMPLETED = 'comments/COMMENTS_SEARCH_COMPLETED';

export const COMMENTS_CHANGES_START = 'comments/COMMENTS_CHANGES_START';
export const COMMENTS_CHANGES_FAILED = 'comments/COMMENTS_CHANGES_FAILED';
export const COMMENTS_CHANGES_COMPLETED = 'comments/COMMENTS_CHANGES_COMPLETED';

export const REPLY_COMMENT_START = 'comments/REPLY_COMMENT_START';
export const REPLY_COMMENT_COMPLETED = 'comments/REPLY_COMMENT_COMPLETED';
export const REPLY_COMMENT_FAILED = 'comments/REPLY_COMMENT_FAILED';

export const GET_REPLY_START = 'comments/GET_REPLY_START';
export const GET_REPLY_FAILED = 'comments/GET_REPLY_FAILED';
export const GET_REPLY_COMPLETED = 'comments/GET_REPLY_COMPLETED';

export const SOCKET_ADD_COMMENT = 'comments/SOCKET_ADD_COMMENT';
