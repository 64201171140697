const initialState = {
   plans: {},
   getPlansInProgress: true,
   filterInProgress: false,
   planProgress: true,
   plan: {},
   initial: {},
   authoresponderOptions: [],
   authoresponderListOptions: [],
   planLoading: false,
   lastPage: 1,
   isFetchingNextPage: false,
};

export default initialState;
