const initialState = {
   isFetchingData: false,
   webHooks: [],
   webHook: {
      metas: {},
   },
   webHookLogs: [],

};

export default initialState;
