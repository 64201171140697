export const CHOOSE_BADGE_START = 'gamifications/CHOOSE_BADGE_START';
export const CHOOSE_BADGE_COMPLETED = 'gamifications/CHOOSE_BADGE_COMPLETED';

export const SET_INPUT = 'gamifications/SET_INPUT';

export const SWITCH_ADD_BADGE = 'gamifications/SWITCH_ADD_BADGE';

export const GET_GAMIFICATIONS_START = 'gamifications/GET_GAMIFICATIONS_START';
export const GET_GAMIFICATIONS_COMPLETED = 'gamifications/GET_GAMIFICATIONS_COMPLETED';
export const GET_GAMIFICATIONS_FAILED = 'gamifications/GET_GAMIFICATIONS_FAILED';

export const CREATE_GAMIFICATIONS_START = 'gamifications/CREATE_GAMIFICATIONS_START';
export const CREATE_GAMIFICATIONS_COMPLETED = 'gamifications/CREATE_GAMIFICATIONS_COMPLETED';
export const CREATE_GAMIFICATIONS_FAILED = 'gamifications/CREATE_GAMIFICATIONS_FAILED';

export const UPDATE_GAMIFICATIONS_START = 'gamifications/UPDATE_GAMIFICATIONS_START';
export const UPDATE_GAMIFICATIONS_COMPLETED = 'gamifications/UPDATE_GAMIFICATIONS_COMPLETED ';
export const UPDATE_GAMIFICATIONS_FAILED = 'gamifications/UPDATE_GAMIFICATIONS_FAILED';

export const DELETE_GAMIFICATIONS_START = 'gamifications/DELETE_GAMIFICATIONS_START';
export const DELETE_GAMIFICATIONS_COMPLETED = 'gamifications/DELETE_GAMIFICATIONS_COMPLETED';
export const DELETE_GAMIFICATIONS_FAILED = 'gamifications/DELETE_GAMIFICATIONS_FAILED';
export const UPDATE_MOBILE_STATE_COMPLETED = 'gamifications/UPDATE_MOBILE_STATE_COMPLETED';
export const RESET_STATE_CHANGE_COMPLETED = 'gamifications/RESET_STATE_CHANGE_COMPLETED';
