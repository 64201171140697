const prefix = 'sites';

export const GET_SITES_START = `${ prefix }/GET_SITES_START`;
export const GET_SITES_COMPLETED = `${ prefix }/GET_SITES_COMPLETED`;

export const ADD_SITE_START = `${ prefix }/ADD_SITE_START`;
export const ADD_SITE_COMPLETED = `${ prefix }/ADD_SITE_COMPLETED`;
export const ADD_SITE_FAILED = `${ prefix }/ADD_SITE_FAILED`;

export const UPDATE_SITE_START = `${ prefix }/UPDATE_SITE_START`;
export const UPDATE_SITE_COMPLETED = `${ prefix }/UPDATE_SITE_COMPLETED`;
export const UPDATE_SITE_FAILED = `${ prefix }/UPDATE_SITE_FAILED`;

export const DELETE_SITE_START = `${ prefix }/DELETE_SITE_START`;
export const DELETE_SITE_COMPLETED = `${ prefix }/DELETE_SITE_COMPLETED`;
export const DELETE_SITE_FAILED = `${ prefix }/DELETE_SITE_FAILED`;

export const LOGIN_SITE_START = `${ prefix }/LOGIN_SITE_START`;
export const LOGIN_SITE_COMPLETED = `${ prefix }/LOGIN_SITE_COMPLETED`;

export const SET_INPUT = `${ prefix }/SET_INPUT`;
export const SET_RESET = `${ prefix }/SET_RESET`;
export const CHOOSE_SITE = `${ prefix }/CHOOSE_SITE`;
