const initialState = {
   currentBadge: {},
   badges: [],
   courses: [],
   errors: {},
   createBadgeinProgress: false,
   getBadgesInProgress: true,
   chooseBadgeInProgress: false,
   addingBadge: false,
   mobileShowCurrentBudge: false,
   initialCurrentBudge: {},
};

export default initialState;
