export const GET_CERTIFICATE_START = 'certificate/GET_CERTIFICATE_START';
export const GET_CERTIFICATE_COMPLETED = 'certificate/GET_CERTIFICATE_COMPLETED';
export const GET_CERTIFICATE_FAILED = 'certificate/GET_CERTIFICATE_FAILED';

export const GET_CERTIFICATEBYID_START = 'certificate/GET_CERTIFICATEBYID_START';
export const GET_CERTIFICATEBYID_COMPLETED = 'certificate/GET_CERTIFICATEBYID_COMPLETED';
export const GET_CERTIFICATEBYID_FAILED = 'certificate/GET_CERTIFICATEBYID_FAILED';

export const GET_DEFAULTCERTIFICATEBYID_START = 'certificate/GET_DEFAULTCERTIFICATEBYID_START';

export const CREATE_CERTIFICATE_COMPLETED = 'certificate/CREATE_CERTIFICATE_COMPLETED';
export const CREATE_CERTIFICATE_FAILED = 'certificate/CREATE_CERTIFICATE_FAILED';

export const UPDATE_CERTIFICATE_COMPLETED = 'certificate/UPDATE_CERTIFICATE_COMPLETED';
export const UPDATE_CERTIFICATE_FAILED = 'certificate/UPDATE_CERTIFICATE_FAILED';

export const DELETE_CERTIFICATE_START = 'certificate/DELETE_CERTIFICATE_START';
export const DELETE_CERTIFICATE_COMPLETED = 'certificate/DELETE_CERTIFICATE_COMPLETED';
export const DELETE_CERTIFICATE_FAILED = 'certificate/DELETE_CERTIFICATE_FAILED';

export const SET_CERTIFICATE_MODE = 'certificate/SET_CERTIFICATE_MODE';

export const GET_COURSESFORCERTIFICATES_START = 'certificate/GET_COURSESFORCERTIFICATES_START';
export const GET_COURSESFORCERTIFICATES_COMPLETED = 'certificate/GET_COURSESFORCERTIFICATES_COMPLETED';
export const GET_COURSESFORCERTIFICATES_FAILED = 'certificate/GET_COURSESFORCERTIFICATES_FAILED';

export const SET_INPUT = 'certificate/SET_INPUT';

export const DELETE_CERTIFICATES_START = 'certificate/DELETE_CERTIFICATES_START';
export const DELETE_CERTIFICATES_FAILED = 'certificate/DELETE_CERTIFICATES_FAILED';
export const DELETE_CERTIFICATES_COMPLETED = 'certificate/DELETE_CERTIFICATES_COMPLETED';

export const DUPLICATE_CERTIFICATE_COMPLETED = 'certificate/DUPLICATE_CERTIFICATE_COMPLETED';

export const STATUS_CHANGE_CERTIFICATE = 'certificate/STATUS_CHANGE_CERTIFICATE';
