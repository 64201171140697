const initialState = {
   sortingVersion: 'Newest',
   isLoading: false,
   comments: null,
   isSearching: false,
   searchData: null,
   isProgressComment: false,
   replieProgress: false,
   comment: null,
};

export default initialState;
