export const GET_TRANSACTIONS_START = 'transactions/GET_TRANSACTIONS_START';
export const GET_TRANSACTIONS_COMPLETED = 'transactions/GET_TRANSACTIONS_COMPLETED';
export const GET_TRANSACTIONS_FAILED = 'transactions/GET_TRANSACTIONS_FAILED';

export const SEARCH_TRANSACTIONS_START = 'transactions/SEARCH_TRANSACTIONS_START';
export const SEARCH_TRANSACTIONS_COMPLETED = 'transactions/SEARCH_TRANSACTIONS_COMPLETED';
export const SEARCH_TRANSACTIONS_FAILED = 'transactions/SEARCH_TRANSACTIONS_FAILED';


export const GET_TRANSACTIONS_PAGINATION_START = 'transactions/GET_TRANSACTIONS_PAGINATION_START';
export const GET_TRANSACTIONS_PAGINATION_COMPLETED = 'transactions/GET_TRANSACTIONS_PAGINATION_COMPLETED';
export const GET_TRANSACTIONS_PAGINATION_FAILED = 'transactions/GET_TRANSACTIONS_PAGINATION_FAILED';
