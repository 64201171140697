import React from 'react';

export const DeleteItemIcon = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
         <g fill='none' fillRule='evenodd'>
            <path d='M0 0h24v24H0z' />
            <path fill='#C2CEDB' fillRule='nonzero' d='M4.286 20.125c0 1.036.863 1.875 1.928 1.875h11.572c1.065 0 1.928-.84 1.928-1.875V7H4.286v13.125zm10.928-10c0-.345.288-.625.643-.625.355 0 .643.28.643.625v8.75a.634.634 0 0 1-.643.625.634.634 0 0 1-.643-.625v-8.75zm-3.857 0c0-.345.288-.625.643-.625.355 0 .643.28.643.625v8.75A.634.634 0 0 1 12 19.5a.634.634 0 0 1-.643-.625v-8.75zm-3.857 0c0-.345.288-.625.643-.625.355 0 .643.28.643.625v8.75a.634.634 0 0 1-.643.625.634.634 0 0 1-.643-.625v-8.75zM20.357 3.25h-4.821l-.378-.73a.967.967 0 0 0-.864-.52H9.702a.956.956 0 0 0-.86.52l-.378.73H3.643A.634.634 0 0 0 3 3.875v1.25c0 .345.288.625.643.625h16.714c.355 0 .643-.28.643-.625v-1.25a.634.634 0 0 0-.643-.625z' />
         </g>
      </svg>
   );
};

export const DeleteItemGrayIcon = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
         <g fill='none' fillRule='evenodd'>
            <path d='M0 0h24v24H0z' />
            <path fill='#A9A8A8' fillRule='nonzero' d='M4.286 20.125c0 1.036.863 1.875 1.928 1.875h11.572c1.065 0 1.928-.84 1.928-1.875V7H4.286v13.125zm10.928-10c0-.345.288-.625.643-.625.355 0 .643.28.643.625v8.75a.634.634 0 0 1-.643.625.634.634 0 0 1-.643-.625v-8.75zm-3.857 0c0-.345.288-.625.643-.625.355 0 .643.28.643.625v8.75A.634.634 0 0 1 12 19.5a.634.634 0 0 1-.643-.625v-8.75zm-3.857 0c0-.345.288-.625.643-.625.355 0 .643.28.643.625v8.75a.634.634 0 0 1-.643.625.634.634 0 0 1-.643-.625v-8.75zM20.357 3.25h-4.821l-.378-.73a.967.967 0 0 0-.864-.52H9.702a.956.956 0 0 0-.86.52l-.378.73H3.643A.634.634 0 0 0 3 3.875v1.25c0 .345.288.625.643.625h16.714c.355 0 .643-.28.643-.625v-1.25a.634.634 0 0 0-.643-.625z' />
         </g>
      </svg>
   );
};

export const EditItemGrayIcon = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25'>
         <g fill='none' fillRule='evenodd'>
            <path d='M0 .628h24v24H0z' />
            <path d='M0 .628h24v24H0z' />
            <path fill='#A9A8A8' fillRule='nonzero' d='M16.377 5.874l3.445 3.524a.388.388 0 0 1 0 .54l-8.341 8.533-3.545.402c-.473.055-.875-.356-.821-.84l.393-3.626 8.342-8.533a.368.368 0 0 1 .527 0zm6.188-.894L20.7 3.073a1.47 1.47 0 0 0-2.109 0L17.24 4.456a.388.388 0 0 0 0 .54l3.445 3.523a.368.368 0 0 0 .527 0l1.353-1.383c.58-.597.58-1.563 0-2.156zm-6.898 11.17v3.977H3.444V7.625h8.778c.122 0 .236-.051.324-.137l1.528-1.563c.29-.297.084-.8-.325-.8H2.833C1.821 5.124 1 5.964 1 7v13.752c0 1.036.821 1.876 1.833 1.876h13.445c1.012 0 1.833-.84 1.833-1.876v-6.165a.458.458 0 0 0-.783-.332L15.8 15.818a.486.486 0 0 0-.133.332z' />
         </g>
      </svg>
   );
};

export const EditItemIcon = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25'>
         <g fill='none' fillRule='evenodd'>
            <path d='M0 .628h24v24H0z' />
            <path d='M0 .628h24v24H0z' />
            <path fill='#C2CEDB' fillRule='nonzero' d='M16.377 5.874l3.445 3.524a.388.388 0 0 1 0 .54l-8.341 8.533-3.545.402c-.473.055-.875-.356-.821-.84l.393-3.626 8.342-8.533a.368.368 0 0 1 .527 0zm6.188-.894L20.7 3.073a1.47 1.47 0 0 0-2.109 0L17.24 4.456a.388.388 0 0 0 0 .54l3.445 3.523a.368.368 0 0 0 .527 0l1.353-1.383c.58-.597.58-1.563 0-2.156zm-6.898 11.17v3.977H3.444V7.625h8.778c.122 0 .236-.051.324-.137l1.528-1.563c.29-.297.084-.8-.325-.8H2.833C1.821 5.124 1 5.964 1 7v13.752c0 1.036.821 1.876 1.833 1.876h13.445c1.012 0 1.833-.84 1.833-1.876v-6.165a.458.458 0 0 0-.783-.332L15.8 15.818a.486.486 0 0 0-.133.332z' />
         </g>
      </svg>
   );
};

export const LoginIcon = () => {
   return (
      <svg
         version='1.1'
         id='Capa_1'
         xmlns='http://www.w3.org/2000/svg'
         x='0px'
         y='0px'
         width='18px'
         height='24px'
         viewBox='0 0 95.667 95.667'
         xmlSpace='preserve'
      >
         <g>
            <g>
               <path
                  fill='#C2CEDB'
                  d='M39.173,72.344l39.447-22.777c0.619-0.356,1-1.018,1-1.731s-0.381-1.375-1-1.732L39.173,23.324
        c-0.619-0.357-1.381-0.357-2,0c-0.619,0.357-1,1.018-1,1.732v10.605H2.121c-1.104,0-2,0.896-2,2v20.344c0,1.104,0.896,2,2,2
        h34.053v10.604c0,0.716,0.381,1.375,1,1.732c0.31,0.18,0.655,0.268,1,0.268C38.519,72.609,38.864,72.521,39.173,72.344z'
               />
               <path
                  fill='#C2CEDB'
                  d='M80.775,0H40.026c-1.104,0-2,0.896-2,2v6c0,1.104,0.896,2,2,2h40.749c2.632,0,4.771,2.141,4.771,4.771v66.125
        c0,2.631-2.141,4.771-4.771,4.771H40.026c-1.104,0-2,0.896-2,2v6c0,1.104,0.896,2,2,2h40.749c8.146,0,14.771-6.627,14.771-14.771
        V14.772C95.546,6.627,88.92,0,80.775,0z'
               />
            </g>
         </g>
      </svg>
   );
};

export const MoreVerticalIcon = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
         <defs>
            <path id='prefix__a' d='M1.328 2.688c-.364 0-.677-.13-.937-.391C.13 2.037 0 1.719 0 1.344S.13.65.39.39C.652.13.965 0 1.329 0c.365 0 .677.13.938.39.26.261.39.579.39.954s-.13.692-.39.953c-.26.26-.573.39-.938.39zm0 1.312c.365 0 .677.13.938.39.26.261.39.579.39.954s-.13.692-.39.953c-.26.26-.573.39-.938.39-.364 0-.677-.13-.937-.39S0 5.719 0 5.344s.13-.693.39-.953C.652 4.13.965 4 1.329 4zm0 4c.365 0 .677.13.938.39.26.261.39.579.39.954s-.13.692-.39.953c-.26.26-.573.39-.938.39-.364 0-.677-.13-.937-.39S0 9.719 0 9.344s.13-.693.39-.953C.652 8.13.965 8 1.329 8z' />
         </defs>
         <g fill='none' fillRule='evenodd' transform='translate(10 7)'>
            <mask id='prefix__b' fill='#fff'>
               <use xlinkHref='#prefix__a' />
            </mask>
            <use fill='#333' xlinkHref='#prefix__a' />
            <g fill='#333' mask='url(#prefix__b)'>
               <path d='M0 0H72V72H0z' transform='translate(-34 -31)' />
            </g>
         </g>
      </svg>
   );
};


export const PrimaryIcon = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
         <g fill='none' fillRule='evenodd'>
            <path d='M0 0L24 0 24 24 0 24z' />
            <path fill='#A9A8A8' fillRule='nonzero' d='M19 1H5c-1.1 0-1.99.9-1.99 2L3 15.93c0 .69.35 1.3.88 1.66L12 23l8.11-5.41c.53-.36.88-.97.88-1.66L21 3c0-1.1-.9-2-2-2zm-7 19.6l-7-4.66V3h14v12.93l-7 4.67zm-2.01-7.42l-2.58-2.59L6 12l4 4 8-8-1.42-1.42-6.59 6.6z' />
         </g>
      </svg>
   );
};


export const TriangleSvgIcon = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
         <g fill='none' fillRule='evenodd'>
            <path d='M0 0h24v24H0z' />
            <path fill='#3F4F65' fillRule='nonzero' d='M8.667 17.714V6.286L15.333 12z' />
         </g>
      </svg>
   );
};
