export const GET_BLOG_START = 'blog/GET_BLOG_START';
export const GET_BLOG_COMPLETED = 'blog/GET_BLOG_COMPLETED';
export const GET_BLOG_FAILED = 'blog/GET_BLOG_FAILED';

export const DELETE_BLOG_START = 'blog/DELETE_BLOG_START';
export const DELETE_BLOG_COMPLETED = 'blog/DELETE_BLOG_COMPLETED';
export const DELETE_BLOG_FAILED = 'blog/DELETE_BLOG_FAILED';

export const SAVE_BLOGSETTINGS_START = 'blog/SAVE_BLOGSETTINGS_START';
export const SAVE_BLOGSETTINGS_COMPLETED = 'blog/SAVE_BLOGSETTINGS_COMPLETED';
export const SAVE_BLOGSETTINGS_FAILED = 'blog/SAVE_BLOGSETTINGS_FAILED';

export const GET_BLOGSETTINGS_START = 'blog/GET_BLOGSETTINGS_START';
export const GET_BLOGSETTINGS_COMPLETED = 'blog/GET_BLOGSETTINGS_COMPLETED';
export const GET_BLOGSETTINGS_FAILED = 'blog/GET_BLOGSETTINGS_FAILED';

export const GET_BLOGPOST_START = 'blog/GET_BLOGPOST_START';
export const GET_BLOGPOST_COMPLETED = 'blog/GET_BLOGPOST_COMPLETED';
export const GET_BLOGPOST_FAILED = 'blog/GET_BLOGPOST_FAILED';

export const SAVE_BLOGPOST_START = 'blog/SAVE_BLOGPOST_START';
export const SAVE_BLOGPOST_COMPLETED = 'blog/SAVE_BLOGPOST_COMPLETED';
export const SAVE_BLOGPOST_FAILED = 'blog/SAVE_BLOGPOST_FAILED';


export const SET_INPUT = 'blog/SET_INPUT';

export const GET_BLOGCATEGORIES_START = 'blog/GET_BLOGCATEGORIES_START';
export const GET_BLOGCATEGORIES_COMPLETED = 'blog/GET_BLOGCATEGORIES_COMPLETED';
export const GET_BLOGCATEGORIES_FAILED = 'blog/GET_BLOGCATEGORIES_FAILED';


export const ATTACH_BLOGCATEGORIES_START = 'blog/ATTACH_BLOGCATEGORIES_START';
export const ATTACH_BLOGCATEGORIES_COMPLETED = 'blog/ATTACH_BLOGCATEGORIES_COMPLETED';
export const ATTACH_BLOGCATEGORIES_FAILED = 'blog/ATTACH_BLOGCATEGORIES_FAILED';

export const ADD_BLOGCATEGORY_START = 'blog/ADD_BLOGCATEGORY_START';
export const ADD_BLOGCATEGORY_COMPLETED = 'blog/ADD_BLOGCATEGORY_COMPLETED';
export const ADD_BLOGCATEGORY_FAILED = 'blog/ADD_BLOGCATEGORY_FAILED';

export const GET_FILTEREDBLOG_COMPLETED = 'blog/GET_FILTEREDBLOG_COMPLETED';

export const UPDATE_BLOG_CATEGORY_COMLETED = 'blog/UPDATE_BLOG_CATEGORY_COMLETED';
export const DELETE_BLOG_CATEGORY_COMLETED = 'blog/UPDATE_BLOG_CATEGORY_COMLETED';
export const UPDATE_BLOG_ATTACHED_CATEGORY = 'blog/UPDATE_BLOG_ATTACHED_CATEGORY';

export const DELETE_BLOGMULTIPLE_START = 'blog/DELETE_BLOGMULTIPLE_START';
export const DELETE_BLOGMULTIPLE_FAILED = 'blog/DELETE_BLOGMULTIPLE_FAILED';
export const DELETE_BLOGMULTIPLE_COMPLETED = 'blog/DELETE_BLOGMULTIPLE_COMPLETED';
