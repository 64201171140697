export const SET_INPUT = 'settings/SET_INPUT';
export const SET_INIT_DATA_START = 'settings/SET_INIT_DATA_START';
export const SET_INIT_DATA_COMPLETED = 'settings/SET_INIT_DATA_COMPLETED';
export const SET_INIT_DATA_FAILED = 'settings/SET_INIT_DATA_FAILED';
export const PUT_SETTINGS_START = 'settings/PUT_SETTINGS_START';
export const PUT_SETTINGS_COPMLATED = 'settings/PUT_SETTINGS_COPMLATED';
export const PUT_SETTINGS_FAILED = 'settings/PUT_SETTINGS_FAILED';
export const GET_SETTINGS_START = 'settings/GET_SETTINGS_START';
export const GET_SETTINGS_COMPLETED = 'settings/GET_SETTINGS_COMPLETED';
export const GET_SETTINGS_FAILED = 'settings/GET_SETTINGS_FAILED';
export const CREATE_CUSTOMLINK_START = 'settings/CREATE_CUSTOMLINK_START';
export const CREATE_CUSTOMLINK_COMPLETED = 'settings/CREATE_CUSTOMLINK_COMPLETED';
export const CREATE_CUSTOMLINK_FAILED = 'settings/CREATE_CUSTOMLINK_FAILED';
export const UPDATE_CUSTOMLINK_START = 'settings/UPDATE_CUSTOMLINK_START';
export const UPDATE_CUSTOMLINK_COMPLETED = 'settings/UPDATE_CUSTOMLINK_COMPLETED';
export const UPDATE_CUSTOMLINK_FAILED = 'settings/UPDATE_CUSTOMLINK_FAILED';
export const DELETE_CUSTOMLINK_START = 'settings/DELETE_CUSTOMLINK_START';
export const DELETE_CUSTOMLINK_COMPLETED = 'settings/DELETE_CUSTOMLINK_COMPLETED';
export const DELETE_CUSTOMLINK_FAILED = 'settings/DELETE_CUSTOMLINK_FAILED';
export const CANCEL_CHANGES = 'settings/CANCEL_CHANGES';
export const GET_VIDEO_START = 'settings/GET_VIDEO_START';
export const GET_VIDEO_COMPLETED = 'settings/GET_VIDEO_COMPLETED';
export const GET_VIDEO_FAILED = 'settings/GET_VIDEO_FAILED';
export const GET_VIDEOS_START = 'settings/GET_VIDEOS_START';
export const GET_VIDEOS_COMPLETED = 'settings/GET_VIDEOS_COMPLETED';
export const GET_VIDEOS_FAILED = 'settings/GET_VIDEOS_FAILED';
export const GET_VIDEOSBYDATE_START = 'settings/GET_VIDEOSBYDATE_START';
export const GET_VIDEOSBYDATE_COMPLETED = 'settings/GET_VIDEOSBYDATE_COMPLETED';
export const GET_VIDEOSBYDATE_FAILED = 'settings/GET_VIDEOSBYDATE_FAILED';
export const CONNECT_INTEGRATION_COMPLETED = 'settings/CONNECT_INTEGRATION_COMPLETED';
export const COLOR_SWITCH = 'settings/COLOR_SWITCH';
export const DELETE_DOMAIN_START = 'settings/DELETE_DOMAIN_START';
export const DELETE_DOMAIN_COMPLETED = 'settings/DELETE_DOMAIN_COMPLETED';
export const DELETE_DOMAIN_FAILED = 'settings/DELETE_DOMAIN_FAILED';
export const CUSTOMLINK_ORDER_COMPLETED = 'courses/edit/CUSTOMLINK_ORDER_COMPLETED';
export const CUSTOMLINK_ORDER_START = 'courses/edit/CUSTOMLINK_ORDER_START';
export const CUSTOMLINK_ORDER_FAILED = 'courses/edit/CUSTOMLINK_ORDER_FAILED';
export const CREATE_CUSTOMLINK_OPEN_MODAL = 'settings/CREATE_CUSTOMLINK_OPEN_MODAL';
export const GET_FILE_SIZE_START = 'settings/GET_FILE_SIZE_START';
export const GET_FILE_SIZE_COMPLETED = 'settings/GET_FILE_SIZE_COMPLETED';
export const GET_FILE_SIZE_FAILED = 'settings/GET_FILE_SIZE_FAILED';
export const GET_APIKEY_START = 'settings/GET_APIKEY_START';
export const GET_APIKEY_COMPLETED = 'settings/GET_APIKEY_COMPLETED';
export const GET_APIKEY_FAILED = 'settings/GET_APIKEY_FAILED';
export const CREATE_APIKEY_START = 'settings/CREATE_APIKEY_START';
export const CREATE_APIKEY_COMPLETED = 'settings/CREATE_APIKEY_COMPLETED';
export const CREATE_APIKEY_FAILED = 'settings/CREATE_APIKEY_FAILED';
export const GET_ALL_COURSES_COMPLETED = 'settings/GET_ALL_COURSES_START';
export const UPDATE_CLASS_DATA_COMPLETED = 'settings/UPDATE_CLASS_DATA_COMPLETED';
export const GET_FROM_EMAIL_START = 'settings/GET_FROM_EMAIL_START';
export const GET_FROM_EMAIL_COMPLETED = 'settings/GET_FROM_EMAIL_COMPLETED';
export const GET_FROM_EMAIL_FAILED = 'settings/GET_FROM_EMAIL_FAILED';
export const SET_DNS_SETTINGS_STATE = 'settings/SET_DNS_SETTINGS_STATE';

export const CREATE_SCRIPT_START = 'settings/CREATE_SCRIPT_START';
export const CREATE_SCRIPT_COMPLETED = 'settings/CREATE_SCRIPT_COMPLETED';
export const CREATE_SCRIPT_FAILED = 'settings/CREATE_SCRIPT_FAILED';

export const GET_SCRIPTS_START = 'settings/GET_SCRIPTS_START';
export const GET_SCRIPTS_COMPLETED = 'settings/GET_SCRIPTS_COMPLETED';
export const GET_SCRIPTS_FAILED = 'settings/GET_SCRIPTS_FAILED';

export const DELETE_SCRIPT_START = 'settings/DELETE_SCRIPT_START';
export const DELETE_SCRIPT_COMPLETED = 'settings/DELETE_SCRIPT_COMPLETED';
export const DELETE_SCRIPT_FAILED = 'settings/DELETE_SCRIPT_FAILED';

export const UPDATE_SCRIPT_START = 'settings/UPDATE_SCRIPT_START';
export const UPDATE_SCRIPT_COMPLETED = 'settings/UPDATE_SCRIPT_COMPLETED';
export const UPDATE_SCRIPT_FAILED = 'settings/UPDATE_SCRIPT_FAILED';

export const SET_SCRIPT_INPUT = 'settings/SET_SCRIPT_INPUT';
export const CHOOSE_SCRIPT = 'settings/CHOOSE_SCRIPT';
export const EMPTY_SCRIPT = 'settings/EMPTY_SCRIPT';
export const DISABLE_DISCONNECT_INTEGRATION = 'settings/DISABLE_DISCONNECT_INTEGRATION';
export const ENABLE_DISCONNECT_INTEGRATION = 'settings/ENABLE_DISCONNECT_INTEGRATION';

export const GET_NOTIFICATIONS_START = 'settings/GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_COMPLECTED = 'settings/GET_NOTIFICATIONS_COMPLECTED';
export const GET_NOTIFICATIONS_FAILED = 'settings/GET_NOTIFICATIONS_FAILED';

export const CHANGE_NOTIFY = 'settings/CHANGE_NOTIFY';

export const SAVE_NOTIFICATIONS_DATA_START = 'settings/SAVE_NOTIFICATIONS_DATA_START';
export const SAVE_NOTIFICATIONS_DATA_COMPLETED = 'settings/SAVE_NOTIFICATIONS_DATA_COMPLETED';
export const SAVE_NOTIFICATIONS_DATA_FAILED = 'settings/SAVE_NOTIFICATIONS_DATA_FAILED';

export const SAVE_CODES_SETTINGS_START = 'settings/SAVE_CODES_SETTINGS_START';
export const SAVE_CODES_SETTINGS_FAILED = 'settings/SAVE_CODES_SETTINGS_FAILED';
export const SAVE_CODES_SETTINGS_COMPLETED = 'settings/SAVE_CODES_SETTINGS_COMPLETED';

export const GET_CODES_SETTINGS_START = 'settings/GET_CODES_SETTINGS_START';
export const GET_CODES_SETTINGS_FAILED = 'settings/GET_CODES_SETTINGS_FAILED';
export const GET_CODES_SETTINGS_COMPLETED = 'settings/GET_CODES_SETTINGS_COMPLETED';
