const prefix = 'studentCourses/courses';

export const GET_COURSES_START = `${ prefix }/GET_COURSES_START`;
export const GET_COURSES_COMPLETED = `${ prefix }/GET_COURSES_COMPLETED`;
export const GET_CATEGORIES_START = `${ prefix }/GET_CATEGORIES_START`;
export const GET_CATEGORIES_COMPLETED = `${ prefix }/GET_CATEGORIES_COMPLETED`;
export const GET_COURSES_FAILED = `${ prefix }/GET_COURSES_FAILED`;
export const GET_COURSES_BY_SEARCH_START = `${ prefix }/GET_COURSES_BY_SEARCH_START`;
export const GET_COURSES_BY_SEARCH_COMPLETED = `${ prefix }/GET_COURSES_BY_SEARCH_COMPLETED`;
export const GET_COURSES_BY_SEARCH_FAILED = `${ prefix }/GET_COURSES_BY_SEARCH_FAILED`;

export const FILTERED_COURSES_START = `${ prefix }/FILTERED_COURSES_START`;
export const FILTERED_COURSES_COMPLETED = `${ prefix }/FILTERED_COURSES_COMPLETED`;
export const FILTERED_COURSES_FAILED = `${ prefix }/FILTERED_COURSES_FAILED`;
export const EXIT_VIEW_ALL = `${ prefix }/EXIT_VIEW_ALL`;
export const GET_UNCATEGORIZED_COURSES_START = `${ prefix }/GET_UNCATEGORIZED_COURSES_START`;
export const GET_UNCATEGORIZED_COURSES_COMPLETED = `${ prefix }/GET_UNCATEGORIZED_COURSES_COMPLETED`;

export const LOAD_MORE_COURSES_START = `${ prefix }/LOAD_MORE_COURSES_START`;
export const LOAD_MORE_COURSES_COMPLETED = `${ prefix }/LOAD_MORE_COURSES_COMPLETED`;
export const LOAD_MORE_CATEGORIES_COMPLETED = `${ prefix }/LOAD_MORE_CATEGORIES_COMPLETED`;

export const SEARCH_COURSES_START = `${ prefix }/SEARCH_COURSES_START`;
export const SEARCH_COURSES_COMPLETED = `${ prefix }/SEARCH_COURSES_COMPLETED`;
export const SEARCH_COURSES_FAILED = `${ prefix }/SEARCH_COURSES_FAILED`;

export const LOAD_MORE_SEARCHED_COURSES_START = `${ prefix }/LOAD_MORE_SEARCHED_COURSES_START`;
export const LOAD_MORE_SEARCHED_COURSES_COMPLETED = `${ prefix }/LOAD_MORE_SEARCHED_COURSES_COMPLETED`;
export const LOAD_MORE_SEARCHED_COURSES_FAILED = `${ prefix }/LOAD_MORE_SEARCHED_COURSES_FAILED`;

export const GET_FRONTSCRIPTS_COMPLETED = `${ prefix }/GET_FRONTSCRIPTS_COMPLETED`;

export const SEARCH_OFFERS_START = `${ prefix }/SEARCH_OFFERS_START`;
export const SEARCH_OFFERS_COMPLETED = `${ prefix }/SEARCH_OFFERS_COMPLETED`;
export const SEARCH_OFFERS_FAILED = `${ prefix }/SEARCH_OFFERS_FAILED`;

export const FAVORITE_OFFER_COMPLETED = `${ prefix }/FAVORITE_OFFER_COMPLETED`;
