import { logout } from 'api';

export default {
   setToken: (token) => {
      return localStorage.setItem('authToken', token);
   },
   getToken: () => {
      return localStorage.getItem('authToken');
   },
   isTokenExists: () => {
      return !!localStorage.getItem('authToken');
   },
   logout: () => {
      logout(localStorage.getItem('authToken'));
      return localStorage.removeItem('authToken');
   },
};
