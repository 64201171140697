const initialState = {
   categories: [],
   isFetchingData: false,
   isFiltering: false,
   categoriesInitialCount: 0,
   updateProgress: false,
   detachedCourses: [],
   isProgressDetachCourses: false,
   isProcesingCourseOperation: false,
};

export default initialState;
