export const REORDER_CATEGORIES_COMLETED = 'categories/REORDER_CATEGORIES_COMLETED';

export const GET_CATEGORIES_START = 'categories/GET_CATEGORIES_START';
export const GET_CATEGORIES_END = 'categories/GET_CATEGORIES_END';
export const GET_CATEGORIES_COMPLETED = 'categories/GET_CATEGORIES_COMPLETED';

export const CREATE_CATEGORY_START = 'categories/CREATE_CATEGORY_START';
export const CREATE_CATEGORY_END = 'categories/CREATE_CATEGORY_END';
export const CREATE_CATEGORY_COMPLETED = 'categories/CREATE_CATEGORY_COMPLETED';

export const CATEGORIES_FILTER_START = 'categories/CATEGORIES_FILTER_START';
export const CATEGORIES_FILTER_END = 'categories/CATEGORIES_FILTER_END';

export const UPDATE_CATEGORY_START = 'categories/UPDATE_CATEGORY_START';
export const UPDATE_CATEGORY_COMPLETED = 'categories/UPDATE_CATEGORY_COMPLETED';
export const UPDATE_CATEGORY_FAILED = 'categories/UPDATE_CATEGORY_FAILED';

export const REMOVE_CATEGORY_COMPLETED = 'categories/REMOVE_CATEGORY_COMPLETED';
export const REMOVE_CATEGORY_FAILED = 'categories/REMOVE_CATEGORY_FAILED';
export const REMOVE_CATEGORY_START = 'categories/REMOVE_CATEGORY_START';

export const GET_DETACHED_COURSES_START = 'categories/GET_DETACHED_COURSES_START';
export const GET_DETACHED_COURSES_END = 'categories/GET_DETACHED_COURSES_END';

export const ATTACH_DETACH_COURSE_START = 'categories/ATTACH_DETACH_COURSE_START';
export const ATTACH_DETACH_COURSE_FAILED = 'categories/ATTACH_DETACH_COURSE_FAILED';
export const ATTACH_DETACH_COURSE_COMPLETED = 'categories/ATTACH_DETACH_COURSE_COMPLETED';
