export const SET_INPUT = 'studentAccount/SET_INPUT';
export const SET_INIT_DATA_START = 'studentAccount/SET_INIT_DATA_START';
export const SET_INIT_DATA_COMPLETED = 'studentAccount/SET_INIT_DATA_COMPLETED';
export const SET_INIT_DATA_FAILED = 'studentAccount/SET_INIT_DATA_FAILED';


export const UPDATE_ACCOUNT_START = 'studentAccount/UPDATE_ACCOUNT_START';
export const UPDATE_ACCOUNT_COPMLATED = 'studentAccount/UPDATE_ACCOUNT_COPMLATED';
export const UPDATE_ACCOUNT_FAILED = 'studentAccount/UPDATE_ACCOUNT_FAILED';


export const GET_ACCOUNT_START = 'studentAccount/GET_ACCOUNT_START';
export const GET_ACCOUNT_COMPLETED = 'studentAccount/GET_ACCOUNT_COMPLETED';
export const GET_ACCOUNT_FAILED = 'studentAccount/GET_ACCOUNT_FAILED';

export const RESET_COURSE_START = 'studentAccount/RESET_COURSE_START';
export const RESET_COURSE_COMPLETED = 'studentAccount/RESET_COURSE_COMPLETED';
export const RESET_COURSE_FAILED = 'studentAccount/RESET_COURSE_FAILED';

export const CANCEL_CHANGES = 'studentAccount/CANCEL_CHANGES';

export const CANCEL_SUBSCRIPTION_START = 'studentAccount/CANCEL_SUBSCRIPTION_START';
export const CANCEL_SUBSCRIPTION_COMPLETED = 'studentAccount/CANCEL_SUBSCRIPTION_COMPLETED';
export const CANCEL_SUBSCRIPTION_FAILED = 'studentAccount/CANCEL_SUBSCRIPTION_FAILED';
