import React from 'react';
import PropTypes from 'prop-types';


export const ArrowUpIcon = ({ style, color }) => {
   return (
      <svg style={ style } width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M5.15451 1.93869C5.05409 1.90606 4.94591 1.90606 4.84549 1.93869C4.8191 1.94726 4.77017 1.96955 4.66628 2.05775C4.55792 2.14974 4.42603 2.28108 4.22218 2.48492L1.35355 5.35355C1.15829 5.54882 0.841709 5.54882 0.646447 5.35355C0.451184 5.15829 0.451184 4.84171 0.646447 4.64645L3.51508 1.77782L3.5287 1.76419C3.71507 1.57781 3.87585 1.41702 4.0191 1.29541C4.1703 1.16705 4.33453 1.05324 4.53647 0.98763C4.83774 0.889743 5.16226 0.889743 5.46353 0.98763C5.66547 1.05324 5.8297 1.16705 5.9809 1.29541C6.12415 1.41702 6.28493 1.57781 6.4713 1.76419L6.48492 1.77782L9.35355 4.64645C9.54882 4.84171 9.54882 5.15829 9.35355 5.35355C9.15829 5.54882 8.84171 5.54882 8.64645 5.35355L5.77782 2.48493C5.57398 2.28108 5.44208 2.14974 5.33372 2.05775C5.22983 1.96955 5.1809 1.94726 5.15451 1.93869Z' fill={ color || '#131F1E' } />
      </svg>
   );
};
export const ArrowDownIcon = ({ style, color }) => {
   return (
      <svg style={ style } width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M4.84549 4.47553C4.94591 4.50816 5.05409 4.50816 5.15451 4.47553C5.1809 4.46695 5.22983 4.44467 5.33372 4.35647C5.44208 4.26448 5.57397 4.13313 5.77782 3.92929L8.64645 1.06066C8.84171 0.865398 9.15829 0.865398 9.35355 1.06066C9.54882 1.25592 9.54882 1.57251 9.35355 1.76777L6.48492 4.6364L6.4713 4.65002C6.28493 4.8364 6.12415 4.99719 5.9809 5.11881C5.8297 5.24716 5.66547 5.36097 5.46353 5.42658C5.16226 5.52447 4.83774 5.52447 4.53647 5.42658C4.33453 5.36097 4.1703 5.24716 4.0191 5.1188C3.87585 4.99719 3.71507 4.8364 3.5287 4.65002L3.51508 4.6364L0.646447 1.76777C0.451185 1.5725 0.451185 1.25592 0.646447 1.06066C0.84171 0.865397 1.15829 0.865397 1.35355 1.06066L4.22218 3.92929C4.42603 4.13313 4.55792 4.26447 4.66628 4.35647C4.77017 4.44467 4.8191 4.46695 4.84549 4.47553Z' fill={ color || '#131F1E' } />
      </svg>
   );
};
export const ArrowDownSmallIcon = () => {
   return (
      <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M4.84549 4.47553C4.94591 4.50816 5.05409 4.50816 5.15451 4.47553C5.1809 4.46695 5.22983 4.44467 5.33372 4.35647C5.44208 4.26448 5.57397 4.13313 5.77782 3.92929L8.64645 1.06066C8.84171 0.865398 9.15829 0.865398 9.35355 1.06066C9.54882 1.25592 9.54882 1.57251 9.35355 1.76777L6.48492 4.6364L6.4713 4.65002C6.28493 4.8364 6.12415 4.99719 5.9809 5.11881C5.8297 5.24716 5.66547 5.36097 5.46353 5.42658C5.16226 5.52447 4.83774 5.52447 4.53647 5.42658C4.33453 5.36097 4.1703 5.24716 4.0191 5.1188C3.87585 4.99719 3.71507 4.8364 3.5287 4.65002L3.51508 4.6364L0.646447 1.76777C0.451185 1.5725 0.451185 1.25592 0.646447 1.06066C0.84171 0.865397 1.15829 0.865397 1.35355 1.06066L4.22218 3.92929C4.42603 4.13313 4.55792 4.26447 4.66628 4.35647C4.77017 4.44467 4.8191 4.46695 4.84549 4.47553Z' fill='#131F1E' />
      </svg>
   );
};
export const ArrowUpSmallIcon = () => {
   return (
      <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M5.15451 1.93853C5.05409 1.90591 4.94591 1.90591 4.84549 1.93853C4.8191 1.94711 4.77017 1.9694 4.66628 2.0576C4.55792 2.14959 4.42603 2.28093 4.22218 2.48477L1.35355 5.3534C1.15829 5.54866 0.841709 5.54866 0.646447 5.3534C0.451184 5.15814 0.451184 4.84156 0.646447 4.6463L3.51508 1.77767L3.5287 1.76404C3.71507 1.57766 3.87585 1.41687 4.0191 1.29526C4.1703 1.1669 4.33453 1.05309 4.53647 0.987478C4.83774 0.889591 5.16226 0.889591 5.46353 0.987478C5.66547 1.05309 5.8297 1.1669 5.9809 1.29526C6.12415 1.41687 6.28493 1.57766 6.4713 1.76404L6.48492 1.77767L9.35355 4.6463C9.54882 4.84156 9.54882 5.15814 9.35355 5.3534C9.15829 5.54866 8.84171 5.54866 8.64645 5.3534L5.77782 2.48477C5.57398 2.28093 5.44208 2.14959 5.33372 2.0576C5.22983 1.9694 5.1809 1.94711 5.15451 1.93853Z' fill='#131F1E' />
      </svg>
   );
};
export const ArrowLeftIcon = ({ style, color }) => {
   return (
      <svg style={ style } width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M6.02021 0.646447C6.21547 0.841709 6.21547 1.15829 6.02021 1.35355L3.28296 4.09081C2.8811 4.49266 2.60125 4.77306 2.40099 5.00897C2.20451 5.24041 2.11417 5.39592 2.0685 5.53647C1.97061 5.83774 1.97061 6.16226 2.0685 6.46353C2.11417 6.60408 2.20451 6.75959 2.40099 6.99103C2.60125 7.22694 2.8811 7.50734 3.28295 7.90919L6.02021 10.6464C6.21547 10.8417 6.21547 11.1583 6.02021 11.3536C5.82495 11.5488 5.50837 11.5488 5.31311 11.3536L2.57585 8.61629L2.56071 8.60116C2.17753 8.21799 1.86847 7.90893 1.63865 7.63821C1.40201 7.35947 1.22015 7.08865 1.11745 6.77254C0.9543 6.27043 0.9543 5.72957 1.11745 5.22746C1.22015 4.91135 1.40201 4.64053 1.63865 4.36179C1.86847 4.09107 2.17753 3.78201 2.56071 3.39884L2.57585 3.3837L5.31311 0.646447C5.50837 0.451184 5.82495 0.451184 6.02021 0.646447Z' fill={ color || '#131F1E' } />
      </svg>
   );
};
export const ArrowLeftLargeIcon = () => {
   return (
      <svg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M8.53033 0.469673C8.82323 0.762567 8.82322 1.23744 8.53033 1.53033L2.81067 7.24993H19C19.4142 7.24993 19.75 7.58572 19.75 7.99993C19.75 8.41414 19.4142 8.74993 19 8.74993H2.81066L8.53033 14.4696C8.82322 14.7625 8.82322 15.2374 8.53033 15.5303C8.23744 15.8232 7.76256 15.8232 7.46967 15.5303L0.46967 8.53026C0.329017 8.38961 0.25 8.19884 0.25 7.99993C0.25 7.80102 0.329019 7.61025 0.469673 7.4696L7.46967 0.469667C7.76257 0.176776 8.23744 0.176778 8.53033 0.469673Z' fill='#131F1E' />
      </svg>
   );
};
export const ArrowRightIcon = ({ style, color }) => {
   return (
      <svg style={ style } width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M0.97979 0.646447C1.17505 0.451184 1.49163 0.451184 1.6869 0.646447L4.42416 3.38371L4.43929 3.39884C4.82247 3.78201 5.13153 4.09107 5.36136 4.36179C5.59799 4.64053 5.77985 4.91135 5.88256 5.22746C6.0457 5.72957 6.0457 6.27043 5.88256 6.77254C5.77985 7.08865 5.59799 7.35947 5.36136 7.63821C5.13153 7.90893 4.82246 8.21799 4.43928 8.60117L4.42416 8.6163L1.6869 11.3536C1.49163 11.5488 1.17505 11.5488 0.97979 11.3536C0.784528 11.1583 0.784528 10.8417 0.97979 10.6464L3.71705 7.90919C4.1189 7.50734 4.39875 7.22694 4.59902 6.99103C4.7955 6.75959 4.88583 6.60408 4.9315 6.46353C5.02939 6.16226 5.02939 5.83774 4.9315 5.53647C4.88583 5.39592 4.7955 5.24041 4.59902 5.00897C4.39875 4.77306 4.1189 4.49266 3.71705 4.09081L0.97979 1.35355C0.784528 1.15829 0.784528 0.841709 0.97979 0.646447Z' fill={ color || '#131F1E' } />
      </svg>
   );
};

export const ArrowStatusDownIcon = () => {
   return (
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <rect x='0.475098' y='0.474609' width='19.05' height='19.05' rx='4' fill='#FFF1F1' />
         <path d='M9.50003 6.19043C9.50003 5.91429 9.72389 5.69043 10 5.69043C10.2762 5.69043 10.5 5.91429 10.5 6.19043L9.50003 6.19043ZM10 13.8095L10.3536 14.163C10.1583 14.3583 9.84174 14.3583 9.64648 14.163L10 13.8095ZM6.78934 11.3059C6.59408 11.1106 6.59408 10.794 6.78934 10.5988C6.9846 10.4035 7.30118 10.4035 7.49644 10.5988L6.78934 11.3059ZM12.5036 10.5988C12.6989 10.4035 13.0155 10.4035 13.2107 10.5988C13.406 10.794 13.406 11.1106 13.2107 11.3059L12.5036 10.5988ZM10.5 6.19043L10.5 13.8095L9.50003 13.8095L9.50003 6.19043L10.5 6.19043ZM9.64648 14.163L6.78934 11.3059L7.49644 10.5988L10.3536 13.4559L9.64648 14.163ZM9.64648 13.4559L12.5036 10.5988L13.2107 11.3059L10.3536 14.163L9.64648 13.4559Z' fill='#D12D36' />
      </svg>
   );
};

export const ArrowStatusUpIcon = () => {
   return (
      <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <rect x='0.975098' y='0.474609' width='19.05' height='19.05' rx='4' fill='#DEFBE6' />
         <path d='M10.5 13.8096L10.5 6.19052M10.5 6.19052L13.3571 9.04767M10.5 6.19052L7.64282 9.04767' stroke='#379552' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
   );
};

export const ArrowSelectMIcon = () => {
   return (
      <svg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M0.146447 0.979454C0.341709 0.784192 0.658291 0.784192 0.853553 0.979454L3.59081 3.71671C3.99266 4.11857 4.27306 4.39841 4.50897 4.59868C4.74041 4.79516 4.89592 4.8855 5.03647 4.93117C5.33774 5.02905 5.66226 5.02905 5.96353 4.93117C6.10408 4.8855 6.25959 4.79516 6.49103 4.59868C6.72694 4.39841 7.00734 4.11856 7.40919 3.71671L10.1464 0.979454C10.3417 0.784192 10.6583 0.784192 10.8536 0.979454C11.0488 1.17472 11.0488 1.4913 10.8536 1.68656L8.1163 4.42382L8.10116 4.43896C7.71799 4.82214 7.40893 5.1312 7.13821 5.36102C6.85947 5.59765 6.58865 5.77951 6.27254 5.88222C5.77043 6.04537 5.22957 6.04537 4.72746 5.88222C4.41135 5.77951 4.14053 5.59766 3.86179 5.36102C3.59107 5.1312 3.28201 4.82214 2.89884 4.43896L2.88371 4.42382L0.146447 1.68656C-0.0488155 1.4913 -0.0488155 1.17472 0.146447 0.979454Z' fill='#131F1E' />
      </svg>
   );
};

export const PaginationArrowIcon = ({ style }) => {
   return (
      <svg style={ style } width='4' height='8' viewBox='0 0 4 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M3.8534 0.646447C4.04866 0.841709 4.04866 1.15829 3.8534 1.35355L1.98477 3.22218L1.98477 3.22218C1.78093 3.42603 1.64959 3.55792 1.5576 3.66628C1.4694 3.77017 1.44711 3.8191 1.43853 3.84549C1.40591 3.94591 1.40591 4.05409 1.43853 4.15451C1.44711 4.1809 1.4694 4.22983 1.5576 4.33372C1.64959 4.44208 1.78093 4.57398 1.98477 4.77782L3.8534 6.64645C4.04866 6.84171 4.04866 7.15829 3.8534 7.35355C3.65814 7.54882 3.34156 7.54882 3.1463 7.35355L1.27767 5.48492L1.26403 5.47129C1.07765 5.28492 0.916867 5.12415 0.795257 4.9809C0.666902 4.8297 0.553092 4.66547 0.487478 4.46353C0.389591 4.16226 0.389591 3.83774 0.487478 3.53647C0.553092 3.33453 0.666902 3.1703 0.795257 3.0191C0.916869 2.87585 1.07766 2.71507 1.26404 2.5287L1.27767 2.51508L1.27767 2.51508L3.1463 0.646447C3.34156 0.451184 3.65814 0.451184 3.8534 0.646447Z' fill='#131F1E' />
      </svg>
   );
};

ArrowUpIcon.propTypes = {
   color: PropTypes.string,
   style: PropTypes.object,
};

ArrowDownIcon.propTypes = {
   color: PropTypes.string,
   style: PropTypes.object,
};

ArrowLeftIcon.propTypes = {
   color: PropTypes.string,
   style: PropTypes.object,
};

ArrowRightIcon.propTypes = {
   color: PropTypes.string,
   style: PropTypes.object,
};
ArrowStatusDownIcon.propTypes = {

};

ArrowStatusUpIcon.propTypes = {

};
ArrowSelectMIcon.propTypes = {};
ArrowLeftLargeIcon.propTypes = {};
PaginationArrowIcon.propTypes = {
   style: PropTypes.any,
};
ArrowDownSmallIcon.propTypes = {

};
ArrowUpSmallIcon.propTypes = {};
