export const GET_MEDIAS_START = 'medialibrary/GET_MEDIAS_START';
export const GET_MEDIAS_COMPLETED = 'medialibrary/GET_MEDIAS_COMPLETED';
export const GET_MEDIAS_FAILED = 'medialibrary/GET_MEDIAS_FAILED';

export const SET_MEDIAS_MODE = 'medialibrary/SET_MEDIAS_MODE';
export const SET_ACTIVE_TAB = 'medialibrary/SET_ACTIVE_TAB';

export const UPDATE_MEDIA_NAME_COMPLETED = 'medialibrary/UPDATE_MEDIA_NAME_COMPLETED';
export const DELETE_MEDIA_COMPLETED = 'medialibrary/DELETE_MEDIA_COMPLETED';
export const UPDATE_MEDIA_NAME_FAILED = 'medialibrary/UPDATE_MEDIA_NAME_FAILED';

export const CREATE_MEDIA_COMPLETED = 'medialibrary/CREATE_MEDIA_COMPLETED';
export const CREATE_MEDIA_FAILED = 'medialibrary/CREATE_MEDIA_FAILED';

export const GET_TAGS_START = 'medialibrary/GET_TAGS_START';
export const GET_TAGS_COMPLTED = 'medialibrary/GET_TAGS_COMPLTED';
export const GET_TAGS_FAILED = 'medialibrary/GET_TAGS_FAILED';


export const SET_TAGS_START = 'medialibrary/SET_TAGS_START';
export const SET_TAGS_COMPLETED = 'medialibrary/SET_TAGS_COMPLETED';
export const SET_TAGS_FAILED = 'medialibrary/SET_TAGS_FAILED';

export const GET_FOLDERS_START = 'medialibrary/GET_FOLDERS_START';
export const GET_FOLDERS_COMPLETED = 'medialibrary/GET_FOLDERS_COMPLETED';
export const GET_FOLDERS_FAILED = 'medialibrary/GET_FOLDERS_FAILED';

export const ADD_FOLDER_START = 'medialibrary/ADD_FOLDER_START';
export const ADD_FOLDER_COMPLETED = 'medialibrary/ADD_FOLDER_COMPLETED';
export const ADD_FOLDER_FAILED = 'medialibrary/ADD_FOLDER_FAILED';

export const BULK_DELETE_START = 'medialibrary/BULK_DELETE_START';
export const BULK_DELETE_FAILED = 'medialibrary/BULK_DELETE_FAILED';
export const BULK_DELETE_COMPLETED = 'medialibrary/BULK_DELETE_COMPLETED';

export const DUPLICATE_FOLDER_START = 'medialibrary/DUPLICATE_FOLDER_START';
export const DUPLICATE_FOLDER_FAILED = 'medialibrary/DUPLICATE_FOLDER_FAILED';
export const DUPLICATE_FOLDER_COMPLETED = 'medialibrary/DUPLICATE_FOLDER_COMPLETED';

export const GET_FILTERED_FOLTER_COMPLETED = 'medialibrary/GET_FILTERED_FOLTER_COMPLETED';

export const GET_LABELS_START = 'medialibrary/GET_LABELS_START';
export const GET_LABELS_COMPLETED = 'medialibrary/GET_LABELS_COMPLETED';
export const GET_LABELS_FAILED = 'medialibrary/GET_LABELS_FAILED';

export const CREATE_LABEL_START = 'medialibrary/CREATE_LABEL_START';
export const CREATE_LABEL_COMPLETED = 'medialibrary/CREATE_LABEL_COMPLETED';
export const CREATE_LABEL_FAILED = 'medialibrary/CREATE_LABEL_FAILED';

export const UPDATE_FOLDER_NAME_START = 'medialibrary/UPDATE_FOLDER_NAME_START';
export const UPDATE_FOLDER_NAME_COMPLETED = 'medialibrary/UPDATE_FOLDER_NAME_COMPLETED';
export const UPDATE_FOLDER_NAME_FAILED = 'medialibrary/UPDATE_FOLDER_NAME_FAILED';
