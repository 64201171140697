export const GET_COMMENTS_START = 'courseComments/GET_COMMENTS_START';
export const GET_COMMENTS_COMPLETED = 'courseComments/GET_COMMENTS_COMPLETED';
export const GET_COMMENTS_FAILED = 'courseComments/GET_COMMENTS_FAILED';

export const GET_NEW_COMMENTS_START = 'courseComments/GET_NEW_COMMENTS_START';
export const GET_NEW_COMMENTS_COMPLETED = 'courseComments/GET_NEW_COMMENTS_COMPLETED';
export const GET_NEW_COMMENTS_FAILED = 'courseComments/GET_NEW_COMMENTS_FAILED';

export const CREATE_COMMENT_START = 'courseComments/CREATE_COMMENT_START';
export const CREATE_COMMENT_COMPLETED = 'courseComments/CREATE_COMMENT_COMPLETED';
export const CREATE_COMMENT_FAILED = 'courseComments/CREATE_COMMENT_FAILED';

export const DELETE_COMMENT_START = 'courseComments/DELETE_COMMENT_START';
export const DELETE_COMMENT_COMPLETED = 'courseComments/DELETE_COMMENT_COMPLETED';
export const DELETE_COMMENT_FAILED = 'courseComments/DELETE_COMMENT_FAILED';

export const TOGGLE_LIKE_COMMENT_START = 'courseComments/TOGGLE_LIKE_COMMENT_START';
export const TOGGLE_LIKE_COMMENT_COMPLETED = 'courseComments/TOGGLE_LIKE_COMMENT_COMPLETED';
export const TOGGLE_LIKE_COMMENT_FAILED = 'courseComments/TOGGLE_LIKE_COMMENT_FAILED';

export const SEND_COMMENT = 'courseComments/SEND_COMMENT';
export const DELETE_COMMENT = 'courseComments/DELETE_COMMENT';

export const RESET_COMMENTS_NEW_FIELD = 'courseComments/RESET_COMMENTS_NEW_FIELD';
export const SET_DELETED_STATUS = 'courseComments/SET_DELETED_STATUS';
export const LIKE_COMMENT = 'courseComments/LIKE_COMMENT';
