import React from 'react';

export const GoldenIcon = () => {
   return (
      <svg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M0.583495 8.33366C0.583496 4.23755 3.90405 0.916993 8.00016 0.916993C12.0963 0.916994 15.4168 4.23755 15.4168 8.33366C15.4168 12.4298 12.0963 15.7503 8.00016 15.7503C3.90405 15.7503 0.583494 12.4298 0.583495 8.33366ZM2.26225 18.1995L2.7959 15.2644C4.24511 16.3544 6.04719 17.0003 8.00015 17.0003C9.95312 17.0003 11.7552 16.3544 13.2044 15.2644L13.7381 18.1995C13.7817 18.4397 13.7057 18.6862 13.5344 18.8601C13.363 19.034 13.1177 19.1136 12.8769 19.0735L12.0855 18.9416C10.4977 18.6769 9.75291 18.5537 9.00683 18.5043C8.33645 18.4599 7.66387 18.4599 6.99348 18.5043C6.24741 18.5537 5.50259 18.6769 3.91484 18.9416L3.12346 19.0735C2.88262 19.1136 2.63732 19.034 2.46594 18.8601C2.29457 18.6862 2.21858 18.4397 2.26225 18.1995Z' fill='url(#paint0_linear_1458_161100)' />
         <defs>
            <linearGradient id='paint0_linear_1458_161100' x1='-8.50049' y1='-10.5' x2='30.9995' y2='33.5' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#BCA687' />
               <stop offset='0.066468' stopColor='#B8A284' />
               <stop offset='0.306859' stopColor='#DAC5A5' />
               <stop offset='0.496763' stopColor='#AB8F76' />
               <stop offset='0.659198' stopColor='#735B42' />
               <stop offset='0.743629' stopColor='#A68B6E' />
               <stop offset='0.912967' stopColor='#B79A7A' />
               <stop offset='1' stopColor='#8F7E61' />
            </linearGradient>
         </defs>
      </svg>
   );
};

export const SilverIcon = () => {
   return (
      <svg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M0.583495 8.33366C0.583496 4.23755 3.90405 0.916993 8.00016 0.916993C12.0963 0.916994 15.4168 4.23755 15.4168 8.33366C15.4168 12.4298 12.0963 15.7503 8.00016 15.7503C3.90405 15.7503 0.583494 12.4298 0.583495 8.33366ZM2.26225 18.1995L2.7959 15.2644C4.24511 16.3544 6.04719 17.0003 8.00015 17.0003C9.95312 17.0003 11.7552 16.3544 13.2044 15.2644L13.7381 18.1995C13.7817 18.4397 13.7057 18.6862 13.5344 18.8601C13.363 19.034 13.1177 19.1136 12.8769 19.0735L12.0855 18.9416C10.4977 18.6769 9.75291 18.5537 9.00683 18.5043C8.33645 18.4599 7.66387 18.4599 6.99348 18.5043C6.24741 18.5537 5.50259 18.6769 3.91484 18.9416L3.12346 19.0735C2.88262 19.1136 2.63732 19.034 2.46594 18.8601C2.29457 18.6862 2.21858 18.4397 2.26225 18.1995Z' fill='url(#paint0_linear_2486_8373)' />
         <defs>
            <linearGradient id='paint0_linear_2486_8373' x1='3.99951' y1='2' x2='27.9995' y2='40' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#B3ABA5' />
               <stop offset='0.066468' stopColor='#B0A9A3' />
               <stop offset='0.317815' stopColor='#A69E99' />
               <stop offset='0.343067' stopColor='#9E9690' />
               <stop offset='0.659198' stopColor='#7A756A' />
               <stop offset='0.743629' stopColor='#9A9187' />
               <stop offset='0.912967' stopColor='#A39B90' />
               <stop offset='1' stopColor='#756F67' />
            </linearGradient>
         </defs>
      </svg>
   );
};

export const BronzeIcon = () => {
   return (
      <svg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M0.583495 8.33366C0.583496 4.23755 3.90405 0.916993 8.00016 0.916993C12.0963 0.916994 15.4168 4.23755 15.4168 8.33366C15.4168 12.4298 12.0963 15.7503 8.00016 15.7503C3.90405 15.7503 0.583494 12.4298 0.583495 8.33366ZM2.26225 18.1995L2.7959 15.2644C4.24511 16.3544 6.04719 17.0003 8.00015 17.0003C9.95312 17.0003 11.7552 16.3544 13.2044 15.2644L13.7381 18.1995C13.7817 18.4397 13.7057 18.6862 13.5344 18.8601C13.363 19.034 13.1177 19.1136 12.8769 19.0735L12.0855 18.9416C10.4977 18.6769 9.75291 18.5537 9.00683 18.5043C8.33645 18.4599 7.66387 18.4599 6.99348 18.5043C6.24741 18.5537 5.50259 18.6769 3.91484 18.9416L3.12346 19.0735C2.88262 19.1136 2.63732 19.034 2.46594 18.8601C2.29457 18.6862 2.21858 18.4397 2.26225 18.1995Z' fill='url(#paint0_linear_2487_8382)' />
         <defs>
            <linearGradient id='paint0_linear_2487_8382' x1='1.49951' y1='1.5' x2='31.4995' y2='50.5' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#B5947D' />
               <stop offset='0.066468' stopColor='#B6937D' />
               <stop offset='0.343067' stopColor='#977061' />
               <stop offset='0.659198' stopColor='#7F5B4A' />
               <stop offset='0.743629' stopColor='#AC836D' />
               <stop offset='0.912967' stopColor='#AB836E' />
               <stop offset='1' stopColor='#8E6958' />
            </linearGradient>
         </defs>
      </svg>
   );
};


GoldenIcon.propTypes = {};
SilverIcon.propTypes = {};
BronzeIcon.propTypes = {};
