const prefix = 'designCourses/emailTemplates';

export const GET_EMAILTEMPLATE_START = `${ prefix }/GET_EMAILTEMPLATE_START`;
export const GET_EMAILTEMPLATE_COMPLETED = `${ prefix }/GET_EMAILTEMPLATE_COMPLETED`;
export const GET_EMAILTEMPLATE_FAILED = `${ prefix }/GET_EMAILTEMPLATE_FAILED`;

export const UPDATE_EMAILTEMPLATE_START = `${ prefix }/UPDATE_EMAILTEMPLATE_START`;
export const UPDATE_EMAILTEMPLATE_COMPLETED = `${ prefix }/UPDATE_EMAILTEMPLATE_COMPLETED`;
export const UPDATE_EMAILTEMPLATE_FAILED = `${ prefix }/UPDATE_EMAILTEMPLATE_FAILED`;

export const UPDATE_EMAILNOTIFICATIONS_START = `${ prefix }/UPDATE_EMAILNOTIFICATIONS_START`;
export const UPDATE_EMAILNOTIFICATIONS_COMPLETED = `${ prefix }/UPDATE_EMAILNOTIFICATIONS_COMPLETED`;
export const UPDATE_EMAILNOTIFICATIONS_FAILED = `${ prefix }/UPDATE_EMAILNOTIFICATIONS_FAILED`;

export const GET_EMAILTEMPLATES_START = `${ prefix }/GET_EMAILTEMPLATES_START`;
export const GET_EMAILTEMPLATES_COMPLETED = `${ prefix }/GET_EMAILTEMPLATES_COMPLETED`;
export const GET_EMAILTEMPLATES_FAILED = `${ prefix }/GET_EMAILTEMPLATES_FAILED`;

export const SET_INPUT = `${ prefix }/SET_INPUT`;

export const SET_CHECKBOX = `${ prefix }/SET_CHECKBOX`;

export const GET_AUTO_EMAILTEMPLATES_COMPLETED = `${ prefix }/GET_AUTO_EMAILTEMPLATES_COMPLETED`;
export const GET_AUTO_EMAILTEMPLATES_START = `${ prefix }/GET_AUTO_EMAILTEMPLATES_START`;
export const GET_AUTO_EMAILTEMPLATES_FAILED = `${ prefix }/GET_AUTO_EMAILTEMPLATES_FAILED`;

export const SET_AUTO_EMAIL_INPUT = `${ prefix }/SET_AUTO_EMAIL_INPUT`;

export const UPDATE_AUTO_EMAILTEMPLATES_COMPLETED = `${ prefix }/UPDATE_AUTO_EMAILTEMPLATES_COMPLETED`;
export const UPDATE_AUTO_EMAILTEMPLATES_FAILED = `${ prefix }/UPDATE_AUTO_EMAILTEMPLATES_FAILED`;
export const UPDATE_AUTO_EMAILTEMPLATES_START = `${ prefix }/UPDATE_AUTO_EMAILTEMPLATES_START`;

export const REVERT_AUTO_EMAILTEMPLATE_COMPLETED = `${ prefix }/REVERT_AUTO_EMAILTEMPLATE_COMPLETED`;
export const REVERT_AUTO_EMAILTEMPLATE_FAILED = `${ prefix }/REVERT_AUTO_EMAILTEMPLATE_FAILED`;
export const REVERT_AUTO_EMAILTEMPLATE_START = `${ prefix }/REVERT_AUTO_EMAILTEMPLATE_START`;
