export const GET_PLANS_START = 'plans/GET_PLANS_START';
export const GET_PLANS_COMPLETED = 'plans/GET_PLANS_COMPLETED';
export const GET_PLANS_FAILED = 'plans/GET_PLANS_FAILED';

export const FILTER_PLANS_START = 'plans/FILTER_PLANS_START';
export const FILTER_PLANS_COMPLETED = 'plans/FILTER_PLANS_COMPLETED';
export const FILTER_PLANS_FAILED = 'plans/FILTER_PLANS_FAILED';

export const DELETE_PLANS_COMPLETED = 'plans/DELETE_PLANS_COMPLETED';

export const PLAN_GET_START = 'plans/PLAN_GET_START';
export const PLAN_GET_FAILED = 'plans/PLAN_GET_FAILED';
export const PLAN_GET_COMPLETED = 'plans/PLAN_GET_COMPLETED';

export const PLAN_INPUT_CHANGE = 'plans/PLAN_INPUT_CHANGE';

export const PLAN_INTEGRATION_START = 'plans/PLAN_INTEGRATION_START';
export const PLAN_INTEGRATION_FAILED = 'plans/PLAN_INTEGRATION_FAILED';
export const PLAN_INTEGRATION_COMPLETED = 'plans/PLAN_INTEGRATION_COMPLETED';

export const PLAN_SAVE_START = 'plans/PLAN_SAVE_START';
export const PLAN_SAVE_FAILED = 'plans/PLAN_SAVE_FAILED';
export const PLAN_SAVE_COMPLETED = 'plans/PLAN_SAVE_COMPLETED';

export const PLAN_ADD_TAG_COMPLETED = 'plans/PLAN_ADD_TAG_COMPLETED';

export const PLAN_ORDER_BUMP_DELETE_COMPLETED = 'plans/PLAN_ORDER_BUMP_DELETE_COMPLETED';

export const PLAN_CUSTOM_FIELDS_ADD_COMPLETED = 'plans/PLAN_CUSTOM_FIELDS_ADD_COMPLETED';

export const PLAN_CUSTOM_FIELD_DELETE_COMPLETED = 'plans/PLAN_CUSTOM_FIELD_DELETE_COMPLETED';

export const AUTHORESPONDER_LIST_COMPLETED = 'plans/AUTHORESPONDER_LIST_COMPLETED';
export const AUTHORESPONDER_OPTIONS_LIST_COMPLETED = 'plans/AUTHORESPONDER_OPTIONS_LIST_COMPLETED';
export const AUTHORESPONDER_OPTIONS_LIST_FAILED = 'plans/AUTHORESPONDER_OPTIONS_LIST_FAILED';
export const AUTHORESPONDER_OPTIONS_LIST_START = 'plans/AUTHORESPONDER_OPTIONS_LIST_START';

export const DELETE_UPSELL_COMPLETED = 'plans/DELETE_UPSELL_COMPLETED';

export const MAKE_ACTIVE_LANDING_COMPLETED = 'plans/MAKE_ACTIVE_LANDING_COMPLETED';

export const DELETE_DOWNSELL_COMPLETED = 'plans/DELETE_DOWNSELL_COMPLETED';

export const DOWNSEL_STATUS_CHANGE = 'plans/DOWNSEL_STATUS_CHANGE';

export const GET_PLANS_NEXT_PAGE_START = 'plans/GET_PLANS_NEXT_PAGE_START';
export const GET_PLANS_NEXT_PAGE_COMPLETED = 'plans/GET_PLANS_NEXT_PAGE_COMPLETED';
export const GET_PLANS_NEXT_PAGE_FAILED = 'plans/GET_PLANS_NEXT_PAGE_FAILED';

export const RESET_STATE_TO_INITIAL = 'plans/RESET_STATE_TO_INITIAL';