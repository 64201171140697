export default {
   course: {},
   lessons: [],
   sections: [],
   questions: [],
   author: {},
   lesson: {},
   defaultLessonId: 1,
   defaultQuestionId: 0,
   getCourseInProgress: true,
   getLessonInProgress: false,
   courseComplatePercent: 0,
   isComplate: false,
   hasCertificateSelector: false,
};
