export const SET_INPUT = 'courses/create/SET_INPUT';

export const CREATE_SECTION_START = 'courses/create/CREATE_SECTION_START';
export const CREATE_SECTION_FAILED = 'courses/create/CREATE_SECTION_FAILED';

export const CREATE_COURSE_START = 'courses/create/CREATE_COURSE_START';
export const CREATE_COURSE_COMPLETED = 'courses/create/CREATE_COURSE_COMPLETED';
export const CREATE_COURSE_FAILED = 'courses/create/CREATE_COURSE_FAILED';

export const CREATE_PLAN_START = 'courses/create/CREATE_PLAN_START';
export const CREATE_PLAN_FAILED = 'courses/create/CREATE_PLAN_FAILED';
export const ADD_PLAN = 'courses/edit/ADD_PLAN';

export const CREATE_SIGN_UP_START = 'courses/create/CREATE_SIGN_UP_START';
export const CREATE_SIGN_UP_COMPLETED = 'courses/create/CREATE_SIGN_UP_COMPLETED';
export const CREATE_SIGN_UP_FAILED = 'courses/create/CREATE_SIGN_UP_FAILED';
export const SET_SIGN_UP_INPUT = 'course/create/SET_SIGN_UP_INPUT';
export const GET_AUTORESPONDERS_LISTS_COMPLETED = 'courses/create/GET_AUTORESPONDERS_LISTS_COMPLETED';
export const UPDATE_SETTINGS_COMPLETED = 'courses/create/UPDATE_SETTINGS_COMPLETED';

export const CREATE_PROGRAM_START = 'course/create/CREATE_PROGRAM_START';
export const CREATE_PROGRAM_END = 'course/create/CREATE_PROGRAM_END';

export const GENERATE_START = 'courses/create/GENERATE_START';
export const GENERATE_FAILED = 'courses/create/GENERATE_FAILED';
export const GENERATE_COMPLETED = 'courses/edit/GENERATE_COMPLETED';

export const EMPTY_GENERATED_ARRAY = 'courses/edit/EMPTY_GENERATED_ARRAY';
