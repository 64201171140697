export const SET_INPUT = 'login/SET_INPUT';
export const SET_REGISTER_INPUT = 'login/SET_REGISTER_INPUT';
export const LOGIN_START = 'login/LOGIN_START';
export const LOGIN_COMPLETED = 'login/LOGIN_COMPLETED';
export const LOGIN_FAILED = 'login/LOGIN_FAILED';

export const FORGOT_PASSWORD_START = 'login/FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_COMPLETED = 'login/FORGOT_PASSWORD_COMPLETED';
export const FORGOT_PASSWORD_FAILED = 'login/FORGOT_PASSWORD_FAILED ';

export const RESET_PASSWORD_START = 'login/RESET_PASSWORD_START';
export const RESET_PASSWORD_COMPLETED = 'login/RESET_PASSWORD_COMPLETED';
export const RESET_PASSWORD_FAILED = 'login/RESET_PASSWORD_FAILED ';

export const REGISTER_STUDENT_START = 'login/REGISTER_STUDENT_START';
export const REGISTER_STUDENT_COMPLETED = 'login/REGISTER_STUDENT_COMPLETED';
export const REGISTER_STUDENT_FAILED = 'login/REGISTER_STUDENT_FAILED ';
