export default {
   formData: { role: 5, css_attributes: {} },
   filterOptions: {},
   emails: [],
   filterEmails: [],
   selectedFilters: [],
   allUsers: [],
   Admins: [],
   isEmailSent: false,
   sendEmailInProgress: true,
   getEmailInProgress: true,
   email: '',
   emailStatuses: [],
   emailStatusesTotal: 0,
   getEmailStatusesInProgress: true,
   getFilterOptionsInProgress: true,
   getUsersEmailsInProgress: true,
   getAdminEmailsInProgress: true,
   initalEmailsLength: 0,
   isEmptyByFilter: false,
   isLoadingAction: false,
};
