export default {
   errors: {},
   initDataInProgress: true,
   instructorDataInProgress: true,
   lessonActionInProgress: true,
   lessonResourceActionInProgress: false,
   planActionInProgress: false,
   planCouponActionInProgress: false,
   isPorgressProductSettings: false,
   updateSignUpInProgress: false,
   courseMaterial: {
      currentLesson: {},
      lessonContent: null,
      lessonResources: null,
      currentSection: {},
      sections: [],
      isQuestionSaved: false,
      quizTemplatesAsc: [],
      quizTemplatesDesc: [],
   },
   settings: {
   },
   plan: {
      pricings: [],
      selectedPricing: {},
      addingFirstPlan: false,
      createFirstPlanAdding: false,
      currentPricingType: null,
      pricingTypeInCreate: null,
      currentPricingUnsaved: false,
      allPlanTypes: [
         { name: 'Free Plan', icon: 'Dollar', type: 0 },
         { name: 'One Time Plan', icon: 'Money', type: 1 },
         { name: 'Subscription Plan', icon: 'Time', type: 2 },
      ],
      planTypes: [
         { name: 'Free Plan', icon: 'Dollar', type: 0 },
         { name: 'One Time Plan', icon: 'Money', type: 1 },
         { name: 'Subscription Plan', icon: 'Time', type: 2 },
      ],
      couponInputs: {},
      payment_methods: {},
      paypal_connection: false,
      braintree_connection: false,
      stripe_connection: false,
      showCouponPopup: false,
      is_published: 0,
   },
   signUp: {
      'order-summary': {},
      'testimonials': [],
      'bullet-points': [],
      'buy-bottom': {},
      'newTestimonialInput': { },
      'newBulletInput': {},
      isTestimonalSaved: false,
      isBulletSaved: false,
   },
   currentResource: {},
   integrationError: false,
   settingsActionInProgress: false,
   planDrawerState: false,
   chooseCourses: [],
   zoomLoader: false,
   isLoadingGeneral: false,
   allCourses: [],
   initialCountOfSections: 0,
   isFiltering: false,
};
