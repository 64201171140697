import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const LeftBar = ({ children }) => {
   return (
      <div className='leftBar'>
         {children}
      </div>
   );
};

export default LeftBar;

LeftBar.propTypes = {
   children: PropTypes.any,
};
