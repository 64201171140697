export default {
   courses: [],
   getCoursesInProgress: true,
   filteredCoursesInProgress: true,
   getCoursesBySearchInProgress: false,
   totalCourses: 0,
   user: {},
   paginationData: { currentPage: 1 },
   categoryPagination: { currentPage: 1 },
   coursesBycategories: { data: [] },
   unCategorizedCourses: { data: [], loading: true },
   loadingSliderCategories: [],
   search: '',
   serachData: [],
   searchLoadingMore: false,
   searchInProgress: false,
   searchCurrentPage: 1,
   searchLastPage: 1,
   frontScripts: [],
   offers: [],
   offersProgress: true,
   categories: [],
};
