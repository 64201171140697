export const GET_VIDEOS_START = 'videolibrary/GET_VIDEOS_START';
export const GET_VIDEOS_COMPLETED = 'videolibrary/GET_VIDEOS_COMPLETED';
export const GET_VIDEOS_FAILED = 'videolibrary/GET_VIDEOS_FAILED';

export const DELETE_VIDEOS_START = 'videolibrary/DELETE_VIDEOS_START';
export const DELETE_VIDEOS_COMPLETED = 'videolibrary/DELETE_VIDEOS_COMPLETED';
export const DELETE_VIDEOS_FAILED = 'videolibrary/DELETE_VIDEOS_FAILED';

export const FETCH_BY_FILTER_START = 'videolibrary/FETCH_BY_FILTER_START';
export const FETCH_BY_FILTER_COMPLETED = 'videolibrary/FETCH_BY_FILTER_COMPLETED';
export const FETCH_BY_FILTER_FAILED = 'videolibrary/FETCH_BY_FILTER_FAILED';
