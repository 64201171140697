export default {
   courses: [],
   errors: {},
   course: {},
   getCoursesInProgress: true,
   duplicateCourseInProgress: true,
   fetchDefaultNames: false,
   defaultNames: [],
   isEmptyByFilter: false,
};
