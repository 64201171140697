const initialState = {
   medias: [],
   mediaTypes: [],
   errors: {},
   mode: {},
   activeTab: 'video',
   pagination: {},
   isFetchingData: true,
   tags: [],
   folders: [],
   changePageInProgress: false,
   folderCount: 0,
   labels: [],
   folderWithTypes: {},
   getProgress: false,
   isFetchingUpdateFolderName: false,
};

export default initialState;
