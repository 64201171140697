export const AFFILIATE_INIT_START = 'affiliate/AFFILATE_INIT_START';
export const AFFILIATE_INIT_END = 'affiliate/AFFILIATE_INIT_END';
// CREATE PAGE
export const AFFILIATE_OFFERS_START = 'affiliate/AFFILIATE_OFFERS_START';
export const AFFILIATE_OFFERS_FINISHED = 'affiliate/AFFILIATE_OFFERS_FINISHED';
export const AFFILIATE_CREATE_START = 'affiliate/AFFILIATE_CREATE_START';
export const AFFILIATE_CREATE_COMPLETED = 'affiliate/AFFILIATE_CREATE_COMPLETED';
export const AFFILIATE_CREATE_FAILED = 'affiliate/AFFILIATE_CREATE_FAILED';
//  END CREATE PAGE

export const AFFILIATE_MAIN_INIT_START = 'affiliate/AFFILIATE_MAIN_INIT_START';
export const AFFILIATE_MAIN_INIT_END = 'affiliate/AFFILIATE_MAIN_INIT_END';

export const AFFILIATE_FILTER_OVERVIEW = 'affiliate/AFFILIATE_FILTER_OVERVIEW';

export const AFFILIATE_DELETE_COMPLETED = 'affiliate/AFFILIATE_DELETE_COMPLETED';

export const AFFILIATE_MARK_AS_PAID = 'affiliate/AFFILIATE_MARK_AS_PAID';

export const AFFILIATE_USER_INFO_START = 'affiliate/AFFILIATE_USER_INFO_START';
export const AFFILIATE_USER_INFO_END = 'affiliate/AFFILIATE_USER_INFO_END';

export const AFFILIATE_GET_SETTINGS_START = 'affiliate/AFFILIATE_GET_SETTINGS_START';
export const AFFILIATE_GET_SETTINGS_END = 'affiliate/AFFILIATE_GET_SETTINGS_END';

export const AFFILIATE_INPUTS_START = 'affiliate/AFFILIATE_INPUTS_START';
export const AFFILIATE_INPUTS_END = 'affiliate/AFFILIATE_INPUTS_END';

export const AFFILIATE_UPDATE_START = 'affiliate/AFFILIATE_UPDATE_START';
export const AFFILIATE_UPDATE_FAILED = 'affiliate/AFFILIATE_UPDATE_FAILED';
export const AFFILIATE_UPDATE_COMPLETED = 'affiliate/AFFILIATE_UPDATE_COMPLETED';
