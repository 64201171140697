const initialState = {
   getBlogInProgress: false,
   getBlogPostInProgress: false,
   getBlogCategoriesInProgress: false,
   getBlogSettingsInProgress: false,
   categories: [],
   blog: [],
   post: {},
   updatedPost: {},
};

export default initialState;
