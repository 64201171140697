const initialState = {
   isFetchingData: false,
   certificates: [],
   certificate: {},
   courses: null,
   certifacteTemp: [{
      certificateName: '',
      assignTo: '',
   }],
   count: 0,
   coursesForCertificate: [],
};

export default initialState;
