export const SET_FILTER_INPUT = 'reports/SET_FILTER_INPUT';
export const GET_COURSES_STATISTICS_START = 'reports/GET_COURSES_STATISTICS_START';
export const GET_COURSES_STATISTICS_COMPLETED = 'reports/GET_COURSES_STATISTICS_COMPLETED';
export const GET_COURSES_STATISTICS_FAILED = 'reports/GET_COURSES_STATISTICS_FAILED';

export const GET_ALL_TIME_REVENUE_BY_COURSE_START = 'reports/GET_ALL_TIME_REVENUE_BY_COURSE_START';
export const GET_ALL_TIME_REVENUE_BY_COURSE_COMPLETED = 'reports/GET_ALL_TIME_REVENUE_BY_COURSE_COMPLETED';
export const GET_ALL_TIME_REVENUE_BY_COURSE_FAILED = 'reports/GET_COURSES_STATISTICS_FAILED';

export const GET_REVENUE_BY_COURSE_START = 'reports/GET_REVENUE_BY_COURSE_START';
export const GET_REVENUE_BY_COURSE_COMPLETED = 'reports/GET_REVENUE_BY_COURSE_COMPLETED';
export const GET_REVENUE_BY_COURSE_FAILED = 'reports/GET_REVENUE_BY_COURSE_FAILED';
