// export default function () {
//    try {
//       const ap3ScriptDoc = document.getElementById('ap3_script');
//       if (window.location.href.includes('/admin')) {
//          if (ap3ScriptDoc) return;

//          const ap3cScript = document.createElement('script');
//          ap3cScript.setAttribute('id', 'ap3_script');

//          ap3cScript.innerHTML = `
//             window.ap3c = window.ap3c || {};
//             const ap3c = window.ap3c;
//             ap3c.cmd = ap3c.cmd || [];
//             ap3c.cmd.push(() => {
//                ap3c.init('ZKN_fNHdFy71Doh3bWllc3Ryb2luYw', 'https://capture-api.autopilotapp.com/');
//                ap3c.track({ v: 0 });
//             });
//             ap3c.activity = function (act) {
//                ap3c.act = (ap3c.act || []);
//                ap3c.act.push(act);
//             };
//             let s; let
//                t;
//             s = document.createElement('script');
//             s.type = 'text/javascript';
//             s.src = 'https://cdn2l.ink/app.js';
//             t = document.getElementsByTagName('script')[0];
//             t.parentNode.insertBefore(s, t);`;

//          document.body.append(ap3cScript);
//          if (document.getElementById('ap3-talk-widget-ui')) {
//             document.getElementById('ap3-talk-widget-ui').style.display = 'inline-flex';
//          }
//       } else if (ap3ScriptDoc) {
//          ap3ScriptDoc.remove();
//          window.ap3c = undefined;
//          if (document.getElementById('ap3-talk-widget-ui')) {
//             document.getElementById('ap3-talk-widget-ui').style.display = 'none';
//          }
//       }
//    } catch (error) {
//       console.log(error);
//    }
// }


export default function () {
   try {
      const ap3ScriptDoc = document.getElementById('liveagent_script');

      if (
         window.location.href === `${ window.location.origin }/admin`
           || window.location.href === `${ window.location.origin }/admin/account#billing`
           || window.location.href === `${ window.location.origin }/admin/app-builder`
      ) {
         if (ap3ScriptDoc) return;

         const ap3cScript = document.createElement('script');
         ap3cScript.setAttribute('id', 'liveagent_script');

         ap3cScript.innerHTML = `
            window.__ow = window.__ow || {};
            window.__ow.organizationId = "5e2f5682-1e6f-4b44-9b4b-2100e60bc0ec";
            window.__ow.integration_name = "manual_settings";
            window.__ow.product_name = "openwidget";
            ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[OpenWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.openwidget.com/openwidget.js",t.head.appendChild(n)}};!n.__ow.asyncInit&&e.init(),n.OpenWidget=n.OpenWidget||e}(window,document,[].slice))
           `;

         document.body.append(ap3cScript);
         window.OpenWidget.call('hide');
      } else if (ap3ScriptDoc) {
         ap3ScriptDoc.remove();
         window.LiveAgent = undefined;
      }
   } catch (error) {
      console.log(error);
   }
}
