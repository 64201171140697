export const SITE_DETAILS_INIT_START = 'common/SITE_DETAILS_INIT_START';
export const SITE_DETAILS_INIT_COMPLETED = 'common/SITE_DETAILS_INIT_COMPLETED';
export const SITE_DETAILS_INIT_FAILED = 'common/SITE_DETAILS_INIT_FAILED';

export const RESET_COMMON_DETAILS = 'common/RESET_COMMON_DETAILS';

export const CHANGE_AUTH_INFORMATION = 'common/CHANGE_AUTH_INFORMATION';

export const GOOGLE_ID_UPDATE_COMPLETED = 'common/GOOGLE_ID_UPDATE_COMPLETED';

export const UPDATE_USER_SOCKET = 'common/UPDATE_USER_SOCKET';

export const UPDATE_ONLINE_LIST = 'common/UPDATE_ONLINE_LIST';

export const ANSWER_ONBOARDING_QUESTION_START = 'common/ANSWER_ONBOARDING_QUESTION_START';

export const ANSWER_ONBOARDING_QUESTION_COMPLETED = 'common/ANSWER_ONBOARDING_QUESTION_COMPLETED';

export const ANSWER_ONBOARDING_QUESTION_FAILED = 'common/ANSWER_ONBOARDING_QUESTION_FAILED';

export const ALL_QUESTIONS_ANSWERED = 'common/ALL_QUESTIONS_ANSWERED';

export const SCREEN_RESIZE = 'common/SCREEN_RESIZE';

export const MAKE_SITE_NOT_INITED = 'common/MAKE_SITE_NOT_INITED';

export const UPDATE_SITE_INFO_ACTIVE_SCHOOL_ROOM = 'common/UPDATE_SITE_INFO_ACTIVE_SCHOOL_ROOM';
export const UPDATE_PORTAL_SECTIONS = 'schoolRoom/UPDATE_PORTAL_SECTIONS';
export const UPDATE_PORTAL_SETTINGS_SECTIONS = 'schoolRoom/UPDATE_PORTAL_SETTINGS_SECTIONS';