export const REORDER_CATEGORIES_COMLETED = 'videoCategories/REORDER_CATEGORIES_COMLETED';

export const GET_CATEGORIES_START = 'videoCategories/GET_CATEGORIES_START';
export const GET_CATEGORIES_END = 'videoCategories/GET_CATEGORIES_END';
export const GET_CATEGORIES_COMPLETED = 'videoCategories/GET_CATEGORIES_COMPLETED';

export const CREATE_CATEGORY_START = 'videoCategories/CREATE_CATEGORY_START';
export const CREATE_CATEGORY_END = 'videoCategories/CREATE_CATEGORY_END';
export const CREATE_CATEGORY_COMPLETED = 'videoCategories/CREATE_CATEGORY_COMPLETED';

export const CATEGORIES_FILTER_START = 'videoCategories/CATEGORIES_FILTER_START';
export const CATEGORIES_FILTER_END = 'videoCategories/CATEGORIES_FILTER_END';

export const UPDATE_CATEGORY_START = 'videoCategories/UPDATE_CATEGORY_START';
export const UPDATE_CATEGORY_COMPLETED = 'videoCategories/UPDATE_CATEGORY_COMPLETED';
export const UPDATE_CATEGORY_FAILED = 'videoCategories/UPDATE_CATEGORY_FAILED';

export const REMOVE_CATEGORY_COMPLETED = 'videoCategories/REMOVE_CATEGORY_COMPLETED';
export const REMOVE_CATEGORY_FAILED = 'videoCategories/REMOVE_CATEGORY_FAILED';
export const REMOVE_CATEGORY_START = 'videoCategories/REMOVE_CATEGORY_START';

export const GET_DETACHED_COURSES_START = 'videoCategories/GET_DETACHED_COURSES_START';
export const GET_DETACHED_COURSES_END = 'videoCategories/GET_DETACHED_COURSES_END';

export const ATTACH_DETACH_COURSE_START = 'videoCategories/ATTACH_DETACH_COURSE_START';
export const ATTACH_DETACH_COURSE_FAILED = 'videoCategories/ATTACH_DETACH_COURSE_FAILED';
export const ATTACH_DETACH_COURSE_COMPLETED = 'videoCategories/ATTACH_DETACH_COURSE_COMPLETED';
