export const SET_INPUT = 'courses/SET_INPUT';

export const CHOOSE_COURSELINK_START = 'courses/CHOOSE_COURSELINK_START';
export const CHOOSE_COURSELINK_COMPLETED = 'courses/CHOOSE_COURSELINK_COMPLETED';

export const GET_COURSES_START = 'courses/GET_COURSES_START';
export const GET_COURSES_COMPLETED = 'courses/GET_COURSES_COMPLETED';
export const GET_COURSES_FAILED = 'courses/GET_COURSES_FAILED';

export const HIDE_COURSE_START = 'courses/HIDE_COURSE_START';
export const HIDE_COURSE_COMPLETED = 'courses/HIDE_COURSE_COMPLETED';
export const HIDE_COURSE_FAILED = 'courses/HIDE_COURSE_FAILED';

export const DUPLICATE_COURSE_START = 'courses/DUPLICATE_COURSE_START';
export const DUPLICATE_COURSE_COMPLETED = 'courses/DUPLICATE_COURSE_COMPLETED';
export const DUPLICATE_COURSE_FAILED = 'courses/DUPLICATE_COURSE_FAILED';
export const DUPLICATE_COURSE = 'courses/DUPLICATE_COURSE';

export const DELETE_COURSE_START = 'courses/DELETE_COURSE_START';
export const DELETE_COURSE_COMPLETED = 'courses/DELETE_COURSE_COMPLETED';
export const DELETE_COURSE_FAILED = 'courses/DELETE_COURSE_FAILED';


export const UPDATE_COURSELINK_START = 'courses/UPDATE_COURSELINK_START';
export const UPDATE_COURSELINK_COMPLETED = 'courses/UPDATE_COURSELINK_COMPLETED';
export const UPDATE_COURSELINK_FAILED = 'courses/UPDATE_COURSELINK_FAILED';

export const FILTERED_COURSES_START = 'courses/FILTERED_COURSES_START';
export const FILTERED_COURSES_COMPLETED = 'courses/FILTERED_COURSES_COMPLETED';
export const FILTERED_COURSES_FAILED = 'courses/FILTERED_COURSES_FAILED';

export const GET_DEFAULT_NAMES_START = 'courses/GET_DEFAULT_NAMES_START';
export const GET_DEFAULT_NAMES_COMPLETED = 'courses/GET_DEFAULT_NAMES_COMPLETED';
export const GET_DEFAULT_NAMES_FAILED = 'courses/GET_DEFAULT_NAMES_FAILED';

export const SHOW_COURSE_START = 'courses/SHOW_COURSE_START';
export const SHOW_COURSE_COMPLETED = 'courses/SHOW_COURSE_COMPLETED';
export const SHOW_COURSE_FAILED = 'courses/SHOW_COURSE_FAILED';

export const DELETE_COURSESMULTIPLE_START = 'courses/DELETE_COURSESMULTIPLE_START';
export const DELETE_COURSESMULTIPLE_FAILED = 'courses/DELETE_COURSESMULTIPLE_FAILED';
export const DELETE_COURSESMULTIPLE_COMPLETED = 'courses/DELETE_COURSESMULTIPLE_COMPLETED';
