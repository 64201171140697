export const GET_AUTHORS_START = 'author/GET_AUTHORS_START';
export const GET_AUTHORS_COMPLETE = 'author/GET_AUTHORS_COMPLETE';
export const GET_AUTHORS_FAILED = 'author/GET_AUTHORS_FAILED';

export const CREATE_AUTHOR_START = 'author/CREATE_AUTHOR_START';
export const CREATE_AUTHOR_COMPLETE = 'author/CREATE_AUTHOR_COMPLETE';
export const CREATE_AUTHOR_FAILED = 'author/CREATE_AUTHOR_FAILED';

export const SAVE_AUTHOR_START = 'author/SAVE_AUTHOR_START';
export const SAVE_AUTHOR_COMPLETE = 'author/SAVE_AUTHOR_COMPLETE';
export const SAVE_AUTHOR_FAILED = 'author/SAVE_AUTHOR_FAILED';

export const DELETE_AUTHOR_START = 'author/DELETE_AUTHOR_START';
export const DELETE_AUTHOR_COMPLETE = 'author/DELETE_AUTHOR_COMPLETE';
export const DELETE_AUTHOR_FAILED = 'author/DELETE_AUTHOR_FAILED';

export const OPEN_AUTHOR_POPUP = 'author/OPEN_AUTHOR_POPUP';
export const OPEN_DELETE_AUTHOR_POPUP = 'author/OPEN_DELETE_AUTHOR_POPUP';
