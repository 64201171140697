import React from 'react';

export const TemplateDefaultLogoIcon = () => {
   return (
      <svg width='479' height='40' viewBox='0 0 479 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path d='M207.414 19.3038V9.92076L207.602 11.2381L200 0.48394H205.963L211.765 8.81845L208.622 8.7378L213.86 0.48394H219.662L212.383 11.5339L212.786 9.70567V19.3038H207.414Z' fill='#131F1E' />
         <path d='M229.144 19.5727C227.694 19.5727 226.36 19.3307 225.142 18.8468C223.924 18.3628 222.868 17.6817 221.972 16.8035C221.077 15.9252 220.379 14.8946 219.877 13.7116C219.394 12.5107 219.152 11.2023 219.152 9.78633C219.152 8.35244 219.394 7.04401 219.877 5.86105C220.379 4.67808 221.077 3.64747 221.972 2.76921C222.868 1.89095 223.924 1.20985 225.142 0.725909C226.36 0.24197 227.694 0 229.144 0C230.595 0 231.92 0.24197 233.12 0.725909C234.338 1.20985 235.394 1.89095 236.289 2.76921C237.203 3.64747 237.901 4.67808 238.385 5.86105C238.868 7.04401 239.11 8.34348 239.11 9.75945C239.11 11.1933 238.868 12.5107 238.385 13.7116C237.901 14.8946 237.203 15.9252 236.289 16.8035C235.394 17.6817 234.338 18.3628 233.12 18.8468C231.92 19.3307 230.595 19.5727 229.144 19.5727ZM229.144 14.6257C229.789 14.6257 230.389 14.5092 230.944 14.2762C231.499 14.0432 231.983 13.7116 232.395 13.2814C232.806 12.8334 233.12 12.3136 233.335 11.7221C233.567 11.1306 233.684 10.4854 233.684 9.78633C233.684 9.08731 233.567 8.44206 233.335 7.85057C233.12 7.25909 232.806 6.74827 232.395 6.3181C231.983 5.87001 231.499 5.52946 230.944 5.29645C230.389 5.06344 229.789 4.94694 229.144 4.94694C228.482 4.94694 227.873 5.06344 227.318 5.29645C226.763 5.52946 226.279 5.87001 225.867 6.3181C225.455 6.74827 225.133 7.25909 224.9 7.85057C224.685 8.42413 224.578 9.06938 224.578 9.78633C224.578 10.4854 224.685 11.1306 224.9 11.7221C225.133 12.3136 225.455 12.8334 225.867 13.2814C226.279 13.7116 226.763 14.0432 227.318 14.2762C227.873 14.5092 228.482 14.6257 229.144 14.6257Z' fill='#131F1E' />
         <path d='M250.37 19.4651C248.776 19.4651 247.353 19.1515 246.099 18.5241C244.863 17.8789 243.887 17.0006 243.171 15.8893C242.473 14.7781 242.124 13.5055 242.124 12.0716V0.48394H247.496V11.8296C247.496 12.4211 247.621 12.9499 247.872 13.4159C248.123 13.864 248.463 14.2224 248.893 14.4913C249.34 14.7422 249.833 14.8677 250.37 14.8677C250.961 14.8677 251.48 14.7422 251.928 14.4913C252.376 14.2224 252.734 13.864 253.002 13.4159C253.271 12.9499 253.405 12.4211 253.405 11.8296V0.48394H258.616V12.0716C258.616 13.5055 258.258 14.7781 257.542 15.8893C256.844 17.0006 255.877 17.8789 254.641 18.5241C253.405 19.1515 251.982 19.4651 250.37 19.4651Z' fill='#131F1E' />
         <path d='M262.789 19.3038V0.48394H271.465C272.737 0.48394 273.874 0.743833 274.877 1.26362C275.88 1.78341 276.659 2.49139 277.214 3.38758C277.787 4.28376 278.073 5.31437 278.073 6.47941C278.073 7.73407 277.787 8.84534 277.214 9.81322C276.659 10.7811 275.88 11.5429 274.877 12.0985C273.874 12.6362 272.737 12.9051 271.465 12.9051H267.785V19.3038H262.789ZM272.862 19.3038L268.081 10.808L273.534 10.1358L278.852 19.3038H272.862ZM267.785 9.08731H270.74C271.206 9.08731 271.591 8.99769 271.895 8.81845C272.218 8.62129 272.459 8.3614 272.62 8.03877C272.8 7.69822 272.889 7.3039 272.889 6.85581C272.889 6.42564 272.8 6.05821 272.62 5.7535C272.441 5.43088 272.173 5.18891 271.815 5.02759C271.474 4.84836 271.045 4.75874 270.525 4.75874H267.785V9.08731Z' fill='#131F1E' />
         <path d='M203.707 39.7311V20.9113H209.025V35.1337H217.218V39.7311H203.707Z' fill='#131F1E' />
         <path d='M227.58 40C226.129 40 224.795 39.758 223.578 39.2741C222.36 38.7902 221.303 38.109 220.408 37.2308C219.513 36.3525 218.814 35.3219 218.313 34.139C217.829 32.9381 217.587 31.6296 217.587 30.2137C217.587 28.7798 217.829 27.4713 218.313 26.2884C218.814 25.1054 219.513 24.0748 220.408 23.1965C221.303 22.3183 222.36 21.6372 223.578 21.1532C224.795 20.6693 226.129 20.4273 227.58 20.4273C229.03 20.4273 230.356 20.6693 231.555 21.1532C232.773 21.6372 233.83 22.3183 234.725 23.1965C235.638 24.0748 236.337 25.1054 236.82 26.2884C237.304 27.4713 237.545 28.7708 237.545 30.1868C237.545 31.6207 237.304 32.9381 236.82 34.139C236.337 35.3219 235.638 36.3525 234.725 37.2308C233.83 38.109 232.773 38.7902 231.555 39.2741C230.356 39.758 229.03 40 227.58 40ZM227.58 35.0531C228.225 35.0531 228.824 34.9366 229.38 34.7036C229.935 34.4705 230.418 34.139 230.83 33.7088C231.242 33.2607 231.555 32.7409 231.77 32.1494C232.003 31.5579 232.119 30.9127 232.119 30.2137C232.119 29.5146 232.003 28.8694 231.77 28.2779C231.555 27.6864 231.242 27.1756 230.83 26.7454C230.418 26.2973 229.935 25.9568 229.38 25.7238C228.824 25.4908 228.225 25.3743 227.58 25.3743C226.917 25.3743 226.308 25.4908 225.753 25.7238C225.198 25.9568 224.715 26.2973 224.303 26.7454C223.891 27.1756 223.569 27.6864 223.336 28.2779C223.121 28.8515 223.013 29.4967 223.013 30.2137C223.013 30.9127 223.121 31.5579 223.336 32.1494C223.569 32.7409 223.891 33.2607 224.303 33.7088C224.715 34.139 225.198 34.4705 225.753 34.7036C226.308 34.9366 226.917 35.0531 227.58 35.0531Z' fill='#131F1E' />
         <path d='M248.806 40C247.248 40 245.851 39.758 244.615 39.2741C243.38 38.7902 242.323 38.109 241.446 37.2308C240.586 36.3525 239.932 35.313 239.485 34.1121C239.037 32.9112 238.813 31.6117 238.813 30.2137C238.813 28.7977 239.055 27.4982 239.538 26.3153C240.04 25.1144 240.756 24.0748 241.687 23.1965C242.619 22.3183 243.711 21.6372 244.964 21.1532C246.236 20.6693 247.642 20.4273 249.182 20.4273C250.238 20.4273 251.232 20.5618 252.163 20.8306C253.112 21.0995 253.963 21.4669 254.715 21.9329C255.467 22.3989 256.112 22.9187 256.649 23.4923L253.479 26.8799C253.085 26.5035 252.665 26.1808 252.217 25.912C251.787 25.6431 251.313 25.428 250.793 25.2667C250.292 25.1054 249.728 25.0248 249.101 25.0248C248.403 25.0248 247.758 25.1592 247.167 25.428C246.594 25.679 246.093 26.0375 245.663 26.5035C245.233 26.9516 244.893 27.4982 244.642 28.1435C244.409 28.7708 244.293 29.4609 244.293 30.2137C244.293 30.9485 244.418 31.6386 244.669 32.2839C244.92 32.9112 245.269 33.4579 245.717 33.9239C246.164 34.3899 246.692 34.7573 247.301 35.0262C247.928 35.2771 248.6 35.4026 249.316 35.4026C249.817 35.4026 250.283 35.3219 250.713 35.1606C251.142 34.9993 251.51 34.7842 251.814 34.5154C252.136 34.2286 252.387 33.897 252.566 33.5206C252.745 33.1263 252.835 32.7051 252.835 32.257V31.5042L253.533 32.6334H248.591V28.7618H257.67C257.688 28.959 257.706 29.2637 257.724 29.676C257.759 30.0703 257.777 30.4646 257.777 30.8589C257.795 31.2532 257.804 31.5311 257.804 31.6924C257.804 32.8933 257.58 34.0045 257.133 35.0262C256.685 36.0478 256.058 36.9261 255.252 37.661C254.446 38.3958 253.497 38.9694 252.405 39.3816C251.313 39.7939 250.113 40 248.806 40Z' fill='#131F1E' />
         <path d='M269.034 40C267.584 40 266.25 39.758 265.032 39.2741C263.814 38.7902 262.758 38.109 261.862 37.2308C260.967 36.3525 260.269 35.3219 259.767 34.139C259.284 32.9381 259.042 31.6296 259.042 30.2137C259.042 28.7798 259.284 27.4713 259.767 26.2884C260.269 25.1054 260.967 24.0748 261.862 23.1965C262.758 22.3183 263.814 21.6372 265.032 21.1532C266.25 20.6693 267.584 20.4273 269.034 20.4273C270.485 20.4273 271.81 20.6693 273.01 21.1532C274.228 21.6372 275.284 22.3183 276.18 23.1965C277.093 24.0748 277.791 25.1054 278.275 26.2884C278.758 27.4713 279 28.7708 279 30.1868C279 31.6207 278.758 32.9381 278.275 34.139C277.791 35.3219 277.093 36.3525 276.18 37.2308C275.284 38.109 274.228 38.7902 273.01 39.2741C271.81 39.758 270.485 40 269.034 40ZM269.034 35.0531C269.679 35.0531 270.279 34.9366 270.834 34.7036C271.389 34.4705 271.873 34.139 272.285 33.7088C272.697 33.2607 273.01 32.7409 273.225 32.1494C273.458 31.5579 273.574 30.9127 273.574 30.2137C273.574 29.5146 273.458 28.8694 273.225 28.2779C273.01 27.6864 272.697 27.1756 272.285 26.7454C271.873 26.2973 271.389 25.9568 270.834 25.7238C270.279 25.4908 269.679 25.3743 269.034 25.3743C268.372 25.3743 267.763 25.4908 267.208 25.7238C266.653 25.9568 266.169 26.2973 265.757 26.7454C265.345 27.1756 265.023 27.6864 264.79 28.2779C264.575 28.8515 264.468 29.4967 264.468 30.2137C264.468 30.9127 264.575 31.5579 264.79 32.1494C265.023 32.7409 265.345 33.2607 265.757 33.7088C266.169 34.139 266.653 34.4705 267.208 34.7036C267.763 34.9366 268.372 35.0531 269.034 35.0531Z' fill='#131F1E' />
      </svg>
   );
};

export const NotFoundblueSvgIcon = () => {
   return (
      <svg width='690' height='246' viewBox='0 0 690 246' fill='none' xmlns='http://www.w3.org/2000/svg' className='not_found_svg'>
         <g id='404_image_blue'>
            <g id='block' clipPath='url(#clip0_5645_335527)'>
               <g id='4'>
                  <path id='Vector' opacity='0.15' d='M570.773 88.0048L619.253 97.7126C624.253 98.7149 625.92 105.02 622.068 108.359L592.56 133.951L600.409 153.891L625.529 157.97C629.317 158.587 631.315 162.795 629.404 166.122L623.863 175.748C622.942 177.345 623.688 179.384 625.424 180.013L651.191 189.36C653.592 190.228 654.699 193.002 653.557 195.286L651.25 199.895C646.605 209.189 636.204 214.101 626.071 211.782L615.332 209.323L616.107 230.854C616.363 237.911 610.874 243.854 603.818 244.157L571.472 245.562C567.76 245.725 564.072 244.839 560.838 243.009L557.004 240.842C550.401 237.107 546.503 229.945 546.958 222.376L570.773 87.9932V88.0048Z' fill='url(#paint0_linear_5645_335527)' />
                  <path id='Vector_2' opacity='0.1' d='M619.784 195.484L608.526 174.752C608.526 174.752 574.624 142.965 570.243 142.983C569.293 142.983 533.259 91.1282 533.259 91.1282L529.57 88.0341C530.38 86.385 440.708 141.882 440.708 141.882L438.838 176.96L433.815 189.04C429.87 198.526 436.915 208.956 447.188 208.84L478.059 208.49C481.602 209.516 485.291 210.04 489.014 210.028L541.649 209.912L553 228.465C553.595 233.307 565.214 238.779 575.155 243.837C584.134 248.399 594.547 244.897 594.955 234.834C595.409 223.64 594.786 210.081 595.095 210.127L602.134 210.588C606.772 210.868 610.35 210.652 613.118 210.069C619.656 208.694 622.971 201.346 619.784 195.478V195.484Z' fill='#1A1A1A' />
                  <path id='Vector_3' opacity='0.1' d='M575.155 243.848C565.214 238.796 553.595 233.325 553.001 228.477L541.644 209.923L489.009 210.04C481.69 210.057 474.511 208.024 468.288 204.166L443.604 188.876L440.697 141.887C440.697 141.887 530.369 86.3909 529.559 88.0399L533.247 91.1341C533.247 91.1341 569.287 142.989 570.231 142.989C574.613 142.971 608.515 174.757 608.515 174.757L619.773 195.49C622.96 201.363 619.644 208.706 613.107 210.081C610.339 210.663 606.761 210.879 602.123 210.599L595.084 210.139C594.781 210.092 595.404 223.646 594.944 234.845C594.536 244.909 584.123 248.411 575.143 243.848H575.155Z' fill='#1A1A1A' />
                  <path id='Vector_4' d='M592.152 230.609C591.604 231.926 590.672 233.057 589.442 233.779C583.866 237.083 570.831 237.765 563.728 236.663C550.262 234.572 542.692 228.523 541.509 218.92L536.918 194.575L450.958 191.184C442.357 191.184 435.103 186.988 435.103 178.387C435.103 177.898 438.843 137.47 438.843 137.47L547.22 14.6191L542.016 60.5069C541.807 62.3366 541.288 64.1138 540.478 65.7629L510.353 127.261L528.906 123.246L533.422 88.3717C533.422 88.3717 567.422 138.577 568.366 138.571C572.748 138.554 616.555 157.585 616.555 157.585L612.243 187.868C611.474 193.695 606.317 197.925 600.45 197.541L593.411 197.081C592.822 196.993 598.422 215.459 592.14 230.603L592.152 230.609Z' fill='url(#paint1_radial_5645_335527)' />
                  <path id='Vector_5' d='M594.483 206.839C595.101 211.145 594.355 215.883 590.608 218.097C585.638 221.028 576.728 221.844 568.442 220.556C554.976 218.464 547.407 212.416 546.224 202.813L541.539 162.612C541.487 162.187 541.149 161.849 540.717 161.808L454.076 153.05C445.475 153.05 438.5 146.075 438.5 137.474C438.5 136.985 438.529 136.397 438.582 135.761C438.815 132.976 439.677 130.278 441.058 127.848L510.533 5.64422C510.533 5.64422 512.649 1.80422 515.061 1.13411C519.128 -0.00215573 524.378 -0.52076 529.967 0.70874C540.554 3.03372 548.065 9.8047 546.743 15.824C546.743 15.824 546.166 18.5744 543.852 22.6358C541.539 26.6972 495.896 118.822 495.896 118.822L534.372 122.668C535.228 122.755 535.945 122.027 535.84 121.171C535.083 115.075 532.554 94.1332 532.956 89.2851C533.504 82.6889 541.871 81.1157 553.723 81.9023C565.581 82.6889 575.277 88.4577 576.845 96.2542L581.437 126.223C581.582 127.161 582.386 127.854 583.336 127.848C583.336 127.848 596.08 129.468 601.138 130.377C603.905 130.872 606.44 132.254 608.351 134.322C612.285 138.587 614.738 144.374 615.455 147.765C616.97 154.909 616.626 164.972 610.805 168.177C607.6 169.937 594.157 168.095 590.02 167.478C589.414 167.385 588.895 167.909 588.983 168.515L594.483 206.851V206.839Z' fill='url(#paint2_radial_5645_335527)' />
               </g>
               <g id='0'>
                  <path id='Vector_6' opacity='0.1' d='M368.477 176.355C367.504 172.328 366.164 168.278 364.445 164.269C352.016 135.303 324.39 119.343 302.743 128.631C298.215 130.578 294.305 133.474 291.071 137.104C285.652 103.564 298.803 73.4786 321.179 69.1841C344.19 64.7673 368.238 89.2932 374.898 123.97C378.738 143.98 375.947 162.999 368.477 176.355Z' fill='#1A1A1A' />
                  <path id='Vector_7' opacity='0.1' d='M355.459 123.235C349.23 122.862 343.065 123.643 337.127 125.705C319.704 131.765 307.567 147.673 302.072 168.464C297.288 160.283 293.611 150.604 291.572 139.97C291.21 138.076 288.32 114.582 288.075 112.711C294.637 100.049 317.187 86.1808 317.321 86.14C330.181 84.5259 345.6 112.193 355.459 123.235Z' fill='#1A1A1A' />
                  <path id='Vector_8' opacity='0.1' d='M323.673 115.986C344.195 112.047 365.546 131.13 374.642 159.91C377.095 149.153 377.351 136.765 374.898 123.975C374.327 120.991 373.622 118.09 372.806 115.269C356.333 101.826 335.985 95.1021 316.546 98.8372C306.873 100.696 298.185 104.326 290.779 109.611C289.369 118.987 289.532 129.342 291.572 139.976C292.294 143.723 293.215 147.353 294.316 150.843C298.512 132.43 309.152 118.777 323.673 115.992V115.986Z' fill='#1A1A1A' />
                  <path id='Vector_9' d='M317.787 108.085C341.527 103.528 365.732 125.665 371.85 157.527C372.392 160.342 372.771 163.145 372.998 165.907C376.902 153.88 377.829 139.219 374.898 123.975C368.243 89.2989 344.19 64.7672 321.179 69.184C300.067 73.2396 287.166 100.254 290.325 131.469C296.064 119.197 305.696 110.404 317.782 108.085H317.787Z' fill='url(#paint3_linear_5645_335527)' />
                  <path id='Vector_10' opacity='0.05' d='M403.404 127.396C393.032 116.581 380.614 151.776 376.623 188.877C374.21 211.282 345.233 202.739 332.874 210.379C330.001 210.291 327.105 210.023 324.191 209.58C316.313 201.085 298.681 199.744 284.801 176.361C267.279 146.841 266.778 127.688 266.778 127.688C247.287 139.26 235.568 170.44 253.09 199.96C267.815 224.766 298.494 240.498 319.483 238.028C301.752 232.346 285.523 219.265 274.982 200.851C292.959 223.705 323.416 235.406 348.239 231.076C375.632 226.298 392.729 212.692 400.129 186.395C396.231 208.869 382.828 228 362.282 236.6C354.993 239.654 347.394 241.128 339.779 241.192C339.79 241.192 339.802 241.192 339.813 241.192C342.092 241.437 344.388 241.565 346.695 241.582C369.193 241.728 392.6 231.134 407.267 214.155C410.652 210.233 413.566 205.973 415.903 201.428C419.014 195.368 421.083 188.795 421.834 181.838C424.084 160.966 416.742 141.311 403.409 127.414L403.404 127.396Z' fill='#1A1A1A' />
                  <path id='Vector_11' opacity='0.15' d='M403.404 127.396C393.031 116.581 380.614 151.776 376.623 188.877C374.21 211.288 347.989 224.276 335.635 231.915C313.749 231.239 290.016 219.941 274.988 200.84C289.474 226.141 314.687 241.378 339.784 241.18C339.796 241.18 339.808 241.18 339.819 241.18C376.261 245.107 417.837 218.921 421.834 181.82C424.084 160.948 416.742 141.294 403.409 127.396H403.404Z' fill='url(#paint4_linear_5645_335527)' />
                  <path id='Vector_12' opacity='0.1' d='M400.129 186.383C396.23 208.858 382.828 227.988 362.282 236.588C330.962 249.699 293.862 233.815 274.982 200.84C292.958 223.693 323.416 235.394 348.239 231.064C375.632 226.286 392.728 212.68 400.129 186.383Z' fill='#1A1A1A' />
                  <g id='Group'>
                     <path id='Vector_13' d='M404.837 141.8C405.163 178.289 384.641 211.386 351.631 217.726C313.324 225.08 273.514 195.106 264.202 149.882C261.568 137.08 260.77 125.357 261.58 114.804C261.376 116.313 263.462 101.285 263.276 102.829C260.246 108.942 258.317 143.094 258.492 154.911C258.731 170.761 264.202 186.33 273.63 199.074C291.403 223.093 322.781 235.51 348.239 231.07C381.605 225.249 399.703 206.329 403.602 167.34C404.155 161.799 406.509 145.891 404.091 130.682C404.231 131.586 404.755 138.864 404.837 141.8Z' fill='url(#paint5_radial_5645_335527)' />
                     <path id='Vector_14' d='M402.926 123.247C394.22 77.9014 352.359 44.6758 314.046 52.0295C275.74 59.3832 252.811 94.5842 264.196 149.877C273.508 195.1 313.411 225.505 351.724 218.152C390.031 210.798 411.631 168.587 402.926 123.241V123.247ZM345.285 194.762C322.274 199.179 298.226 174.647 291.566 139.971C284.911 105.294 298.168 73.6011 321.179 69.1842C344.19 64.7674 368.238 89.2991 374.898 123.976C381.558 158.652 368.296 190.345 345.285 194.762Z' fill='url(#paint6_linear_5645_335527)' />
                  </g>
               </g>
               <g id='4_2'>
                  <path id='Vector_15' opacity='0.03' d='M80.8614 74.8832C84.2294 69.7146 115.713 33.8086 115.713 33.8086L118.108 95.3302L123.276 141.853L175.69 150.937L193.801 177.765C197.576 183.359 196.667 191.016 191.446 195.293C190.963 195.689 190.462 196.044 189.949 196.341C182.543 200.653 165.959 201.271 165.959 201.271L171.809 225.669C172.584 231.642 158.617 238.278 145.559 239.98C132.5 241.676 120.73 240.324 119.955 234.345L117.717 209.295L9.94667 224.463C9.74855 224.492 9.54461 224.515 9.34649 224.533C2.32494 225.139 -2.33667 217.336 1.21781 211.247L80.8556 74.8774L80.8614 74.8832Z' fill='#1A1A1A' />
                  <path id='Vector_16' opacity='0.05' d='M109.105 74.8832C112.473 69.7146 143.956 33.8086 143.956 33.8086L146.351 95.3302L151.52 141.853L203.934 150.937L222.044 177.765C225.82 183.359 224.911 191.016 219.69 195.293C219.206 195.689 218.705 196.044 218.192 196.341C210.786 200.653 194.202 201.271 194.202 201.271L196.178 225.989C196.953 231.962 182.985 238.599 169.927 240.3C156.869 241.996 145.098 240.644 144.323 234.666L142.086 209.615L38.1901 224.468C37.992 224.498 37.788 224.521 37.5899 224.538C30.5683 225.144 25.9067 217.342 29.4612 211.253L109.099 74.8832H109.105Z' fill='#1A1A1A' />
                  <path id='Vector_17' opacity='0.1' d='M123.026 59.4184C126.394 54.2498 165.44 126.388 165.44 126.388L208.181 150.203C208.181 150.203 213.513 159.934 217.103 167.981C219.527 173.412 217.825 179.933 212.861 183.207C212.662 183.336 212.464 183.458 212.26 183.58C204.854 187.892 191.808 186.651 191.808 186.651L191.382 210.758C192.157 216.73 182.205 222.953 169.146 224.649C156.088 226.345 147.575 221.165 146.8 215.186L144.725 195.339L51.8662 207.127C46.2315 207.844 41.1678 203.654 40.8065 197.991L37.3569 157.819L123.026 59.4184Z' fill='#1A1A1A' />
                  <path id='Vector_18' d='M119.151 55.725C122.519 50.5564 154.002 14.6504 154.002 14.6504L156.397 76.172L161.565 122.695L214.824 125.375C214.824 125.375 215.057 156.34 214.929 168.344C214.877 173.064 212.464 177.515 208.385 179.887C200.979 184.199 190.642 183.686 190.642 183.686L190.852 210.7C191.627 216.673 181.674 222.896 168.616 224.592C155.558 226.287 143.787 224.936 143.012 218.957L140.845 191.646L48.2417 205.304C42.6245 206.132 37.4734 202.047 37.0014 196.389L33.4761 154.126L119.151 55.725Z' fill='url(#paint7_radial_5645_335527)' />
                  <path id='Vector_19' d='M117.764 14.737C121.132 9.56846 127.792 6.5792 136.736 6.48597C146.129 6.39274 153.489 10.3959 154.002 14.6438C154.264 16.8289 152.79 19.5967 150.581 23.3202L96.093 120.678C95.7958 121.202 96.227 121.843 96.8272 121.768L135.915 116.75C136.579 116.663 137.057 116.075 137.004 115.404L134.773 88.1865C134.056 82.6683 143.251 76.917 155.313 75.3496C167.375 73.7821 177.735 76.987 178.452 82.5051L181.476 109.834C181.54 110.399 182.059 110.795 182.618 110.708L197.844 108.33C208.636 106.763 214.381 114.309 214.795 124.209C215.209 134.051 210.296 141.171 197.844 142.815C194.913 143.205 189.185 144.277 186.604 144.767C185.858 144.907 185.357 145.606 185.45 146.358L189.914 182.007C190.689 187.98 180.736 194.203 167.678 195.899C154.619 197.594 143.408 194.127 142.627 188.155L139.09 152.779C139.009 151.969 138.275 151.386 137.465 151.497L45.9983 163.78C43.8248 164.072 41.5872 163.798 39.6235 162.836C35.9001 161.012 33.342 157.184 33.342 152.761C33.342 149.731 33.9422 148.042 34.6823 146.544L117.764 14.737Z' fill='url(#paint8_radial_5645_335527)' />
               </g>
            </g>
         </g>
         <defs>
            <linearGradient id='paint0_linear_5645_335527' x1='546.917' y1='166.798' x2='691.042' y2='166.798' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#1A1A1A' />
               <stop offset='1' stopColor='#4D4D4D' stopOpacity='0' />
            </linearGradient>
            <radialGradient id='paint1_radial_5645_335527' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(444 160.999) rotate(-12.6848) scale(159.39 159.391)'>
               <stop stopColor='#2555B2' />
               <stop offset='0.980876' stopColor='#1444A1' />
            </radialGradient>
            <radialGradient id='paint2_radial_5645_335527' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(460.668 128.998) rotate(-5.96856) scale(177.372 184.467)'>
               <stop stopColor='#3161BE' />
               <stop offset='0.829479' stopColor='#1F4FAC' />
            </radialGradient>
            <linearGradient id='paint3_linear_5645_335527' x1='334.697' y1='74.5682' x2='333.5' y2='154' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#3060BD' />
               <stop offset='1' stopColor='#2B5BB8' />
            </linearGradient>
            <linearGradient id='paint4_linear_5645_335527' x1='329.418' y1='194.43' x2='410.559' y2='175.877' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#1A1A1A' />
               <stop offset='1' stopColor='#4D4D4D' stopOpacity='0' />
            </linearGradient>
            <radialGradient id='paint5_radial_5645_335527' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(325 135) rotate(85.7026) scale(86.7439 86.7439)'>
               <stop stopColor='#2A5AB7' />
               <stop offset='1' stopColor='#1949A5' />
            </radialGradient>
            <linearGradient id='paint6_linear_5645_335527' x1='322.035' y1='53.6727' x2='343.636' y2='210.274' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#3565C2' />
               <stop offset='1' stopColor='#2656B3' />
            </linearGradient>
            <radialGradient id='paint7_radial_5645_335527' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(50.5 172.001) rotate(-14.0362) scale(169.047 169.047)'>
               <stop stopColor='#2656B2' />
               <stop offset='0.99' stopColor='#1343A0' />
            </radialGradient>
            <radialGradient id='paint8_radial_5645_335527' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(70 132.5) rotate(-10.8636) scale(164.94 164.941)'>
               <stop stopColor='#3969C6' />
               <stop offset='1' stopColor='#2858B5' />
            </radialGradient>
            <clipPath id='clip0_5645_335527'>
               <rect width='654' height='245.818' fill='white' />
            </clipPath>
         </defs>
      </svg>
   );
};

export const NotFoundblackSvgIcon = () => {
   return (
      <svg width='690' height='246' viewBox='0 0 690 246' fill='none' xmlns='http://www.w3.org/2000/svg' className='not_found_svg'>
         <g id='404_image_dark'>
            <g id='block' clipPath='url(#clip0_5645_335425)'>
               <g id='4'>
                  <path id='Vector' opacity='0.15' d='M570.773 88.0048L619.253 97.7126C624.253 98.7149 625.92 105.02 622.068 108.359L592.56 133.951L600.409 153.891L625.529 157.97C629.317 158.587 631.315 162.795 629.404 166.122L623.863 175.748C622.942 177.345 623.688 179.384 625.424 180.013L651.191 189.36C653.592 190.228 654.699 193.002 653.557 195.286L651.25 199.895C646.605 209.189 636.204 214.101 626.071 211.782L615.332 209.323L616.107 230.854C616.363 237.911 610.874 243.854 603.818 244.157L571.472 245.562C567.76 245.725 564.072 244.839 560.838 243.009L557.004 240.842C550.401 237.107 546.503 229.945 546.958 222.376L570.773 87.9932V88.0048Z' fill='url(#paint0_linear_5645_335425)' />
                  <path id='Vector_2' opacity='0.1' d='M619.784 195.484L608.526 174.752C608.526 174.752 574.624 142.965 570.243 142.983C569.293 142.983 533.259 91.1282 533.259 91.1282L529.57 88.0341C530.38 86.385 440.708 141.882 440.708 141.882L438.838 176.96L433.815 189.04C429.87 198.526 436.915 208.956 447.188 208.84L478.059 208.49C481.602 209.516 485.291 210.04 489.014 210.028L541.649 209.912L553 228.465C553.595 233.307 565.214 238.779 575.155 243.837C584.134 248.399 594.547 244.897 594.955 234.834C595.409 223.64 594.786 210.081 595.095 210.127L602.134 210.588C606.772 210.868 610.35 210.652 613.118 210.069C619.656 208.694 622.971 201.346 619.784 195.478V195.484Z' fill='#1A1A1A' />
                  <path id='Vector_3' opacity='0.1' d='M575.155 243.848C565.214 238.796 553.595 233.325 553.001 228.477L541.644 209.923L489.009 210.04C481.69 210.057 474.511 208.024 468.288 204.166L443.604 188.876L440.697 141.887C440.697 141.887 530.369 86.3909 529.559 88.0399L533.247 91.1341C533.247 91.1341 569.287 142.989 570.231 142.989C574.613 142.971 608.515 174.757 608.515 174.757L619.773 195.49C622.96 201.363 619.644 208.706 613.107 210.081C610.339 210.663 606.761 210.879 602.123 210.599L595.084 210.139C594.781 210.092 595.404 223.646 594.944 234.845C594.536 244.909 584.123 248.411 575.143 243.848H575.155Z' fill='#1A1A1A' />
                  <path id='Vector_4' d='M592.152 230.609C591.604 231.926 590.672 233.057 589.442 233.779C583.866 237.083 570.831 237.765 563.728 236.663C550.262 234.572 542.692 228.523 541.509 218.92L536.918 194.575L450.958 191.184C442.357 191.184 435.103 186.988 435.103 178.387C435.103 177.898 438.843 137.47 438.843 137.47L547.22 14.6191L542.016 60.5069C541.807 62.3366 541.288 64.1138 540.478 65.7629L510.353 127.261L528.906 123.246L533.422 88.3717C533.422 88.3717 567.422 138.577 568.366 138.571C572.748 138.554 616.555 157.585 616.555 157.585L612.243 187.868C611.474 193.695 606.317 197.925 600.45 197.541L593.411 197.081C592.822 196.993 598.422 215.459 592.14 230.603L592.152 230.609Z' fill='url(#paint1_radial_5645_335425)' />
                  <path id='Vector_5' d='M594.483 206.839C595.101 211.145 594.355 215.883 590.608 218.097C585.638 221.028 576.728 221.844 568.442 220.556C554.976 218.464 547.407 212.416 546.224 202.813L541.539 162.612C541.487 162.187 541.149 161.849 540.717 161.808L454.076 153.05C445.475 153.05 438.5 146.075 438.5 137.474C438.5 136.985 438.529 136.397 438.582 135.761C438.815 132.976 439.677 130.278 441.058 127.848L510.533 5.64422C510.533 5.64422 512.649 1.80422 515.061 1.13411C519.128 -0.00215573 524.378 -0.52076 529.967 0.70874C540.554 3.03372 548.065 9.8047 546.743 15.824C546.743 15.824 546.166 18.5744 543.852 22.6358C541.539 26.6972 495.896 118.822 495.896 118.822L534.372 122.668C535.228 122.755 535.945 122.027 535.84 121.171C535.083 115.075 532.554 94.1332 532.956 89.2851C533.504 82.6889 541.871 81.1157 553.723 81.9023C565.581 82.6889 575.277 88.4577 576.845 96.2542L581.437 126.223C581.582 127.161 582.386 127.854 583.336 127.848C583.336 127.848 596.08 129.468 601.138 130.377C603.905 130.872 606.44 132.254 608.351 134.322C612.285 138.587 614.738 144.374 615.455 147.765C616.97 154.909 616.626 164.972 610.805 168.177C607.6 169.937 594.157 168.095 590.02 167.478C589.414 167.385 588.895 167.909 588.983 168.515L594.483 206.851V206.839Z' fill='url(#paint2_radial_5645_335425)' />
               </g>
               <g id='0'>
                  <path id='Vector_6' opacity='0.1' d='M368.477 176.355C367.504 172.328 366.164 168.278 364.445 164.269C352.016 135.303 324.39 119.343 302.743 128.631C298.215 130.578 294.305 133.474 291.071 137.104C285.652 103.564 298.803 73.4786 321.179 69.1841C344.19 64.7673 368.238 89.2932 374.898 123.97C378.738 143.98 375.947 162.999 368.477 176.355Z' fill='#1A1A1A' />
                  <path id='Vector_7' opacity='0.1' d='M355.459 123.235C349.23 122.862 343.065 123.643 337.127 125.705C319.704 131.765 307.567 147.673 302.072 168.464C297.288 160.283 293.611 150.604 291.572 139.97C291.21 138.076 288.32 114.582 288.075 112.711C294.637 100.049 317.187 86.1808 317.321 86.14C330.181 84.5259 345.6 112.193 355.459 123.235Z' fill='#1A1A1A' />
                  <path id='Vector_8' opacity='0.1' d='M323.673 115.986C344.195 112.047 365.546 131.13 374.642 159.91C377.095 149.153 377.351 136.765 374.898 123.975C374.327 120.991 373.622 118.09 372.806 115.269C356.333 101.826 335.985 95.1021 316.546 98.8372C306.873 100.696 298.185 104.326 290.779 109.611C289.369 118.987 289.532 129.342 291.572 139.976C292.294 143.723 293.215 147.353 294.316 150.843C298.512 132.43 309.152 118.777 323.673 115.992V115.986Z' fill='#1A1A1A' />
                  <path id='Vector_9' d='M317.787 108.085C341.527 103.528 365.732 125.665 371.85 157.527C372.392 160.342 372.771 163.145 372.998 165.907C376.902 153.88 377.829 139.219 374.898 123.975C368.243 89.2989 344.19 64.7672 321.179 69.184C300.067 73.2396 287.166 100.254 290.325 131.469C296.064 119.197 305.696 110.404 317.782 108.085H317.787Z' fill='url(#paint3_linear_5645_335425)' />
                  <path id='Vector_10' opacity='0.05' d='M403.404 127.396C393.032 116.581 380.614 151.776 376.623 188.877C374.21 211.282 345.233 202.739 332.874 210.379C330.001 210.291 327.105 210.023 324.191 209.58C316.313 201.085 298.681 199.744 284.801 176.361C267.279 146.841 266.778 127.688 266.778 127.688C247.287 139.26 235.568 170.44 253.09 199.96C267.815 224.766 298.494 240.498 319.483 238.028C301.752 232.346 285.523 219.265 274.982 200.851C292.959 223.705 323.416 235.406 348.239 231.076C375.632 226.298 392.729 212.692 400.129 186.395C396.231 208.869 382.828 228 362.282 236.6C354.993 239.654 347.394 241.128 339.779 241.192C339.79 241.192 339.802 241.192 339.813 241.192C342.092 241.437 344.388 241.565 346.695 241.582C369.193 241.728 392.6 231.134 407.267 214.155C410.652 210.233 413.566 205.973 415.903 201.428C419.014 195.368 421.083 188.795 421.834 181.838C424.084 160.966 416.742 141.311 403.409 127.414L403.404 127.396Z' fill='#1A1A1A' />
                  <path id='Vector_11' opacity='0.15' d='M403.404 127.396C393.031 116.581 380.614 151.776 376.623 188.877C374.21 211.288 347.989 224.276 335.635 231.915C313.749 231.239 290.016 219.941 274.988 200.84C289.474 226.141 314.687 241.378 339.784 241.18C339.796 241.18 339.808 241.18 339.819 241.18C376.261 245.107 417.837 218.921 421.834 181.82C424.084 160.948 416.742 141.294 403.409 127.396H403.404Z' fill='url(#paint4_linear_5645_335425)' />
                  <path id='Vector_12' opacity='0.1' d='M400.129 186.383C396.23 208.858 382.828 227.988 362.282 236.588C330.962 249.699 293.862 233.815 274.982 200.84C292.958 223.693 323.416 235.394 348.239 231.064C375.632 226.286 392.728 212.68 400.129 186.383Z' fill='#1A1A1A' />
                  <g id='Group'>
                     <path id='Vector_13' d='M404.837 141.8C405.163 178.289 384.641 211.386 351.631 217.726C313.324 225.08 273.514 195.106 264.202 149.882C261.568 137.08 260.77 125.357 261.58 114.804C261.376 116.313 263.462 101.285 263.276 102.829C260.246 108.942 258.317 143.094 258.492 154.911C258.731 170.761 264.202 186.33 273.63 199.074C291.403 223.093 322.781 235.51 348.239 231.07C381.605 225.249 399.703 206.329 403.602 167.34C404.155 161.799 406.509 145.891 404.091 130.682C404.231 131.586 404.755 138.864 404.837 141.8Z' fill='url(#paint5_radial_5645_335425)' />
                     <path id='Vector_14' d='M402.926 123.247C394.22 77.9014 352.359 44.6758 314.046 52.0295C275.74 59.3832 252.811 94.5842 264.196 149.877C273.508 195.1 313.411 225.505 351.724 218.152C390.031 210.798 411.631 168.587 402.926 123.241V123.247ZM345.285 194.762C322.274 199.179 298.226 174.647 291.566 139.971C284.911 105.294 298.168 73.6011 321.179 69.1842C344.19 64.7674 368.238 89.2991 374.898 123.976C381.558 158.652 368.296 190.345 345.285 194.762Z' fill='url(#paint6_linear_5645_335425)' />
                  </g>
               </g>
               <g id='4_2'>
                  <path id='Vector_15' opacity='0.03' d='M80.8614 74.8832C84.2294 69.7146 115.713 33.8086 115.713 33.8086L118.108 95.3302L123.276 141.853L175.69 150.937L193.801 177.765C197.576 183.359 196.667 191.016 191.446 195.293C190.963 195.689 190.462 196.044 189.949 196.341C182.543 200.653 165.959 201.271 165.959 201.271L171.809 225.669C172.584 231.642 158.617 238.278 145.559 239.98C132.5 241.676 120.73 240.324 119.955 234.345L117.717 209.295L9.94667 224.463C9.74855 224.492 9.54461 224.515 9.34649 224.533C2.32494 225.139 -2.33667 217.336 1.21781 211.247L80.8556 74.8774L80.8614 74.8832Z' fill='#1A1A1A' />
                  <path id='Vector_16' opacity='0.05' d='M109.105 74.8832C112.473 69.7146 143.956 33.8086 143.956 33.8086L146.351 95.3302L151.52 141.853L203.934 150.937L222.044 177.765C225.82 183.359 224.911 191.016 219.69 195.293C219.206 195.689 218.705 196.044 218.192 196.341C210.786 200.653 194.202 201.271 194.202 201.271L196.178 225.989C196.953 231.962 182.985 238.599 169.927 240.3C156.869 241.996 145.098 240.644 144.323 234.666L142.086 209.615L38.1901 224.468C37.992 224.498 37.788 224.521 37.5899 224.538C30.5683 225.144 25.9067 217.342 29.4612 211.253L109.099 74.8832H109.105Z' fill='#1A1A1A' />
                  <path id='Vector_17' opacity='0.1' d='M123.026 59.4184C126.394 54.2498 165.44 126.388 165.44 126.388L208.181 150.203C208.181 150.203 213.513 159.934 217.103 167.981C219.527 173.412 217.825 179.933 212.861 183.207C212.662 183.336 212.464 183.458 212.26 183.58C204.854 187.892 191.808 186.651 191.808 186.651L191.382 210.758C192.157 216.73 182.205 222.953 169.146 224.649C156.088 226.345 147.575 221.165 146.8 215.186L144.725 195.339L51.8662 207.127C46.2315 207.844 41.1678 203.654 40.8065 197.991L37.3569 157.819L123.026 59.4184Z' fill='#1A1A1A' />
                  <path id='Vector_18' d='M119.151 55.725C122.519 50.5564 154.002 14.6504 154.002 14.6504L156.397 76.172L161.565 122.695L214.824 125.375C214.824 125.375 215.057 156.34 214.929 168.344C214.877 173.064 212.464 177.515 208.385 179.887C200.979 184.199 190.642 183.686 190.642 183.686L190.852 210.7C191.627 216.673 181.674 222.896 168.616 224.592C155.558 226.287 143.787 224.936 143.012 218.957L140.845 191.646L48.2417 205.304C42.6245 206.132 37.4734 202.047 37.0014 196.389L33.4761 154.126L119.151 55.725Z' fill='url(#paint7_radial_5645_335425)' />
                  <path id='Vector_19' d='M117.764 14.737C121.132 9.56846 127.792 6.5792 136.736 6.48597C146.129 6.39274 153.489 10.3959 154.002 14.6438C154.264 16.8289 152.79 19.5967 150.581 23.3202L96.093 120.678C95.7958 121.202 96.227 121.843 96.8272 121.768L135.915 116.75C136.579 116.663 137.057 116.075 137.004 115.404L134.773 88.1865C134.056 82.6683 143.251 76.917 155.313 75.3496C167.375 73.7821 177.735 76.987 178.452 82.5051L181.476 109.834C181.54 110.399 182.059 110.795 182.618 110.708L197.844 108.33C208.636 106.763 214.381 114.309 214.795 124.209C215.209 134.051 210.296 141.171 197.844 142.815C194.913 143.205 189.185 144.277 186.604 144.767C185.858 144.907 185.357 145.606 185.45 146.358L189.914 182.007C190.689 187.98 180.736 194.203 167.678 195.899C154.619 197.594 143.408 194.127 142.627 188.155L139.09 152.779C139.009 151.969 138.275 151.386 137.465 151.497L45.9983 163.78C43.8248 164.072 41.5872 163.798 39.6235 162.836C35.9001 161.012 33.342 157.184 33.342 152.761C33.342 149.731 33.9422 148.042 34.6823 146.544L117.764 14.737Z' fill='url(#paint8_radial_5645_335425)' />
               </g>
            </g>
         </g>
         <defs>
            <linearGradient id='paint0_linear_5645_335425' x1='546.917' y1='166.798' x2='691.042' y2='166.798' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#1A1A1A' />
               <stop offset='1' stopColor='#4D4D4D' stopOpacity='0' />
            </linearGradient>
            <radialGradient id='paint1_radial_5645_335425' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(444 160.999) rotate(-12.6848) scale(159.39 159.391)'>
               <stop stopColor='#414D4C' />
               <stop offset='0.980876' stopColor='#2C3837' />
            </radialGradient>
            <radialGradient id='paint2_radial_5645_335425' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(460.668 128.998) rotate(-5.96856) scale(177.372 184.467)'>
               <stop stopColor='#4B5756' />
               <stop offset='0.829479' stopColor='#364241' />
            </radialGradient>
            <linearGradient id='paint3_linear_5645_335425' x1='334.697' y1='74.5682' x2='333.5' y2='154' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#515E5C' />
               <stop offset='1' stopColor='#44504F' />
            </linearGradient>
            <linearGradient id='paint4_linear_5645_335425' x1='329.418' y1='194.43' x2='410.559' y2='175.877' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#1A1A1A' />
               <stop offset='1' stopColor='#4D4D4D' stopOpacity='0' />
            </linearGradient>
            <radialGradient id='paint5_radial_5645_335425' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(325 135) rotate(85.7026) scale(86.7439 86.7439)'>
               <stop stopColor='#434F4E' />
               <stop offset='1' stopColor='#313D3C' />
            </radialGradient>
            <linearGradient id='paint6_linear_5645_335425' x1='322.035' y1='53.6727' x2='343.636' y2='210.274' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#4E5A59' />
               <stop offset='1' stopColor='#3F4B4A' />
            </linearGradient>
            <radialGradient id='paint7_radial_5645_335425' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(50.5 172.001) rotate(-14.0362) scale(169.047 169.047)'>
               <stop stopColor='#3E4A49' />
               <stop offset='0.99' stopColor='#2C3837' />
            </radialGradient>
            <radialGradient id='paint8_radial_5645_335425' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(70 132.5) rotate(-10.8636) scale(164.94 164.941)'>
               <stop stopColor='#505C5B' />
               <stop offset='1' stopColor='#424E4D' />
            </radialGradient>
            <clipPath id='clip0_5645_335425'>
               <rect width='654' height='245.818' fill='white' />
            </clipPath>
         </defs>
      </svg>
   );
};

export const NotFoundgreenSvgIcon = () => {
   return (
      <svg width='690' height='246' viewBox='0 0 690 246' fill='none' xmlns='http://www.w3.org/2000/svg' className='not_found_svg'>
         <g id='404_image_green'>
            <g id='block' clipPath='url(#clip0_5645_335058)'>
               <g id='4'>
                  <path id='Vector' opacity='0.15' d='M570.773 88.0053L619.253 97.7131C624.253 98.7154 625.92 105.02 622.068 108.359L592.56 133.951L600.409 153.891L625.529 157.97C629.317 158.588 631.315 162.795 629.404 166.122L623.863 175.749C622.942 177.345 623.688 179.385 625.424 180.014L651.191 189.36C653.592 190.229 654.699 193.002 653.557 195.287L651.25 199.896C646.605 209.19 636.204 214.102 626.071 211.783L615.332 209.324L616.107 230.855C616.363 237.911 610.874 243.855 603.818 244.158L571.472 245.562C567.76 245.725 564.072 244.84 560.838 243.01L557.004 240.842C550.401 237.107 546.503 229.946 546.958 222.376L570.773 87.9937V88.0053Z' fill='url(#paint0_linear_5645_335058)' />
                  <path id='Vector_2' opacity='0.1' d='M619.784 195.484L608.526 174.752C608.526 174.752 574.624 142.965 570.243 142.983C569.293 142.983 533.259 91.1282 533.259 91.1282L529.57 88.0341C530.38 86.385 440.708 141.882 440.708 141.882L438.838 176.96L433.815 189.04C429.87 198.526 436.915 208.956 447.188 208.84L478.059 208.49C481.602 209.516 485.291 210.04 489.014 210.028L541.649 209.912L553 228.465C553.595 233.307 565.214 238.779 575.155 243.837C584.134 248.399 594.547 244.897 594.955 234.834C595.409 223.64 594.786 210.081 595.095 210.127L602.134 210.588C606.772 210.868 610.35 210.652 613.118 210.069C619.656 208.694 622.971 201.346 619.784 195.478V195.484Z' fill='#1A1A1A' />
                  <path id='Vector_3' opacity='0.1' d='M575.155 243.849C565.214 238.797 553.595 233.325 553.001 228.477L541.644 209.924L489.009 210.04C481.69 210.058 474.511 208.024 468.288 204.167L443.604 188.877L440.697 141.888C440.697 141.888 530.369 86.3914 529.559 88.0404L533.247 91.1346C533.247 91.1346 569.287 142.989 570.231 142.989C574.613 142.972 608.515 174.758 608.515 174.758L619.773 195.49C622.96 201.364 619.644 208.706 613.107 210.081C610.339 210.664 606.761 210.879 602.123 210.6L595.084 210.139C594.781 210.093 595.404 223.646 594.944 234.846C594.536 244.909 584.123 248.411 575.143 243.849H575.155Z' fill='#1A1A1A' />
                  <path id='Vector_4' d='M592.152 230.61C591.604 231.927 590.672 233.058 589.442 233.78C583.866 237.084 570.831 237.766 563.728 236.664C550.262 234.573 542.692 228.524 541.509 218.921L536.918 194.576L450.958 191.185C442.357 191.185 435.103 186.989 435.103 178.388C435.103 177.899 438.843 137.471 438.843 137.471L547.22 14.6201L542.016 60.5079C541.807 62.3376 541.288 64.1148 540.478 65.7638L510.353 127.262L528.906 123.247L533.422 88.3727C533.422 88.3727 567.422 138.578 568.366 138.572C572.748 138.555 616.555 157.586 616.555 157.586L612.243 187.869C611.474 193.696 606.317 197.926 600.45 197.542L593.411 197.081C592.822 196.994 598.422 215.46 592.14 230.604L592.152 230.61Z' fill='url(#paint1_radial_5645_335058)' />
                  <path id='Vector_5' d='M594.815 206.842C595.432 211.148 594.687 215.885 590.94 218.099C585.969 221.03 577.06 221.846 568.774 220.558C555.308 218.467 547.738 212.418 546.555 202.815L541.871 162.615C541.818 162.189 541.48 161.851 541.049 161.811L454.407 153.053C445.806 153.053 438.832 146.078 438.832 137.477C438.832 136.987 438.861 136.399 438.913 135.764C439.146 132.978 440.009 130.281 441.39 127.851L510.865 5.64666C510.865 5.64666 512.98 1.80666 515.393 1.13655C519.46 0.00028568 524.71 -0.518318 530.298 0.711181C540.886 3.03616 548.397 9.80714 547.074 15.8264C547.074 15.8264 546.497 18.5768 544.184 22.6382C541.871 26.6996 496.228 118.825 496.228 118.825L534.703 122.671C535.56 122.758 536.277 122.03 536.172 121.173C535.414 115.078 532.885 94.1356 533.287 89.2876C533.835 82.6914 542.203 81.1181 554.055 81.9047C565.913 82.6914 575.609 88.4601 577.176 96.2567L581.768 126.225C581.914 127.163 582.718 127.857 583.668 127.851C583.668 127.851 596.411 129.471 601.469 130.38C604.237 130.875 606.772 132.256 608.683 134.325C612.616 138.59 615.069 144.376 615.786 147.767C617.301 154.911 616.957 164.975 611.136 168.179C607.931 169.939 594.488 168.098 590.351 167.48C589.745 167.387 589.227 167.911 589.314 168.517L594.815 206.853V206.842Z' fill='url(#paint2_radial_5645_335058)' />
               </g>
               <g id='0'>
                  <path id='Vector_6' opacity='0.1' d='M368.477 176.355C367.504 172.328 366.164 168.278 364.445 164.269C352.016 135.303 324.39 119.343 302.743 128.631C298.215 130.578 294.305 133.474 291.071 137.104C285.652 103.564 298.803 73.4786 321.179 69.1841C344.19 64.7673 368.238 89.2932 374.898 123.97C378.738 143.98 375.947 162.999 368.477 176.355Z' fill='#1A1A1A' />
                  <path id='Vector_7' opacity='0.1' d='M355.459 123.235C349.23 122.862 343.065 123.643 337.127 125.706C319.704 131.766 307.567 147.674 302.072 168.465C297.288 160.283 293.611 150.605 291.572 139.97C291.21 138.077 288.32 114.582 288.075 112.712C294.637 100.05 317.187 86.1813 317.321 86.1405C330.181 84.5264 345.6 112.193 355.459 123.235Z' fill='#1A1A1A' />
                  <path id='Vector_8' opacity='0.1' d='M323.673 115.986C344.195 112.047 365.546 131.13 374.642 159.91C377.095 149.153 377.351 136.765 374.898 123.975C374.327 120.991 373.622 118.09 372.806 115.269C356.333 101.826 335.985 95.1021 316.546 98.8372C306.873 100.696 298.185 104.326 290.779 109.611C289.369 118.987 289.532 129.342 291.572 139.976C292.294 143.723 293.215 147.353 294.316 150.843C298.512 132.43 309.152 118.777 323.673 115.992V115.986Z' fill='#1A1A1A' />
                  <path id='Vector_9' d='M317.787 108.085C341.527 103.528 365.732 125.665 371.85 157.527C372.392 160.342 372.771 163.145 372.998 165.907C376.902 153.88 377.829 139.219 374.898 123.975C368.243 89.2989 344.19 64.7672 321.179 69.184C300.067 73.2396 287.166 100.254 290.325 131.469C296.064 119.197 305.696 110.404 317.782 108.085H317.787Z' fill='url(#paint3_linear_5645_335058)' />
                  <path id='Vector_10' opacity='0.05' d='M403.404 127.396C393.032 116.581 380.614 151.776 376.623 188.877C374.21 211.282 345.233 202.739 332.874 210.379C330.001 210.291 327.105 210.023 324.191 209.58C316.313 201.085 298.681 199.744 284.801 176.361C267.279 146.841 266.778 127.688 266.778 127.688C247.287 139.26 235.568 170.44 253.09 199.96C267.815 224.766 298.494 240.498 319.483 238.028C301.752 232.346 285.523 219.265 274.982 200.851C292.959 223.705 323.416 235.406 348.239 231.076C375.632 226.298 392.729 212.692 400.129 186.395C396.231 208.869 382.828 228 362.282 236.6C354.993 239.654 347.394 241.128 339.779 241.192C339.79 241.192 339.802 241.192 339.813 241.192C342.092 241.437 344.388 241.565 346.695 241.582C369.193 241.728 392.6 231.134 407.267 214.155C410.652 210.233 413.566 205.973 415.903 201.428C419.014 195.368 421.083 188.795 421.834 181.838C424.084 160.966 416.742 141.311 403.409 127.414L403.404 127.396Z' fill='#1A1A1A' />
                  <path id='Vector_11' opacity='0.15' d='M403.404 127.396C393.031 116.581 380.614 151.776 376.623 188.877C374.21 211.288 347.989 224.276 335.635 231.915C313.749 231.239 290.016 219.941 274.988 200.84C289.474 226.141 314.687 241.378 339.784 241.18C339.796 241.18 339.808 241.18 339.819 241.18C376.261 245.107 417.837 218.921 421.834 181.82C424.084 160.948 416.742 141.294 403.409 127.396H403.404Z' fill='url(#paint4_linear_5645_335058)' />
                  <path id='Vector_12' opacity='0.1' d='M400.129 186.382C396.23 208.857 382.828 227.987 362.282 236.588C330.962 249.699 293.862 233.814 274.982 200.839C292.958 223.693 323.416 235.393 348.239 231.064C375.632 226.286 392.728 212.68 400.129 186.382Z' fill='#1A1A1A' />
                  <g id='Group'>
                     <path id='Vector_13' d='M404.837 141.8C405.163 178.289 384.641 211.386 351.631 217.726C313.324 225.08 273.514 195.106 264.202 149.882C261.568 137.08 260.77 125.357 261.58 114.804C261.376 116.313 263.462 101.285 263.276 102.829C260.246 108.942 258.317 143.094 258.492 154.911C258.731 170.761 264.202 186.33 273.63 199.074C291.403 223.093 322.781 235.51 348.239 231.07C381.605 225.249 399.703 206.329 403.602 167.34C404.155 161.799 406.509 145.891 404.091 130.682C404.231 131.586 404.755 138.864 404.837 141.8Z' fill='url(#paint5_radial_5645_335058)' />
                     <path id='Vector_14' d='M402.926 123.247C394.22 77.9014 352.359 44.6758 314.046 52.0295C275.74 59.3832 252.811 94.5842 264.196 149.877C273.508 195.1 313.411 225.505 351.724 218.152C390.031 210.798 411.631 168.587 402.926 123.241V123.247ZM345.285 194.762C322.274 199.179 298.226 174.647 291.566 139.971C284.911 105.294 298.168 73.6011 321.179 69.1842C344.19 64.7674 368.238 89.2991 374.898 123.976C381.558 158.652 368.296 190.345 345.285 194.762Z' fill='url(#paint6_linear_5645_335058)' />
                  </g>
               </g>
               <g id='4_2'>
                  <path id='Vector_15' opacity='0.03' d='M80.8614 74.8827C84.2294 69.7142 115.713 33.8081 115.713 33.8081L118.108 95.3297L123.276 141.853L175.69 150.937L193.801 177.764C197.576 183.358 196.667 191.015 191.446 195.292C190.963 195.688 190.462 196.044 189.949 196.341C182.543 200.653 165.959 201.271 165.959 201.271L171.809 225.668C172.584 231.641 158.617 238.278 145.559 239.979C132.5 241.675 120.73 240.323 119.955 234.345L117.717 209.294L9.94667 224.462C9.74855 224.491 9.54461 224.515 9.34649 224.532C2.32494 225.138 -2.33667 217.336 1.21781 211.246L80.8556 74.8769L80.8614 74.8827Z' fill='#1A1A1A' />
                  <path id='Vector_16' opacity='0.05' d='M109.105 74.8827C112.473 69.7141 143.956 33.8081 143.956 33.8081L146.351 95.3297L151.52 141.853L203.934 150.937L222.044 177.764C225.82 183.358 224.911 191.015 219.69 195.292C219.206 195.688 218.705 196.044 218.192 196.341C210.786 200.653 194.202 201.271 194.202 201.271L196.178 225.989C196.953 231.961 182.985 238.598 169.927 240.3C156.869 241.996 145.098 240.644 144.323 234.665L142.086 209.615L38.1901 224.468C37.992 224.497 37.788 224.52 37.5899 224.538C30.5683 225.144 25.9067 217.341 29.4612 211.252L109.099 74.8827H109.105Z' fill='#1A1A1A' />
                  <path id='Vector_17' opacity='0.1' d='M123.026 59.4179C126.394 54.2493 165.44 126.388 165.44 126.388L208.181 150.203C208.181 150.203 213.513 159.934 217.103 167.981C219.527 173.412 217.825 179.932 212.861 183.207C212.662 183.335 212.464 183.457 212.26 183.58C204.854 187.892 191.808 186.651 191.808 186.651L191.382 210.757C192.157 216.73 182.205 222.953 169.146 224.649C156.088 226.344 147.575 221.164 146.8 215.186L144.725 195.339L51.8662 207.127C46.2315 207.844 41.1678 203.654 40.8065 197.99L37.3569 157.819L123.026 59.4179Z' fill='#1A1A1A' />
                  <path id='Vector_18' d='M119.151 55.724C122.519 50.5555 154.002 14.6494 154.002 14.6494L156.397 76.171L161.565 122.694L214.824 125.374C214.824 125.374 215.057 156.339 214.929 168.343C214.877 173.063 212.464 177.514 208.385 179.886C200.979 184.198 190.642 183.685 190.642 183.685L190.852 210.699C191.627 216.672 181.674 222.895 168.616 224.591C155.558 226.286 143.787 224.935 143.012 218.956L140.845 191.645L48.2417 205.303C42.6245 206.131 37.4734 202.046 37.0014 196.388L33.4761 154.125L119.151 55.724Z' fill='url(#paint7_radial_5645_335058)' />
                  <path id='Vector_19' d='M117.764 14.7365C121.132 9.56797 127.792 6.57872 136.736 6.48548C146.129 6.39225 153.489 10.3954 154.002 14.6433C154.264 16.8284 152.79 19.5963 150.581 23.3197L96.093 120.677C95.7958 121.202 96.227 121.843 96.8272 121.767L135.915 116.75C136.579 116.663 137.057 116.074 137.004 115.404L134.773 88.186C134.056 82.6678 143.251 76.9166 155.313 75.3491C167.375 73.7816 177.735 76.9865 178.452 82.5047L181.476 109.833C181.54 110.399 182.059 110.795 182.618 110.707L197.844 108.33C208.636 106.763 214.381 114.308 214.795 124.209C215.209 134.05 210.296 141.171 197.844 142.814C194.913 143.205 189.185 144.277 186.604 144.766C185.858 144.906 185.357 145.605 185.45 146.357L189.914 182.007C190.689 187.979 180.736 194.203 167.678 195.898C154.619 197.594 143.408 194.127 142.627 188.154L139.09 152.778C139.009 151.968 138.275 151.386 137.465 151.496L45.9983 163.78C43.8248 164.071 41.5872 163.797 39.6235 162.836C35.9001 161.012 33.342 157.184 33.342 152.761C33.342 149.731 33.9422 148.041 34.6823 146.544L117.764 14.7365Z' fill='url(#paint8_radial_5645_335058)' />
               </g>
            </g>
         </g>
         <defs>
            <linearGradient id='paint0_linear_5645_335058' x1='546.917' y1='166.798' x2='691.042' y2='166.798' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#1A1A1A' />
               <stop offset='1' stopColor='#4D4D4D' stopOpacity='0' />
            </linearGradient>
            <radialGradient id='paint1_radial_5645_335058' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(525.829 125.887) scale(112.123 112.123)'>
               <stop stopColor='#00695D' />
               <stop offset='1' stopColor='#005245' />
            </radialGradient>
            <radialGradient id='paint2_radial_5645_335058' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(527.699 110.556) scale(109.711 114.099)'>
               <stop stopColor='#00796D' />
               <stop offset='1' stopColor='#006053' />
            </radialGradient>
            <linearGradient id='paint3_linear_5645_335058' x1='334.697' y1='74.5682' x2='333.45' y2='123.585' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#00796C' />
               <stop offset='1' stopColor='#007467' />
            </linearGradient>
            <linearGradient id='paint4_linear_5645_335058' x1='329.418' y1='194.43' x2='410.559' y2='175.877' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#1A1A1A' />
               <stop offset='1' stopColor='#4D4D4D' stopOpacity='0' />
            </linearGradient>
            <radialGradient id='paint5_radial_5645_335058' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(331.819 167.334) scale(69.12 69.12)'>
               <stop stopColor='#00695D' />
               <stop offset='1' stopColor='#005245' />
            </radialGradient>
            <linearGradient id='paint6_linear_5645_335058' x1='322.035' y1='53.6727' x2='343.636' y2='210.274' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#00877A' />
               <stop offset='1' stopColor='#00685B' />
            </linearGradient>
            <radialGradient id='paint7_radial_5645_335058' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(124.226 119.961) scale(126.347 126.347)'>
               <stop stopColor='#00695D' />
               <stop offset='0.99' stopColor='#005245' />
            </radialGradient>
            <radialGradient id='paint8_radial_5645_335058' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(124.086 101.413) scale(107.899 107.899)'>
               <stop stopColor='#00796D' />
               <stop offset='1' stopColor='#006053' />
            </radialGradient>
            <clipPath id='clip0_5645_335058'>
               <rect width='654' height='245.818' fill='white' />
            </clipPath>
         </defs>
      </svg>
   );
};

export const NotFoundredSvgIcon = () => {
   return (
      <svg width='690' height='246' viewBox='0 0 690 246' fill='none' xmlns='http://www.w3.org/2000/svg' className='not_found_svg'>
         <g id='404_image_red'>
            <g id='block' clipPath='url(#clip0_5645_335266)'>
               <g id='4'>
                  <path id='Vector' opacity='0.15' d='M570.773 88.0048L619.253 97.7126C624.253 98.7149 625.92 105.02 622.068 108.359L592.56 133.951L600.409 153.891L625.529 157.97C629.317 158.587 631.315 162.795 629.404 166.122L623.863 175.748C622.942 177.345 623.688 179.384 625.424 180.013L651.191 189.36C653.592 190.228 654.699 193.002 653.557 195.286L651.25 199.895C646.605 209.189 636.204 214.101 626.071 211.782L615.332 209.323L616.107 230.854C616.363 237.911 610.874 243.854 603.818 244.157L571.472 245.562C567.76 245.725 564.072 244.839 560.838 243.009L557.004 240.842C550.401 237.107 546.503 229.945 546.958 222.376L570.773 87.9932V88.0048Z' fill='url(#paint0_linear_5645_335266)' />
                  <path id='Vector_2' opacity='0.1' d='M619.784 195.484L608.526 174.752C608.526 174.752 574.624 142.965 570.243 142.983C569.293 142.983 533.259 91.1282 533.259 91.1282L529.57 88.0341C530.38 86.385 440.708 141.882 440.708 141.882L438.838 176.96L433.815 189.04C429.87 198.526 436.915 208.956 447.188 208.84L478.059 208.49C481.602 209.516 485.291 210.04 489.014 210.028L541.649 209.912L553 228.465C553.595 233.307 565.214 238.779 575.155 243.837C584.134 248.399 594.547 244.897 594.955 234.834C595.409 223.64 594.786 210.081 595.095 210.127L602.134 210.588C606.772 210.868 610.35 210.652 613.118 210.069C619.656 208.694 622.971 201.346 619.784 195.478V195.484Z' fill='#1A1A1A' />
                  <path id='Vector_3' opacity='0.1' d='M575.155 243.848C565.214 238.796 553.595 233.325 553.001 228.477L541.644 209.923L489.009 210.04C481.69 210.057 474.511 208.024 468.288 204.166L443.604 188.876L440.697 141.887C440.697 141.887 530.369 86.3909 529.559 88.0399L533.247 91.1341C533.247 91.1341 569.287 142.989 570.231 142.989C574.613 142.971 608.515 174.757 608.515 174.757L619.773 195.49C622.96 201.363 619.644 208.706 613.107 210.081C610.339 210.663 606.761 210.879 602.123 210.599L595.084 210.139C594.781 210.092 595.404 223.646 594.944 234.845C594.536 244.909 584.123 248.411 575.143 243.848H575.155Z' fill='#1A1A1A' />
                  <path id='Vector_4' d='M592.152 230.609C591.604 231.926 590.672 233.057 589.442 233.779C583.866 237.083 570.831 237.765 563.728 236.663C550.262 234.572 542.692 228.523 541.509 218.92L536.918 194.575L450.958 191.184C442.357 191.184 435.103 186.988 435.103 178.387C435.103 177.898 438.843 137.47 438.843 137.47L547.22 14.6191L542.016 60.5069C541.807 62.3366 541.288 64.1138 540.478 65.7629L510.353 127.261L528.906 123.246L533.422 88.3717C533.422 88.3717 567.422 138.577 568.366 138.571C572.748 138.554 616.555 157.585 616.555 157.585L612.243 187.868C611.474 193.695 606.317 197.925 600.45 197.541L593.411 197.081C592.822 196.993 598.422 215.459 592.14 230.603L592.152 230.609Z' fill='url(#paint1_radial_5645_335266)' />
                  <path id='Vector_5' d='M594.815 206.841C595.432 211.147 594.687 215.885 590.94 218.099C585.969 221.03 577.06 221.846 568.774 220.558C555.308 218.466 547.738 212.418 546.555 202.815L541.871 162.614C541.818 162.189 541.48 161.851 541.049 161.81L454.407 153.052C445.806 153.052 438.832 146.077 438.832 137.476C438.832 136.987 438.861 136.398 438.913 135.763C439.146 132.978 440.009 130.28 441.39 127.85L510.865 5.64617C510.865 5.64617 512.98 1.80617 515.393 1.13606C519.46 -0.000202601 524.71 -0.518806 530.298 0.710693C540.886 3.03567 548.397 9.80666 547.074 15.826C547.074 15.826 546.497 18.5763 544.184 22.6377C541.871 26.6992 496.228 118.824 496.228 118.824L534.703 122.67C535.56 122.757 536.277 122.029 536.172 121.172C535.414 115.077 532.885 94.1351 533.287 89.2871C533.835 82.6909 542.203 81.1176 554.055 81.9043C565.913 82.6909 575.609 88.4596 577.176 96.2562L581.768 126.224C581.914 127.163 582.718 127.856 583.668 127.85C583.668 127.85 596.411 129.47 601.469 130.379C604.237 130.874 606.772 132.255 608.683 134.324C612.616 138.589 615.069 144.376 615.786 147.767C617.301 154.911 616.957 164.974 611.136 168.179C607.931 169.939 594.488 168.097 590.351 167.48C589.745 167.387 589.227 167.911 589.314 168.517L594.815 206.853V206.841Z' fill='url(#paint2_radial_5645_335266)' />
               </g>
               <g id='0'>
                  <path id='Vector_6' opacity='0.1' d='M368.477 176.355C367.504 172.328 366.164 168.278 364.445 164.269C352.016 135.303 324.39 119.343 302.743 128.631C298.215 130.578 294.305 133.474 291.071 137.104C285.652 103.564 298.803 73.4786 321.179 69.1841C344.19 64.7673 368.238 89.2932 374.898 123.97C378.738 143.98 375.947 162.999 368.477 176.355Z' fill='#1A1A1A' />
                  <path id='Vector_7' opacity='0.1' d='M355.459 123.235C349.23 122.862 343.065 123.643 337.127 125.705C319.704 131.765 307.567 147.673 302.072 168.464C297.288 160.283 293.611 150.604 291.572 139.97C291.21 138.076 288.32 114.582 288.075 112.711C294.637 100.049 317.187 86.1808 317.321 86.14C330.181 84.5259 345.6 112.193 355.459 123.235Z' fill='#1A1A1A' />
                  <path id='Vector_8' opacity='0.1' d='M323.673 115.986C344.195 112.047 365.546 131.13 374.642 159.91C377.095 149.153 377.351 136.765 374.898 123.975C374.327 120.991 373.622 118.09 372.806 115.269C356.333 101.826 335.985 95.1021 316.546 98.8372C306.873 100.696 298.185 104.326 290.779 109.611C289.369 118.987 289.532 129.342 291.572 139.976C292.294 143.723 293.215 147.353 294.316 150.843C298.512 132.43 309.152 118.777 323.673 115.992V115.986Z' fill='#1A1A1A' />
                  <path id='Vector_9' d='M317.787 108.085C341.527 103.528 365.732 125.665 371.85 157.527C372.392 160.342 372.771 163.145 372.998 165.907C376.902 153.88 377.829 139.219 374.898 123.975C368.243 89.2989 344.19 64.7672 321.179 69.184C300.067 73.2396 287.166 100.254 290.325 131.469C296.064 119.197 305.696 110.404 317.782 108.085H317.787Z' fill='url(#paint3_linear_5645_335266)' />
                  <path id='Vector_10' opacity='0.05' d='M403.404 127.396C393.032 116.581 380.614 151.776 376.623 188.877C374.21 211.282 345.233 202.739 332.874 210.379C330.001 210.291 327.105 210.023 324.191 209.58C316.313 201.085 298.681 199.744 284.801 176.361C267.279 146.841 266.778 127.688 266.778 127.688C247.287 139.26 235.568 170.44 253.09 199.96C267.815 224.766 298.494 240.498 319.483 238.028C301.752 232.346 285.523 219.265 274.982 200.851C292.959 223.705 323.416 235.406 348.239 231.076C375.632 226.298 392.729 212.692 400.129 186.395C396.231 208.869 382.828 228 362.282 236.6C354.993 239.654 347.394 241.128 339.779 241.192C339.79 241.192 339.802 241.192 339.813 241.192C342.092 241.437 344.388 241.565 346.695 241.582C369.193 241.728 392.6 231.134 407.267 214.155C410.652 210.233 413.566 205.973 415.903 201.428C419.014 195.368 421.083 188.795 421.834 181.838C424.084 160.966 416.742 141.311 403.409 127.414L403.404 127.396Z' fill='#1A1A1A' />
                  <path id='Vector_11' opacity='0.15' d='M403.404 127.396C393.031 116.581 380.614 151.776 376.623 188.877C374.21 211.288 347.989 224.276 335.635 231.915C313.749 231.239 290.016 219.941 274.988 200.84C289.474 226.141 314.687 241.378 339.784 241.18C339.796 241.18 339.808 241.18 339.819 241.18C376.261 245.107 417.837 218.921 421.834 181.82C424.084 160.948 416.742 141.294 403.409 127.396H403.404Z' fill='url(#paint4_linear_5645_335266)' />
                  <path id='Vector_12' opacity='0.1' d='M400.129 186.383C396.23 208.858 382.828 227.988 362.282 236.588C330.962 249.699 293.862 233.815 274.982 200.84C292.958 223.693 323.416 235.394 348.239 231.064C375.632 226.286 392.728 212.68 400.129 186.383Z' fill='#1A1A1A' />
                  <g id='Group'>
                     <path id='Vector_13' d='M404.837 141.8C405.163 178.289 384.641 211.386 351.631 217.726C313.324 225.08 273.514 195.106 264.202 149.882C261.568 137.08 260.77 125.357 261.58 114.804C261.376 116.313 263.462 101.285 263.276 102.829C260.246 108.942 258.317 143.094 258.492 154.911C258.731 170.761 264.202 186.33 273.63 199.074C291.403 223.093 322.781 235.51 348.239 231.07C381.605 225.249 399.703 206.329 403.602 167.34C404.155 161.799 406.509 145.891 404.091 130.682C404.231 131.586 404.755 138.864 404.837 141.8Z' fill='url(#paint5_radial_5645_335266)' />
                     <path id='Vector_14' d='M402.926 123.247C394.22 77.9014 352.359 44.6758 314.046 52.0295C275.74 59.3832 252.811 94.5842 264.196 149.877C273.508 195.1 313.411 225.505 351.724 218.152C390.031 210.798 411.631 168.587 402.926 123.241V123.247ZM345.285 194.762C322.274 199.179 298.226 174.647 291.566 139.971C284.911 105.294 298.168 73.6011 321.179 69.1842C344.19 64.7674 368.238 89.2991 374.898 123.976C381.558 158.652 368.296 190.345 345.285 194.762Z' fill='url(#paint6_linear_5645_335266)' />
                  </g>
               </g>
               <g id='4_2'>
                  <path id='Vector_15' opacity='0.03' d='M80.8614 74.8832C84.2294 69.7146 115.713 33.8086 115.713 33.8086L118.108 95.3302L123.276 141.853L175.69 150.937L193.801 177.765C197.576 183.359 196.667 191.016 191.446 195.293C190.963 195.689 190.462 196.044 189.949 196.341C182.543 200.653 165.959 201.271 165.959 201.271L171.809 225.669C172.584 231.642 158.617 238.278 145.559 239.98C132.5 241.676 120.73 240.324 119.955 234.345L117.717 209.295L9.94667 224.463C9.74855 224.492 9.54461 224.515 9.34649 224.533C2.32494 225.139 -2.33667 217.336 1.21781 211.247L80.8556 74.8774L80.8614 74.8832Z' fill='#1A1A1A' />
                  <path id='Vector_16' opacity='0.05' d='M109.105 74.8832C112.473 69.7146 143.956 33.8086 143.956 33.8086L146.351 95.3302L151.52 141.853L203.934 150.937L222.044 177.765C225.82 183.359 224.911 191.016 219.69 195.293C219.206 195.689 218.705 196.044 218.192 196.341C210.786 200.653 194.202 201.271 194.202 201.271L196.178 225.989C196.953 231.962 182.985 238.599 169.927 240.3C156.869 241.996 145.098 240.644 144.323 234.666L142.086 209.615L38.1901 224.468C37.992 224.498 37.788 224.521 37.5899 224.538C30.5683 225.144 25.9067 217.342 29.4612 211.253L109.099 74.8832H109.105Z' fill='#1A1A1A' />
                  <path id='Vector_17' opacity='0.1' d='M123.026 59.4184C126.394 54.2498 165.44 126.388 165.44 126.388L208.181 150.203C208.181 150.203 213.513 159.934 217.103 167.981C219.527 173.412 217.825 179.933 212.861 183.207C212.662 183.336 212.464 183.458 212.26 183.58C204.854 187.892 191.808 186.651 191.808 186.651L191.382 210.758C192.157 216.73 182.205 222.953 169.146 224.649C156.088 226.345 147.575 221.165 146.8 215.186L144.725 195.339L51.8662 207.127C46.2315 207.844 41.1678 203.654 40.8065 197.991L37.3569 157.819L123.026 59.4184Z' fill='#1A1A1A' />
                  <path id='Vector_18' d='M119.151 55.725C122.519 50.5564 154.002 14.6504 154.002 14.6504L156.397 76.172L161.565 122.695L214.824 125.375C214.824 125.375 215.057 156.34 214.929 168.344C214.877 173.064 212.464 177.515 208.385 179.887C200.979 184.199 190.642 183.686 190.642 183.686L190.852 210.7C191.627 216.673 181.674 222.896 168.616 224.592C155.558 226.287 143.787 224.936 143.012 218.957L140.845 191.646L48.2417 205.304C42.6245 206.132 37.4734 202.047 37.0014 196.389L33.4761 154.126L119.151 55.725Z' fill='url(#paint7_radial_5645_335266)' />
                  <path id='Vector_19' d='M117.764 14.737C121.132 9.56846 127.792 6.5792 136.736 6.48597C146.129 6.39274 153.489 10.3959 154.002 14.6438C154.264 16.8289 152.79 19.5967 150.581 23.3202L96.093 120.678C95.7958 121.202 96.227 121.843 96.8272 121.768L135.915 116.75C136.579 116.663 137.057 116.075 137.004 115.404L134.773 88.1865C134.056 82.6683 143.251 76.917 155.313 75.3496C167.375 73.7821 177.735 76.987 178.452 82.5051L181.476 109.834C181.54 110.399 182.059 110.795 182.618 110.708L197.844 108.33C208.636 106.763 214.381 114.309 214.795 124.209C215.209 134.051 210.296 141.171 197.844 142.815C194.913 143.205 189.185 144.277 186.604 144.767C185.858 144.907 185.357 145.606 185.45 146.358L189.914 182.007C190.689 187.98 180.736 194.203 167.678 195.899C154.619 197.594 143.408 194.127 142.627 188.155L139.09 152.779C139.009 151.969 138.275 151.386 137.465 151.497L45.9983 163.78C43.8248 164.072 41.5872 163.798 39.6235 162.836C35.9001 161.012 33.342 157.184 33.342 152.761C33.342 149.731 33.9422 148.042 34.6823 146.544L117.764 14.737Z' fill='url(#paint8_radial_5645_335266)' />
               </g>
            </g>
         </g>
         <defs>
            <linearGradient id='paint0_linear_5645_335266' x1='546.917' y1='166.798' x2='691.042' y2='166.798' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#1A1A1A' />
               <stop offset='1' stopColor='#4D4D4D' stopOpacity='0' />
            </linearGradient>
            <radialGradient id='paint1_radial_5645_335266' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(444 160.999) rotate(-12.6848) scale(159.39 159.391)'>
               <stop stopColor='#AE242B' />
               <stop offset='0.980876' stopColor='#9B1118' />
            </radialGradient>
            <radialGradient id='paint2_radial_5645_335266' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(461 129) rotate(-5.96856) scale(177.372 184.467)'>
               <stop stopColor='#BA3138' />
               <stop offset='0.829479' stopColor='#A71D24' />
            </radialGradient>
            <linearGradient id='paint3_linear_5645_335266' x1='334.697' y1='74.5682' x2='333.5' y2='154' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#C42C34' />
               <stop offset='1' stopColor='#B52B32' />
            </linearGradient>
            <linearGradient id='paint4_linear_5645_335266' x1='329.418' y1='194.43' x2='410.559' y2='175.877' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#1A1A1A' />
               <stop offset='1' stopColor='#4D4D4D' stopOpacity='0' />
            </linearGradient>
            <radialGradient id='paint5_radial_5645_335266' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(325 135) rotate(85.7026) scale(86.7439 86.7439)'>
               <stop stopColor='#B32A31' />
               <stop offset='1' stopColor='#A0171E' />
            </radialGradient>
            <linearGradient id='paint6_linear_5645_335266' x1='322.035' y1='53.6727' x2='343.636' y2='210.274' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#BD333A' />
               <stop offset='1' stopColor='#AD232A' />
            </linearGradient>
            <radialGradient id='paint7_radial_5645_335266' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(50.5 172.001) rotate(-14.0362) scale(169.047 169.047)'>
               <stop stopColor='#AE242B' />
               <stop offset='0.99' stopColor='#9B1118' />
            </radialGradient>
            <radialGradient id='paint8_radial_5645_335266' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(70 132.5) rotate(-10.8636) scale(164.94 164.941)'>
               <stop stopColor='#C0363D' />
               <stop offset='1' stopColor='#AE232A' />
            </radialGradient>
            <clipPath id='clip0_5645_335266'>
               <rect width='654' height='245.818' fill='white' />
            </clipPath>
         </defs>
      </svg>
   );
};

export const NotFoundwhiteSvgIcon = () => {
   return (
      <svg width='690' height='246' viewBox='0 0 690 246' fill='none' xmlns='http://www.w3.org/2000/svg' className='not_found_svg'>
         <g id='404_image_white'>
            <g id='block' clipPath='url(#clip0_5645_335162)'>
               <g id='4'>
                  <path id='Vector' opacity='0.15' d='M570.773 88.0053L619.253 97.7131C624.253 98.7154 625.92 105.02 622.068 108.359L592.56 133.951L600.409 153.891L625.529 157.97C629.317 158.588 631.315 162.795 629.404 166.122L623.863 175.749C622.942 177.345 623.688 179.385 625.424 180.014L651.191 189.36C653.592 190.229 654.699 193.002 653.557 195.287L651.25 199.896C646.605 209.19 636.204 214.102 626.071 211.783L615.332 209.324L616.107 230.855C616.363 237.911 610.874 243.855 603.818 244.158L571.472 245.562C567.76 245.725 564.072 244.84 560.838 243.01L557.004 240.842C550.401 237.107 546.503 229.946 546.958 222.376L570.773 87.9937V88.0053Z' fill='url(#paint0_linear_5645_335162)' />
                  <path id='Vector_2' opacity='0.1' d='M619.784 195.484L608.526 174.752C608.526 174.752 574.624 142.965 570.243 142.983C569.293 142.983 533.259 91.1282 533.259 91.1282L529.57 88.0341C530.38 86.385 440.708 141.882 440.708 141.882L438.838 176.96L433.815 189.04C429.87 198.526 436.915 208.956 447.188 208.84L478.059 208.49C481.602 209.516 485.291 210.04 489.014 210.028L541.649 209.912L553 228.465C553.595 233.307 565.214 238.779 575.155 243.837C584.134 248.399 594.547 244.897 594.955 234.834C595.409 223.64 594.786 210.081 595.095 210.127L602.134 210.588C606.772 210.868 610.35 210.652 613.118 210.069C619.656 208.694 622.971 201.346 619.784 195.478V195.484Z' fill='#1A1A1A' />
                  <path id='Vector_3' opacity='0.1' d='M575.155 243.849C565.214 238.797 553.595 233.325 553.001 228.477L541.644 209.924L489.009 210.04C481.69 210.058 474.511 208.024 468.288 204.167L443.604 188.877L440.697 141.888C440.697 141.888 530.369 86.3914 529.559 88.0404L533.247 91.1346C533.247 91.1346 569.287 142.989 570.231 142.989C574.613 142.972 608.515 174.758 608.515 174.758L619.773 195.49C622.96 201.364 619.644 208.706 613.107 210.081C610.339 210.664 606.761 210.879 602.123 210.6L595.084 210.139C594.781 210.093 595.404 223.646 594.944 234.846C594.536 244.909 584.123 248.411 575.143 243.849H575.155Z' fill='#1A1A1A' />
                  <path id='Vector_4' d='M592.152 230.61C591.604 231.927 590.672 233.058 589.442 233.78C583.866 237.084 570.831 237.766 563.728 236.664C550.262 234.573 542.692 228.524 541.509 218.921L536.918 194.576L450.958 191.185C442.357 191.185 435.103 186.989 435.103 178.388C435.103 177.899 438.843 137.471 438.843 137.471L547.22 14.6201L542.016 60.5079C541.807 62.3376 541.288 64.1148 540.478 65.7638L510.353 127.262L528.906 123.247L533.422 88.3727C533.422 88.3727 567.422 138.578 568.366 138.572C572.748 138.555 616.555 157.586 616.555 157.586L612.243 187.869C611.474 193.696 606.317 197.926 600.45 197.542L593.411 197.081C592.822 196.994 598.422 215.46 592.14 230.604L592.152 230.61Z' fill='url(#paint1_radial_5645_335162)' />
                  <path id='Vector_5' d='M594.815 206.842C595.432 211.148 594.687 215.885 590.94 218.099C585.969 221.03 577.06 221.846 568.774 220.558C555.308 218.467 547.738 212.418 546.555 202.815L541.871 162.615C541.818 162.189 541.48 161.851 541.049 161.811L454.407 153.053C445.806 153.053 438.832 146.078 438.832 137.477C438.832 136.987 438.861 136.399 438.913 135.764C439.146 132.978 440.009 130.281 441.39 127.851L510.865 5.64666C510.865 5.64666 512.98 1.80666 515.393 1.13655C519.46 0.00028568 524.71 -0.518318 530.298 0.711181C540.886 3.03616 548.397 9.80714 547.074 15.8264C547.074 15.8264 546.497 18.5768 544.184 22.6382C541.871 26.6996 496.228 118.825 496.228 118.825L534.703 122.671C535.56 122.758 536.277 122.03 536.172 121.173C535.414 115.078 532.885 94.1356 533.287 89.2876C533.835 82.6914 542.203 81.1181 554.055 81.9047C565.913 82.6914 575.609 88.4601 577.176 96.2567L581.768 126.225C581.914 127.163 582.718 127.857 583.668 127.851C583.668 127.851 596.411 129.471 601.469 130.38C604.237 130.875 606.772 132.256 608.683 134.325C612.616 138.59 615.069 144.376 615.786 147.767C617.301 154.911 616.957 164.975 611.136 168.179C607.931 169.939 594.488 168.098 590.351 167.48C589.745 167.387 589.227 167.911 589.314 168.517L594.815 206.853V206.842Z' fill='url(#paint2_radial_5645_335162)' />
               </g>
               <g id='0'>
                  <path id='Vector_6' opacity='0.1' d='M368.477 176.355C367.504 172.328 366.164 168.278 364.445 164.269C352.016 135.303 324.39 119.343 302.743 128.631C298.215 130.578 294.305 133.474 291.071 137.104C285.652 103.564 298.803 73.4786 321.179 69.1841C344.19 64.7673 368.238 89.2932 374.898 123.97C378.738 143.98 375.947 162.999 368.477 176.355Z' fill='#1A1A1A' />
                  <path id='Vector_7' opacity='0.1' d='M355.459 123.235C349.23 122.862 343.065 123.643 337.127 125.706C319.704 131.766 307.567 147.674 302.072 168.465C297.288 160.283 293.611 150.605 291.572 139.97C291.21 138.077 288.32 114.582 288.075 112.712C294.637 100.05 317.187 86.1813 317.321 86.1405C330.181 84.5264 345.6 112.193 355.459 123.235Z' fill='#1A1A1A' />
                  <path id='Vector_8' opacity='0.1' d='M323.673 115.986C344.195 112.047 365.546 131.13 374.642 159.91C377.095 149.153 377.351 136.765 374.898 123.975C374.327 120.991 373.622 118.09 372.806 115.269C356.333 101.826 335.985 95.1021 316.546 98.8372C306.873 100.696 298.185 104.326 290.779 109.611C289.369 118.987 289.532 129.342 291.572 139.976C292.294 143.723 293.215 147.353 294.316 150.843C298.512 132.43 309.152 118.777 323.673 115.992V115.986Z' fill='#1A1A1A' />
                  <path id='Vector_9' d='M317.787 108.085C341.527 103.528 365.732 125.665 371.85 157.527C372.392 160.342 372.771 163.145 372.998 165.907C376.902 153.88 377.829 139.219 374.898 123.975C368.243 89.2989 344.19 64.7672 321.179 69.184C300.067 73.2396 287.166 100.254 290.325 131.469C296.064 119.197 305.696 110.404 317.782 108.085H317.787Z' fill='url(#paint3_linear_5645_335162)' />
                  <path id='Vector_10' opacity='0.05' d='M403.404 127.396C393.032 116.581 380.614 151.776 376.623 188.877C374.21 211.282 345.233 202.739 332.874 210.379C330.001 210.291 327.105 210.023 324.191 209.58C316.313 201.085 298.681 199.744 284.801 176.361C267.279 146.841 266.778 127.688 266.778 127.688C247.287 139.26 235.568 170.44 253.09 199.96C267.815 224.766 298.494 240.498 319.483 238.028C301.752 232.346 285.523 219.265 274.982 200.851C292.959 223.705 323.416 235.406 348.239 231.076C375.632 226.298 392.729 212.692 400.129 186.395C396.231 208.869 382.828 228 362.282 236.6C354.993 239.654 347.394 241.128 339.779 241.192C339.79 241.192 339.802 241.192 339.813 241.192C342.092 241.437 344.388 241.565 346.695 241.582C369.193 241.728 392.6 231.134 407.267 214.155C410.652 210.233 413.566 205.973 415.903 201.428C419.014 195.368 421.083 188.795 421.834 181.838C424.084 160.966 416.742 141.311 403.409 127.414L403.404 127.396Z' fill='#1A1A1A' />
                  <path id='Vector_11' opacity='0.15' d='M403.404 127.396C393.031 116.581 380.614 151.776 376.623 188.877C374.21 211.288 347.989 224.276 335.635 231.915C313.749 231.239 290.016 219.941 274.988 200.84C289.474 226.141 314.687 241.378 339.784 241.18C339.796 241.18 339.808 241.18 339.819 241.18C376.261 245.107 417.837 218.921 421.834 181.82C424.084 160.948 416.742 141.294 403.409 127.396H403.404Z' fill='url(#paint4_linear_5645_335162)' />
                  <path id='Vector_12' opacity='0.1' d='M400.129 186.382C396.23 208.857 382.828 227.987 362.282 236.588C330.962 249.699 293.862 233.814 274.982 200.839C292.958 223.693 323.416 235.393 348.239 231.064C375.632 226.286 392.728 212.68 400.129 186.382Z' fill='#1A1A1A' />
                  <g id='Group'>
                     <path id='Vector_13' d='M404.837 141.8C405.163 178.289 384.641 211.386 351.631 217.726C313.324 225.08 273.514 195.106 264.202 149.882C261.568 137.08 260.77 125.357 261.58 114.804C261.376 116.313 263.462 101.285 263.276 102.829C260.246 108.942 258.317 143.094 258.492 154.911C258.731 170.761 264.202 186.33 273.63 199.074C291.403 223.093 322.781 235.51 348.239 231.07C381.605 225.249 399.703 206.329 403.602 167.34C404.155 161.799 406.509 145.891 404.091 130.682C404.231 131.586 404.755 138.864 404.837 141.8Z' fill='url(#paint5_radial_5645_335162)' />
                     <path id='Vector_14' d='M402.926 123.247C394.22 77.9014 352.359 44.6758 314.046 52.0295C275.74 59.3832 252.811 94.5842 264.196 149.877C273.508 195.1 313.411 225.505 351.724 218.152C390.031 210.798 411.631 168.587 402.926 123.241V123.247ZM345.285 194.762C322.274 199.179 298.226 174.647 291.566 139.971C284.911 105.294 298.168 73.6011 321.179 69.1842C344.19 64.7674 368.238 89.2991 374.898 123.976C381.558 158.652 368.296 190.345 345.285 194.762Z' fill='url(#paint6_linear_5645_335162)' />
                  </g>
               </g>
               <g id='4_2'>
                  <path id='Vector_15' opacity='0.03' d='M80.8614 74.8827C84.2294 69.7142 115.713 33.8081 115.713 33.8081L118.108 95.3297L123.276 141.853L175.69 150.937L193.801 177.764C197.576 183.358 196.667 191.015 191.446 195.292C190.963 195.688 190.462 196.044 189.949 196.341C182.543 200.653 165.959 201.271 165.959 201.271L171.809 225.668C172.584 231.641 158.617 238.278 145.559 239.979C132.5 241.675 120.73 240.323 119.955 234.345L117.717 209.294L9.94667 224.462C9.74855 224.491 9.54461 224.515 9.34649 224.532C2.32494 225.138 -2.33667 217.336 1.21781 211.246L80.8556 74.8769L80.8614 74.8827Z' fill='#1A1A1A' />
                  <path id='Vector_16' opacity='0.05' d='M109.105 74.8827C112.473 69.7141 143.956 33.8081 143.956 33.8081L146.351 95.3297L151.52 141.853L203.934 150.937L222.044 177.764C225.82 183.358 224.911 191.015 219.69 195.292C219.206 195.688 218.705 196.044 218.192 196.341C210.786 200.653 194.202 201.271 194.202 201.271L196.178 225.989C196.953 231.961 182.985 238.598 169.927 240.3C156.869 241.996 145.098 240.644 144.323 234.665L142.086 209.615L38.1901 224.468C37.992 224.497 37.788 224.52 37.5899 224.538C30.5683 225.144 25.9067 217.341 29.4612 211.252L109.099 74.8827H109.105Z' fill='#1A1A1A' />
                  <path id='Vector_17' opacity='0.1' d='M123.026 59.4179C126.394 54.2493 165.44 126.388 165.44 126.388L208.181 150.203C208.181 150.203 213.513 159.934 217.103 167.981C219.527 173.412 217.825 179.932 212.861 183.207C212.662 183.335 212.464 183.457 212.26 183.58C204.854 187.892 191.808 186.651 191.808 186.651L191.382 210.757C192.157 216.73 182.205 222.953 169.146 224.649C156.088 226.344 147.575 221.164 146.8 215.186L144.725 195.339L51.8662 207.127C46.2315 207.844 41.1678 203.654 40.8065 197.99L37.3569 157.819L123.026 59.4179Z' fill='#1A1A1A' />
                  <path id='Vector_18' d='M119.151 55.724C122.519 50.5555 154.002 14.6494 154.002 14.6494L156.397 76.171L161.565 122.694L214.824 125.374C214.824 125.374 215.057 156.339 214.929 168.343C214.877 173.063 212.464 177.514 208.385 179.886C200.979 184.198 190.642 183.685 190.642 183.685L190.852 210.699C191.627 216.672 181.674 222.895 168.616 224.591C155.558 226.286 143.787 224.935 143.012 218.956L140.845 191.645L48.2417 205.303C42.6245 206.131 37.4734 202.046 37.0014 196.388L33.4761 154.125L119.151 55.724Z' fill='url(#paint7_radial_5645_335162)' />
                  <path id='Vector_19' d='M117.764 14.7365C121.132 9.56797 127.792 6.57872 136.736 6.48548C146.129 6.39225 153.489 10.3954 154.002 14.6433C154.264 16.8284 152.79 19.5963 150.581 23.3197L96.093 120.677C95.7958 121.202 96.227 121.843 96.8272 121.767L135.915 116.75C136.579 116.663 137.057 116.074 137.004 115.404L134.773 88.186C134.056 82.6678 143.251 76.9166 155.313 75.3491C167.375 73.7816 177.735 76.9865 178.452 82.5047L181.476 109.833C181.54 110.399 182.059 110.795 182.618 110.707L197.844 108.33C208.636 106.763 214.381 114.308 214.795 124.209C215.209 134.05 210.296 141.171 197.844 142.814C194.913 143.205 189.185 144.277 186.604 144.766C185.858 144.906 185.357 145.605 185.45 146.357L189.914 182.007C190.689 187.979 180.736 194.203 167.678 195.898C154.619 197.594 143.408 194.127 142.627 188.154L139.09 152.778C139.009 151.968 138.275 151.386 137.465 151.496L45.9983 163.78C43.8248 164.071 41.5872 163.797 39.6235 162.836C35.9001 161.012 33.342 157.184 33.342 152.761C33.342 149.731 33.9422 148.041 34.6823 146.544L117.764 14.7365Z' fill='url(#paint8_radial_5645_335162)' />
               </g>
            </g>
         </g>
         <defs>
            <linearGradient id='paint0_linear_5645_335162' x1='546.917' y1='166.798' x2='691.042' y2='166.798' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#1A1A1A' />
               <stop offset='1' stopColor='#4D4D4D' stopOpacity='0' />
            </linearGradient>
            <radialGradient id='paint1_radial_5645_335162' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(444 161) rotate(-12.6848) scale(159.39 159.391)'>
               <stop stopColor='#CFD3DD' />
               <stop offset='0.980876' stopColor='#9A9CA5' />
            </radialGradient>
            <radialGradient id='paint2_radial_5645_335162' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(461 129) rotate(-5.96856) scale(177.372 184.467)'>
               <stop stopColor='#E9EDF7' />
               <stop offset='0.829479' stopColor='#C5C9D2' />
            </radialGradient>
            <linearGradient id='paint3_linear_5645_335162' x1='334.697' y1='74.5682' x2='333.5' y2='154' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#F4F5FD' />
               <stop offset='1' stopColor='#E2E2EF' />
            </linearGradient>
            <linearGradient id='paint4_linear_5645_335162' x1='329.418' y1='194.43' x2='410.559' y2='175.877' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#1A1A1A' />
               <stop offset='1' stopColor='#4D4D4D' stopOpacity='0' />
            </linearGradient>
            <radialGradient id='paint5_radial_5645_335162' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(325 135) rotate(85.7026) scale(86.7439 86.7439)'>
               <stop stopColor='#F7F8FC' />
               <stop offset='1' stopColor='#B4B7C0' />
            </radialGradient>
            <linearGradient id='paint6_linear_5645_335162' x1='322.035' y1='53.6727' x2='343.636' y2='210.274' gradientUnits='userSpaceOnUse'>
               <stop stopColor='#E9EDF7' />
               <stop offset='1' stopColor='#E5E8F2' />
            </linearGradient>
            <radialGradient id='paint7_radial_5645_335162' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(50.5 172) rotate(-14.0362) scale(169.047 169.047)'>
               <stop stopColor='#D1D3DE' />
               <stop offset='0.99' stopColor='#888B93' />
            </radialGradient>
            <radialGradient id='paint8_radial_5645_335162' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(70 132.5) rotate(-10.8636) scale(164.94 164.941)'>
               <stop stopColor='#ECEFF8' />
               <stop offset='1' stopColor='#CACED8' />
            </radialGradient>
            <clipPath id='clip0_5645_335162'>
               <rect width='654' height='245.818' fill='white' />
            </clipPath>
         </defs>
      </svg>
   );
};

export const TemplateSecondDefaultLogoIcon = () => {
   return (
      <svg width='66' height='33' viewBox='0 0 66 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path d='M6.19375 15.9256V8.18463L6.35083 9.27147L0 0.39925H4.98193L9.82921 7.27522L7.2036 7.20868L11.5796 0.39925H16.4269L10.3454 9.51546L10.682 8.00718V15.9256H6.19375Z' fill='#131F1E' />
         <path d='M24.3484 16.1474C23.1366 16.1474 22.022 15.9478 21.0047 15.5486C19.9874 15.1493 19.1047 14.5874 18.3567 13.8628C17.6086 13.1383 17.0251 12.288 16.6062 11.3121C16.2023 10.3214 16.0003 9.2419 16.0003 8.07372C16.0003 6.89076 16.2023 5.81131 16.6062 4.83536C17.0251 3.85942 17.6086 3.00916 18.3567 2.2846C19.1047 1.56003 19.9874 0.998125 21.0047 0.598875C22.022 0.199625 23.1366 0 24.3484 0C25.5602 0 26.6673 0.199625 27.6697 0.598875C28.687 0.998125 29.5697 1.56003 30.3178 2.2846C31.0808 3.00916 31.6642 3.85942 32.0682 4.83536C32.4721 5.81131 32.6741 6.88337 32.6741 8.05154C32.6741 9.23451 32.4721 10.3214 32.0682 11.3121C31.6642 12.288 31.0808 13.1383 30.3178 13.8628C29.5697 14.5874 28.687 15.1493 27.6697 15.5486C26.6673 15.9478 25.5602 16.1474 24.3484 16.1474ZM24.3484 12.0662C24.887 12.0662 25.3882 11.9701 25.852 11.7779C26.3158 11.5856 26.7197 11.3121 27.0638 10.9572C27.4079 10.5875 27.6697 10.1587 27.8492 9.67072C28.0437 9.18275 28.141 8.65042 28.141 8.07372C28.141 7.49703 28.0437 6.9647 27.8492 6.47672C27.6697 5.98875 27.4079 5.56732 27.0638 5.21243C26.7197 4.84276 26.3158 4.5618 25.852 4.36957C25.3882 4.17734 24.887 4.08122 24.3484 4.08122C23.7949 4.08122 23.2862 4.17734 22.8224 4.36957C22.3586 4.5618 21.9547 4.84276 21.6106 5.21243C21.2665 5.56732 20.9972 5.98875 20.8027 6.47672C20.6232 6.94991 20.5334 7.48224 20.5334 8.07372C20.5334 8.65042 20.6232 9.18275 20.8027 9.67072C20.9972 10.1587 21.2665 10.5875 21.6106 10.9572C21.9547 11.3121 22.3586 11.5856 22.8224 11.7779C23.2862 11.9701 23.7949 12.0662 24.3484 12.0662Z' fill='#131F1E' />
         <path d='M42.0813 16.0587C40.7498 16.0587 39.5604 15.8 38.5132 15.2824C37.4809 14.7501 36.6655 14.0255 36.0671 13.1087C35.4836 12.1919 35.1919 11.142 35.1919 9.95907V0.39925H39.6801V9.75945C39.6801 10.2474 39.7848 10.6836 39.9943 11.0681C40.2037 11.4378 40.488 11.7335 40.847 11.9553C41.221 12.1623 41.6325 12.2658 42.0813 12.2658C42.575 12.2658 43.0089 12.1623 43.3829 11.9553C43.7569 11.7335 44.0561 11.4378 44.2805 11.0681C44.5049 10.6836 44.6171 10.2474 44.6171 9.75945V0.39925H48.9707V9.95907C48.9707 11.142 48.6715 12.1919 48.0731 13.1087C47.4896 14.0255 46.6817 14.7501 45.6494 15.2824C44.6171 15.8 43.4278 16.0587 42.0813 16.0587Z' fill='#131F1E' />
         <path d='M52.4568 15.9256V0.39925H59.7053C60.7675 0.39925 61.7175 0.613663 62.5553 1.04249C63.3931 1.47131 64.0439 2.0554 64.5077 2.79475C64.9864 3.5341 65.2258 4.38436 65.2258 5.34551C65.2258 6.38061 64.9864 7.2974 64.5077 8.0959C64.0439 8.8944 63.3931 9.52285 62.5553 9.98125C61.7175 10.4249 60.7675 10.6467 59.7053 10.6467H56.6308V15.9256H52.4568ZM60.8722 15.9256L56.8777 8.91658L61.4332 8.36207L65.8766 15.9256H60.8722ZM56.6308 7.49703H59.0994C59.4883 7.49703 59.81 7.42309 60.0643 7.27522C60.3336 7.11257 60.5356 6.89815 60.6702 6.63199C60.8198 6.35103 60.8946 6.02572 60.8946 5.65604C60.8946 5.30115 60.8198 4.99802 60.6702 4.74664C60.5206 4.48047 60.2962 4.28085 59.997 4.14777C59.7127 3.99989 59.3537 3.92596 58.9198 3.92596H56.6308V7.49703Z' fill='#131F1E' />
         <path d='M3.09687 32.7782V17.2518H7.54021V28.9853H14.3848V32.7782H3.09687Z' fill='#131F1E' />
         <path d='M23.0414 33C21.8296 33 20.715 32.8004 19.6977 32.4011C18.6804 32.0019 17.7977 31.44 17.0496 30.7154C16.3016 29.9908 15.7181 29.1406 15.2992 28.1646C14.8953 27.1739 14.6933 26.0945 14.6933 24.9263C14.6933 23.7433 14.8953 22.6639 15.2992 21.6879C15.7181 20.712 16.3016 19.8617 17.0496 19.1372C17.7977 18.4126 18.6804 17.8507 19.6977 17.4514C20.715 17.0522 21.8296 16.8526 23.0414 16.8526C24.2532 16.8526 25.3603 17.0522 26.3627 17.4514C27.38 17.8507 28.2627 18.4126 29.0107 19.1372C29.7737 19.8617 30.3572 20.712 30.7611 21.6879C31.1651 22.6639 31.3671 23.7359 31.3671 24.9041C31.3671 26.0871 31.1651 27.1739 30.7611 28.1646C30.3572 29.1406 29.7737 29.9908 29.0107 30.7154C28.2627 31.44 27.38 32.0019 26.3627 32.4011C25.3603 32.8004 24.2532 33 23.0414 33ZM23.0414 28.9188C23.58 28.9188 24.0812 28.8227 24.545 28.6304C25.0087 28.4382 25.4127 28.1646 25.7568 27.8098C26.1009 27.4401 26.3627 27.0112 26.5422 26.5233C26.7367 26.0353 26.834 25.503 26.834 24.9263C26.834 24.3496 26.7367 23.8172 26.5422 23.3293C26.3627 22.8413 26.1009 22.4199 25.7568 22.065C25.4127 21.6953 25.0087 21.4144 24.545 21.2221C24.0812 21.0299 23.58 20.9338 23.0414 20.9338C22.4879 20.9338 21.9792 21.0299 21.5154 21.2221C21.0516 21.4144 20.6477 21.6953 20.3036 22.065C19.9595 22.4199 19.6902 22.8413 19.4957 23.3293C19.3162 23.8025 19.2264 24.3348 19.2264 24.9263C19.2264 25.503 19.3162 26.0353 19.4957 26.5233C19.6902 27.0112 19.9595 27.4401 20.3036 27.8098C20.6477 28.1646 21.0516 28.4382 21.5154 28.6304C21.9792 28.8227 22.4879 28.9188 23.0414 28.9188Z' fill='#131F1E' />
         <path d='M40.7743 33C39.4727 33 38.3057 32.8004 37.2735 32.4011C36.2412 32.0019 35.3585 31.44 34.6254 30.7154C33.9073 29.9908 33.3612 29.1332 32.9872 28.1425C32.6132 27.1517 32.4262 26.0797 32.4262 24.9263C32.4262 23.7581 32.6281 22.686 33.0321 21.7101C33.451 20.7194 34.0494 19.8617 34.8274 19.1372C35.6053 18.4126 36.5179 17.8507 37.5652 17.4514C38.6274 17.0522 39.8018 16.8526 41.0884 16.8526C41.9711 16.8526 42.8014 16.9635 43.5794 17.1853C44.3723 17.4071 45.083 17.7102 45.7113 18.0947C46.3397 18.4791 46.8782 18.908 47.3271 19.3811L44.679 22.1759C44.3499 21.8654 43.9983 21.5992 43.6243 21.3774C43.2652 21.1556 42.8688 20.9781 42.4349 20.8451C42.016 20.712 41.5447 20.6454 41.0211 20.6454C40.4377 20.6454 39.8991 20.7563 39.4054 20.9781C38.9266 21.1852 38.5077 21.4809 38.1487 21.8654C37.7896 22.235 37.5053 22.686 37.2959 23.2184C37.1014 23.7359 37.0042 24.3052 37.0042 24.9263C37.0042 25.5325 37.1089 26.1018 37.3183 26.6342C37.5278 27.1517 37.8195 27.6027 38.1935 27.9872C38.5676 28.3717 39.0089 28.6748 39.5176 28.8966C40.0412 29.1036 40.6022 29.2071 41.2006 29.2071C41.6195 29.2071 42.0085 29.1406 42.3676 29.0075C42.7266 28.8744 43.0333 28.697 43.2877 28.4752C43.557 28.2386 43.7664 27.965 43.916 27.6545C44.0656 27.3292 44.1404 26.9817 44.1404 26.612V25.9909L44.7239 26.9225H40.5947V23.7285H48.1798C48.1948 23.8912 48.2098 24.1426 48.2247 24.4827C48.2546 24.808 48.2696 25.1333 48.2696 25.4586C48.2846 25.7839 48.292 26.0131 48.292 26.1462C48.292 27.1369 48.105 28.0537 47.731 28.8966C47.357 29.7395 46.8334 30.464 46.1601 31.0703C45.4869 31.6766 44.694 32.1497 43.7814 32.4898C42.8688 32.8299 41.8664 33 40.7743 33Z' fill='#131F1E' />
         <path d='M57.6743 33C56.4625 33 55.348 32.8004 54.3306 32.4011C53.3133 32.0019 52.4306 31.44 51.6826 30.7154C50.9345 29.9908 50.3511 29.1406 49.9322 28.1646C49.5282 27.1739 49.3263 26.0945 49.3263 24.9263C49.3263 23.7433 49.5282 22.6639 49.9322 21.6879C50.3511 20.712 50.9345 19.8617 51.6826 19.1372C52.4306 18.4126 53.3133 17.8507 54.3306 17.4514C55.348 17.0522 56.4625 16.8526 57.6743 16.8526C58.8862 16.8526 59.9933 17.0522 60.9956 17.4514C62.013 17.8507 62.8956 18.4126 63.6437 19.1372C64.4067 19.8617 64.9902 20.712 65.3941 21.6879C65.798 22.6639 66 23.7359 66 24.9041C66 26.0871 65.798 27.1739 65.3941 28.1646C64.9902 29.1406 64.4067 29.9908 63.6437 30.7154C62.8956 31.44 62.013 32.0019 60.9956 32.4011C59.9933 32.8004 58.8862 33 57.6743 33ZM57.6743 28.9188C58.2129 28.9188 58.7141 28.8227 59.1779 28.6304C59.6417 28.4382 60.0456 28.1646 60.3897 27.8098C60.7338 27.4401 60.9956 27.0112 61.1752 26.5233C61.3697 26.0353 61.4669 25.503 61.4669 24.9263C61.4669 24.3496 61.3697 23.8172 61.1752 23.3293C60.9956 22.8413 60.7338 22.4199 60.3897 22.065C60.0456 21.6953 59.6417 21.4144 59.1779 21.2221C58.7141 21.0299 58.2129 20.9338 57.6743 20.9338C57.1208 20.9338 56.6121 21.0299 56.1484 21.2221C55.6846 21.4144 55.2806 21.6953 54.9365 22.065C54.5924 22.4199 54.3231 22.8413 54.1287 23.3293C53.9491 23.8025 53.8594 24.3348 53.8594 24.9263C53.8594 25.503 53.9491 26.0353 54.1287 26.5233C54.3231 27.0112 54.5924 27.4401 54.9365 27.8098C55.2806 28.1646 55.6846 28.4382 56.1484 28.6304C56.6121 28.8227 57.1208 28.9188 57.6743 28.9188Z' fill='#131F1E' />
      </svg>
   );
};
