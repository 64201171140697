const initialState = {
   data: [],
   loading: true,
   offersLoading: true,
   offers: [],
   createLoading: false,
   mainInfo: {},
   mainLoading: true,
   userPageLoading: true,
   settings: {},
   settingsLoading: true,
   editLoader: true,
   inputs: {},
   updateLoading: false,
};

export default initialState;
